import { React, useState, useEffect } from 'react';
import { Container, Row, Col, Image, Card } from "react-bootstrap";
import Topbar from '../Assets/Topbar';
import LeftSidebar from '../Assets/LeftSidebar'; 
import { useSelector } from 'react-redux';
import { getStylistDashboard, postClientTimeZoneDetails } from '../../../Service';
import { InfinitySpin } from 'react-loader-spinner';
import { useNavigate } from 'react-router-dom';
import month from "../../../images/month.png";
import moment from 'moment';
import moments from 'moment-timezone';

export default function Dashboard() {

    var abbrs = {
        EST : 'Eastern Standard Time',
        EDT : 'Eastern Daylight Time',
        CST : 'Central Standard Time',
        CDT : 'Central Daylight Time',
        MST : 'Mountain Standard Time',
        MDT : 'Mountain Daylight Time',
        PST : 'Pacific Standard Time',
        PDT : 'Pacific Daylight Time',
        AKST : 'Alaskan Standard Time',
        HST : 'Hawaiian Standard Time'
    };

    moment.fn.zoneName = function () {
        var abbr = this.zoneAbbr();
        return abbrs[abbr] || abbr;
    };

    const navigate = useNavigate();

    // initial state declaration
    const [dashboardData, setDashboardData] = useState({'dayBookings' : 0, 'monthlyBookings' : 0, 'yearBookings' : 0, 'dayIncome' : 0, 'monthlyIncome' : 0, 'yearIncome' : 0});
    const [loader, setLoader] = useState(true);
    const [apiError, setApiError] = useState(false);
    const [proTimeZone, setProTimeZone] = useState('');

    // get customer data from store
    const userData =  useSelector(store => store.user['stylistuser']);
    
    const stylistDashboard = () =>{
        if (userData !== null) {
            const userId = {'clientId' : userData.clientId};
            // getStylistDashboard(userId).then(res => initialAPIResponse(res));

            postClientTimeZoneDetails(userId).then(res => timeZoneResponseHanlde(res));
        }
    }

    const timeZoneResponseHanlde = ( res ) => {

        if ( res.data.statusCode === 200 ) {

            setProTimeZone(res.data.timeZone);
            let userId = {};

            if ( res.data.timeZone !== '' ) {
                let zoneSplit = res.data.timeZone.split(' ');
                let zone = '';

                if ( zoneSplit[0] == 'US/Arizona' ) {
                    zone = 'US Mountain Standard Time';
                } else {
                    zone = moments.tz([2012, 0], zoneSplit[0]).format('zz');
                }

                let proLocalTime = moment().tz(zoneSplit[0]).format('YYYY-MM-DD')+'T'+moment().tz(zoneSplit[0]).format('HH:mm:ss')+'Z';

                userId = {'clientId' : userData.clientId, 'timeZone' : zone, 'localDateTime' : proLocalTime};
            } else {
                let proLocalTime = moment(new Date()).format('YYYY-MM-DD')+'T'+moment(new Date()).format('HH:mm:ss')+'Z';

                userId = {'clientId' : userData.clientId, 'timeZone' : '', 'localDateTime' : proLocalTime};
            }
            
            getStylistDashboard(userId).then(res => initialAPIResponse(res));
        } else {
            setApiError(true);
            setLoader(false);
        }
    }
     
    // get stylist dashboard data by clientid
    useEffect(() => {
        stylistDashboard();
    },[]);

    const initialAPIResponse = (response) => {
        if(response.data.statusCode === 200) {
            setLoader(false);
            setDashboardData({'dayBookings' : response.data.dayBookings, 'monthlyBookings' : response.data.monthlyBookings, 'yearBookings' : response.data.yearBookings, 'dayIncome' : response.data.dayIncome, 'monthlyIncome' : response.data.monthlyIncome, 'yearIncome' : response.data.yearIncome});
        } else {
            setApiError(true);
            setLoader(false);
        }
    }

    // get decimal two degit cost
    const getFullCost = (dollar) => {
        let roundCost = (Math.floor(dollar * 100) / 100).toFixed(2);
        return roundCost;
    }

    const myEarningHandleClick = (range) => {
        navigate('/stylist/myearning/'+range);
    }

    return(
        // stylist dashboard HTML start here
        <section className='dashboard'>
            <Container>
                <Row className='sideBarRow'> 
                    <Col md={3} className='sideBarCol'>
                        <LeftSidebar/>
                    </Col>
                    <Col md={9}>
                        <div className='rightsidebar tab-view'>
                            <Topbar/>
                            <Row className="main-title">
                                <Col>
                                    <h1>Dashboard</h1>
                                </Col>
                            </Row>
                            {
                                loader === true ?
                                    <Row xs={1} md={1} style={{marginTop : '24px'}}>
                                        <div style={{textAlign : 'center'}}>
                                              <InfinitySpin height="80" width="200" radius="9" color="#D49D29" ariaLabel="loading" wrapperStyle wrapperClass />
                                        </div>
                                    </Row>
                                :
                                    apiError ? 
                                        <Row xs={1} md={1} style={{marginTop : '24px'}}>
                                            <div style={{textAlign : 'center', color : '#fff'}}>
                                                <p>Something went wrong, Please try after sometimes...</p>
                                            </div>
                                        </Row>  
                                    :
                                        <>
                                            <Row>
                                                <Col className='bookings'>
                                                    <h2>Bookings</h2>
                                                </Col>
                                            </Row>
                                            <Row xs={1} md={3} className="g-4">
                                                <Col className='dashboard-col stylist-dashboard'>
                                                    <Card className="text-center" >                        
                                                        <div className='circle-blue'>
                                                            <Image variant="top" src={month} />
                                                        </div>
                                                        <Card.Text>Current day Booking</Card.Text>
                                                        <Card.Title>{dashboardData.dayBookings}</Card.Title>
                                                    </Card>
                                                </Col>
                                                <Col className='dashboard-col stylist-dashboard'>
                                                    <Card className="text-center" >
                                                        <div className='circle-red'>
                                                            <Image variant="top" src={month} />
                                                        </div>
                                                        <Card.Text>Monthly</Card.Text>
                                                        <Card.Title>{dashboardData.monthlyBookings}</Card.Title>
                                                    </Card>
                                                </Col>
                                                <Col className='dashboard-col stylist-dashboard'>
                                                    <Card className="text-center" >
                                                        <div className='circle-violet'>
                                                            <Image variant="top" src={month} />
                                                        </div>
                                                        <Card.Text>Year to date (YTD)</Card.Text>
                                                        <Card.Title>{dashboardData.yearBookings}</Card.Title>
                                                    </Card>
                                                </Col>                   
                                            </Row>
                                            <Row className='line'>
                                                <Col><hr/></Col>
                                            </Row>
                                            <Row>
                                                <Col className='bookings'>
                                                    <h2>Income</h2>
                                                </Col>
                                            </Row>
                                            <Row xs={1} md={3} className="g-4">
                                                <Col className='dashboard-col stylist-dashboard' >
                                                    <Card className="text-center pro-earning-card" onClick={() => myEarningHandleClick('day')}>                        
                                                        <div className='circle-violet'>
                                                            <Image variant="top" src={month} />
                                                        </div>
                                                        <Card.Text>Current day Income</Card.Text>
                                                        <Card.Title>${dashboardData.dayIncome.toFixed(2)}</Card.Title>
                                                    </Card>
                                                </Col>
                                                <Col className='dashboard-col stylist-dashboard'>
                                                    <Card className="text-center pro-earning-card" onClick={() => myEarningHandleClick('month')}>
                                                        <div className='circle-blue'>
                                                            <Image variant="top" src={month} />
                                                        </div>
                                                        <Card.Text>Monthly total</Card.Text>
                                                        <Card.Title>${dashboardData.monthlyIncome.toFixed(2)}</Card.Title>
                                                    </Card>
                                                </Col>
                                                <Col className='dashboard-col stylist-dashboard'>
                                                    <Card className="text-center pro-earning-card" onClick={() => myEarningHandleClick('year')}>
                                                        <div className='circle-pink'>
                                                            <Image variant="top" src={month} />
                                                        </div>
                                                        <Card.Text>Year to date (YTD)</Card.Text>
                                                        <Card.Title>${dashboardData.yearIncome.toFixed(2)}</Card.Title>
                                                    </Card>
                                                </Col>                   
                                            </Row>
                                        </>
                            }
                        </div>
                    </Col>
                </Row>
            </Container>
        </section>
    )
} 