import { React, useState } from 'react';
import { Row, Col, Button, Modal, Form } from "react-bootstrap";
import { useSelector } from 'react-redux';
import { postUpdateSubService } from '../../../../Service';
import Swal from "sweetalert2";
import { InfinitySpin } from 'react-loader-spinner';
import { BlockInvalidNumber, BlockSQLInjection, HtmlTagValidation } from '../../../Reusable';
import { JsonEncode } from '../../../Reusable/JsonModify';

export default function EditServicePopup( { serviceData, duration, getBack } ) {

    // get Professional data from store
    const userData =  useSelector(store => store.user['stylistuser']);

    const updateInitialValues = {baseServiceId : serviceData.baseServiceId, baseserviceName : serviceData.baseserviceName, description : serviceData.description, price : serviceData.price, oldPrice : serviceData.oldPrice, productId : serviceData.productId, subServiceId : serviceData.subServiceId, subServiceName : serviceData.subServiceName};

    const [updateFormValues, setUpdateformValues] = useState(updateInitialValues);
    const [updateFormErrors, setUpdateFormErrors] = useState({}); 
    const [popupShow, setPopupShow] = useState(true);
    const [updateLoader, setUpdateLoader] = useState(false);
    const [updateDurationTime, setUpdateDurationTime] = useState({hours : duration.hours, minutes : duration.minutes});

    // services update form functionality start here
    const popupHandleClose = () => {
        setUpdateFormErrors({});
        setUpdateformValues(updateInitialValues);
        setPopupShow(false)
        getBack(false);
    };

    const updateHandleChange = ( e )=> {
        const value=e.target.value;
        const field=e.target.name;

        if ( field === 'hours' || field === 'minutes' ) {
            setUpdateDurationTime(prevState => ({
                ...prevState, 
                [field] : value
            }))
        } else if ( field === 'price' ) {
            let setupValue = value.replace(/-\d+/g, "");
            let splitValue = setupValue.split('');

            if ( splitValue[0] == 0 || splitValue[0] == '.' ) {

            } else {
                let roundValue = Math.floor(setupValue);
                let roundValueInString = roundValue.toString().length;

                if ( roundValueInString < 5 ) {
                    let valueSplit = setupValue.split('');
                    let getValue = valueSplit.indexOf(".");

                    if ( getValue != -1 ) {

                        if ( valueSplit.length <= getValue + 3 ) {
                            let floatTip = Math.floor(setupValue * 100) / 100;
                            let amtSplit = floatTip.toString().split('');
                            
                            if ( amtSplit[0] == 0 ) {
                                amtSplit.shift();
                            }  

                            let roundAmount = amtSplit.join('');
                            setUpdateformValues(prevState => ({
                                ...prevState,
                                [field]: roundAmount  }
                            ));
                        }

                    } else {
                        let floatTip = Math.floor(setupValue * 100) / 100;
                        let amtSplit = floatTip.toString().split('');
                        
                        if ( amtSplit[0] == 0 ) {
                            amtSplit.shift();
                        }   

                        let roundAmount = amtSplit.join('');
                        setUpdateformValues(prevState => ({
                            ...prevState,
                            [field]: roundAmount  }
                        ));
                    }

                }

            }

        } else {
            setUpdateformValues(prevState => ({
                ...prevState,
                [field]: value  }
            ));
        }

    } 

    const updateHandleSubmit = async ( e ) => {
        e.preventDefault();
        const errorCount = validate(updateFormValues);
        setUpdateFormErrors(errorCount);

        if( errorCount.status ) {
            let HH = updateDurationTime.hours * 60;
            let MM = 0;

            if ( updateDurationTime.minutes !== 0 ) {
                MM = updateDurationTime.minutes;
            }
            
            let updatedDuration = HH + Number(MM);
            let withoutSpaceDescription = updateFormValues.description.replace(/^\s+|\s+$/gm,'');
            
            let productUpdateParams = {baseServiceId : updateFormValues.baseServiceId, baseserviceName : updateFormValues.baseserviceName, description : withoutSpaceDescription, price : updateFormValues.price, productId : updateFormValues.productId, subServiceId : updateFormValues.subServiceId, subServiceName : updateFormValues.subServiceName, duration : updatedDuration, professionId : userData.professionId, clientId : userData.clientId};

            let jsonData = await JsonEncode(productUpdateParams).then(result => {
                return result
            }).catch(error => {
                Swal.fire({
                    position: 'center',
                    icon: 'error',
                    title: 'Oops...',
                    showConfirmButton: false,
                    text: error,
                    timer: 3000
                }); 
            });
            
            if ( jsonData ) {
                let loginPayload = {data : jsonData};
                postUpdateSubService(loginPayload).then(res => updatehandleResponse(res));
                setUpdateLoader(true);
            } else {
                Swal.fire({
                    position: 'center',
                    icon: 'error',
                    title: 'Oops...',
                    showConfirmButton: false,
                    text: 'Something went wrong, Please try after sometimes',
                    timer: 3000
                }); 
            }
            
        }  

    }

    const updatehandleResponse = ( res ) => {

        if ( res.data.statusCode === 200 ) {
            Swal.fire({
                position: 'center',
                icon: 'success',
                title: 'Service updated successfully...!',
                showConfirmButton: false,
                timer: 3000
            });
            setUpdateLoader(false);
            getBack(true);
        } else {
            Swal.fire({
                icon: 'error',
                title: 'Oops...',
                text: res.data.statusMessage,
                showConfirmButton: false,
                timer: 3000
            });
            setUpdateLoader(false);
        }
         
    }

    const validate = ( values ) => {
        const errors = {};
        errors.status = true; 
        let newHrs = updateDurationTime.hours.toString();
        let newMins = updateDurationTime.minutes.toString();

        if ( !values.baseserviceName ) {
            errors.baseserviceName='Base Service is required';
            errors.status=false;
        }

        if ( !values.subServiceName || values.subServiceName.trim() === '' ) {
            errors.subServiceName='Service is required';
            errors.status=false;
        }

        if ( !values.description || values.description.trim() === '' ) {
            errors.description='Description is required';
            errors.status=false;
        }

        if ( !values.price ) {
            errors.price='Cost is required';
            errors.status=false;
        } else if ( values.price == 0 ) {
            errors.price='Cost should not be zero';
            errors.status=false;
        }

        if ( !newHrs || !newMins ) {
            errors.duration='Duration is required';
            errors.status=false;
        } else if ( newHrs == 0 && newMins == 0 ){
            errors.duration='Duration should not be zero';
            errors.status=false;
        }

        const tagValidations = {
            subServiceName: HtmlTagValidation(values.subServiceName),
            description: HtmlTagValidation(values.description)
        };

        Object.entries(tagValidations).forEach(([key, value]) => {

            if ( !value ) {
                errors[key] = 'Invalid input';
                errors.status = false;
            }
            
        });
    
        return errors;
    }

    return(
        <Modal show={popupShow} onHide={popupHandleClose} className="custom-modal">
            <Form onSubmit={updateHandleSubmit}>
                <Modal.Header closeButton>
                    <Modal.Title>Edit Service</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Row className="mb-3">
                        <Form.Group as={Col} controlId="formGridFirstName">
                            <Form.Label>Base Service <span>*</span></Form.Label>
                            <Form.Control type="text" name="baseserviceName" value={updateFormValues.baseserviceName} onChange={updateHandleChange} disabled/>
                            <span className="error">{updateFormErrors.baseserviceName}</span>
                        </Form.Group> 
                    </Row>
                    <Row className="mb-3">
                        <Form.Group as={Col} controlId="formGridFirstName">
                            <Form.Label>Service <span>*</span></Form.Label>
                            <Form.Control onKeyDown={BlockSQLInjection} type="text" name="subServiceName" defaultValue={updateFormValues.subServiceName} onChange={updateHandleChange}/>
                            <span className="error">{updateFormErrors.subServiceName}</span>
                        </Form.Group> 
                    </Row>
                    <Row className="mb-3">
                        <Form.Group as={Col} controlId="formGridFirstName">
                            <Form.Label>Description <span>*</span></Form.Label>
                            {/* <Form.Control type="text" name="description" defaultValue={updateFormValues.description} onChange={updateHandleChange}/> */}
                            <textarea onKeyDown={BlockSQLInjection} class="form-control" name='description' defaultValue={updateFormValues.description} onChange={updateHandleChange} rows="4" maxLength="200"></textarea>
                            <span className="error">{updateFormErrors.description}</span>
                        </Form.Group> 
                    </Row>
                    <Row className="mb-3">
                        <Col md={6}>
                        <Form.Group as={Col} controlId="formGridLastName">
                            <Form.Label>Cost (in $)<span>*</span></Form.Label>
                            <Form.Control onKeyDown={BlockInvalidNumber} type="number" name="price" value={updateFormValues.price} pattern='[0-9]*' onChange={updateHandleChange} maxLength={5}/>
                            <span className="error">{updateFormErrors.price}</span>
                        </Form.Group>
                        </Col>
                        <Col md={6}>
                        <Form.Group as={Col} controlId="formGridFirstName">
                            <Form.Label>Duration (HH : MM) <span>*</span></Form.Label>
                            <span className='hours-min-combo'>
                            <Form.Select aria-label="Default select example" name="hours" onChange={updateHandleChange}>
                                <option value=''>Select Hours...</option>
                                <option value='0' selected={updateDurationTime.hours === 0 ? 'selected' : ''}>00</option>
                                <option value='1' selected={updateDurationTime.hours === 1 ? 'selected' : ''}>01</option>
                                <option value='2' selected={updateDurationTime.hours === 2 ? 'selected' : ''}>02</option>
                                <option value='3' selected={updateDurationTime.hours === 3 ? 'selected' : ''}>03</option>
                                <option value='4' selected={updateDurationTime.hours === 4 ? 'selected' : ''}>04</option>
                                <option value='5' selected={updateDurationTime.hours === 5 ? 'selected' : ''}>05</option>
                                <option value='6' selected={updateDurationTime.hours === 6 ? 'selected' : ''}>06</option>
                                <option value='7' selected={updateDurationTime.hours === 7 ? 'selected' : ''}>07</option>
                                <option value='8' selected={updateDurationTime.hours === 8 ? 'selected' : ''}>08</option>
                                <option value='9' selected={updateDurationTime.hours === 9 ? 'selected' : ''}>09</option>
                                <option value='10' selected={updateDurationTime.hours === 10 ? 'selected' : ''}>10</option>
                                <option value='11' selected={updateDurationTime.hours === 11 ? 'selected' : ''}>11</option>
                                <option value='12' selected={updateDurationTime.hours === 12 ? 'selected' : ''}>12</option>
                            </Form.Select>
                            <Form.Select aria-label="Default select example" name="minutes" onChange={updateHandleChange}>
                                <option value=''>Select Minutes...</option>
                                <option value='0' selected={updateDurationTime.minutes === 0 ? 'selected' : ''}>00</option>
                                <option value='15' selected={updateDurationTime.minutes === 15 ? 'selected' : ''}>15</option>
                                <option value='30' selected={updateDurationTime.minutes === 30 ? 'selected' : ''}>30</option>
                                <option value='45' selected={updateDurationTime.minutes === 45 ? 'selected' : ''}>45</option>
                            </Form.Select>
                            </span>
                            <span className="error">{updateFormErrors.duration}</span>
                        </Form.Group>
                        </Col>
                    </Row>

                </Modal.Body>
                <Modal.Footer>
                    {
                        updateLoader === true ?
                            <InfinitySpin height="80" width="200" radius="9" color="#D49D29" ariaLabel="loading" wrapperStyle wrapperClass />
                        :
                            <Button variant="primary" type='submit'>Update</Button>
                    }
                    <Button variant="outline-secondary" onClick={popupHandleClose}>Cancel</Button>
                </Modal.Footer>
            </Form>
        </Modal>
    )
}