import {React, useState, useEffect} from 'react';
import { Row, Col, Form, Button } from "react-bootstrap";
import { useSelector } from 'react-redux';
import { stylistServiceAdd, getAllServiceListByProfessionId, stylistServiceList, postCreateSubService } from '../../../Service';
import Swal from "sweetalert2";
import { InfinitySpin } from 'react-loader-spinner';
import { useNavigate } from "react-router-dom";
import { BlockInvalidNumber } from '../../Reusable';
import { JsonEncode } from '../../Reusable/JsonModify';

export default function StepTwo(){

    const navigate = useNavigate();

    const initialValues = {serviceName:'', serviceId : '', price:'', product : '', description : ''};
    const [serviceListByProfession, setServiceListByProfession] = useState([]);
    const [formValues, setformValues] = useState(initialValues);
    const [durationTime, setDurationTime] = useState({hours : '', minutes : ''});
    const [formErrors, setFormErrors] = useState({});
    const [loader, setLoader] = useState(false);
    const [pageLoader, setPageLoader] = useState(true);

    // get customer data from store
    const userData =  useSelector(store => store.user['stylistuser']);

    // get service by profession
    useEffect(() => {
        const professionId = {'professionId' : userData.professionId};
        getAllServiceListByProfessionId(professionId).then(res => initialServiceResponse(res));
        const userId = {'clientId' : userData.clientId, 'searchServiceName' : '', 'pageNo' : 1, 'pageSize' : 10, 'sortColumn' : 'id', 'sortDirection' : 'asc'};
        stylistServiceList(userId).then(res => initialStylistServiceResponse(res));
    },[]);

    const initialStylistServiceResponse = (response) => {
        if(response.data.statusCode === 200){
            if(response.data.productList.length > 0){
                navigate('/stylist/step-III');
                setPageLoader(false);
            }else{
                setPageLoader(false);
            }
        }else{
            setPageLoader(false);
        }
    }

    const initialServiceResponse = (response) => {
        if(response.data.statusCode === 200){
            setServiceListByProfession(response.data.serviceList)
        }
    }

    const handleChange = (e)=>{
        const value=e.target.value;
        const field=e.target.name;

        if(field === 'hours' || field === 'minutes'){
            setDurationTime(prevState => ({
                ...prevState, 
                [field] : value
            }))
        }else if(field === 'price'){
            let setupValue = value.replace(/-\d+/g, "");
            let splitValue = setupValue.split('');
            if(splitValue[0] == 0 || splitValue[0] == '.'){

            }else{
                let roundValue = Math.floor(setupValue);
                let roundValueInString = roundValue.toString().length;
                if(roundValueInString < 5){
                    let valueSplit = setupValue.split('');
                    let getValue = valueSplit.indexOf(".");
                    if(getValue != -1){
                        if(valueSplit.length <= getValue + 3){
                            let floatTip = Math.floor(setupValue * 100) / 100;
                            let amtSplit = floatTip.toString().split('');
                            
                            if(amtSplit[0] == 0){
                                amtSplit.shift();
                            }            
                            let roundAmount = amtSplit.join('');
                            setformValues(prevState => ({
                                ...prevState,
                                [field]: roundAmount  }
                            ));
                        }
                    }else{
                        let floatTip = Math.floor(setupValue * 100) / 100;
                        let amtSplit = floatTip.toString().split('');
                        
                        if(amtSplit[0] == 0){
                            amtSplit.shift();
                        }            
                        let roundAmount = amtSplit.join('');
                        setformValues(prevState => ({
                            ...prevState,
                            [field]: roundAmount  }
                        ));
                    }
                }
            }

            // let priceLength = value.length;
            // if(priceLength <= 5){
            //     setformValues(prevState => ({
            //         ...prevState,
            //         [field]: value  }
            //     ));  
            // }
        }else{
            setformValues(prevState => ({
                ...prevState,
                [field]: value  }
            ));
    
            if(field === 'serviceName'){
                const index = e.target.selectedIndex;
                const el = e.target.childNodes[index]
                const option =  el.getAttribute('id');
          
                const fieldName='serviceId';
                setformValues(prevState => ({
                    ...prevState,
                    [fieldName]:  option  }));
            }
        }
        
    } 

    const handleSubmit = async (e) => {
        e.preventDefault();
        const errorCount = validate(formValues);
        setFormErrors(errorCount);
        if(errorCount.status){
            let HH = durationTime.hours * 60;
            let MM = 0;
            if(durationTime.minutes !== 0){
                MM = durationTime.minutes;
            }
            let time = HH + Number(MM);
            formValues['clientId'] = userData.clientId;
            let withoutSpaceDescription = formValues.description.replace(/^\s+|\s+$/gm,'');

            let addParams = {'professionId' : userData.professionId, 'subServiceName' : formValues.product, 'baseServiceId' : formValues.serviceId, 'description' : withoutSpaceDescription, 'clientId' : userData.clientId, 'price' : formValues.price, 'duration' : time};

            let jsonData = await JsonEncode(addParams).then(result => {
                return result
            }).catch(error => {
                Swal.fire({
                    position: 'center',
                    icon: 'error',
                    title: 'Oops...',
                    showConfirmButton: false,
                    text: error,
                    timer: 3000
                }); 
            });
            
            if ( jsonData ) {
                let loginPayload = {data : jsonData};
                postCreateSubService(loginPayload).then(res => handleResponse(res));
                setLoader(true);
            } else {
                Swal.fire({
                    position: 'center',
                    icon: 'error',
                    title: 'Oops...',
                    showConfirmButton: false,
                    text: 'Something went wrong, Please try after sometimes',
                    timer: 3000
                }); 
            }
            
        }
    } 

    const handleResponse = (response) =>{
        if(response.data.statusCode === 200){
            navigate('/stylist/step-III');
            setLoader(false);
        }else{
            Swal.fire({
              position: 'center',
              icon: 'error',
              title: 'Oops...',
              showConfirmButton: false,
              text: response.data.statusMessage,
              timer: 3000
            }); 
            setLoader(false);
          }
    }

    const validate = (values)=>{
        const errors = {};
        errors.status = true;
        var numbers = /^[0-9]+$/;

        if(!values.serviceName){
            errors.serviceName='Base Service is required';
            errors.status=false;
        }
        if(!values.product || values.product.trim() === ''){
            errors.productName='Service is required';
            errors.status=false;
        }
        if(!values.description || values.description.trim() === ''){
            errors.description='Description is required';
            errors.status=false;
        }
        if(!values.price){
            errors.price='Cost is required';
            errors.status=false;
        } else if(values.price == 0){
            errors.price='Cost should not be zero';
            errors.status=false;
        }
        // else if(!values.price.match(numbers)){
        //     errors.price='Enter valid number';
        //     errors.status=false;
        // }
        if(!durationTime.hours || !durationTime.minutes){
            errors.duration='Duration is required';
            errors.status=false;
        }
        if(durationTime.hours && durationTime.minutes){
            if(durationTime.hours == 0 && durationTime.minutes == 0){
                errors.duration='Duration should not be zero';
                errors.status=false;
            }
            
        }
        return errors;
    }

    return(
        <>
            <div>
                <Row className='CommonHeaderWrapper'>
                    <Col className="stylistCommonHeader">
                        <h1>Services</h1>
                        <p>Step 2 / 3 : Add your service</p>
                        <p className="selfBookingNote" style={{marginBottom : '16px'}}><span><span>* </span>Note: <i>You can add one service here. In future you can add more services in 'Manage Services' menu.</i></span></p>
                    </Col>
                </Row>
            </div>
            {
                pageLoader === true ?
                    <div style={{textAlign:'center'}}>
                        <InfinitySpin height="80" width="200" radius="9" color="#D49D29" ariaLabel="loading" wrapperStyle wrapperClass />
                    </div>
                :
                    <div className='booking-details availabilityII'>
                        <Form onSubmit={handleSubmit} className='setting-sec'>
                            <Row className="mb-3">
                                <Form.Group as={Col} controlId="formGridFirstName">
                                    <Form.Label>Base Service <span>*</span></Form.Label>
                                    <Form.Select aria-label="Default select example" name="serviceName" onChange={handleChange}>
                                        <option value=''>Select an option...</option>
                                        {
                                            serviceListByProfession.length > 0 ?
                                                serviceListByProfession.map((data, index) => (
                                                    <option value={data.name} id={data.id} key={index}>{data.name}</option>
                                                ))
                                            :
                                            ''
                                        }
                                    </Form.Select>
                                    <span className="error">{formErrors.serviceName}</span>
                                </Form.Group> 
                            </Row>
                            <Row className="mb-3">
                                <Form.Group as={Col} controlId="formGridFirstName">
                                    <Form.Label>Service <span>*</span></Form.Label>
                                    <Form.Control type="text" name="product" value={formValues.product} onChange={handleChange}/>
                                    <span className="error">{formErrors.productName}</span>
                                </Form.Group> 
                            </Row>
                            <Row className="mb-3">
                                <Form.Group as={Col} controlId="formGridFirstName">
                                    <Form.Label>Description <span>*</span></Form.Label>
                                    {/* <Form.Control type="text" name="description" value={formValues.description} onChange={handleChange}/> */}
                                    <textarea class="form-control" name='description' defaultValue={formValues.description} onChange={handleChange} rows="4" maxLength="200"></textarea>
                                    <span className="error">{formErrors.description}</span>
                                </Form.Group> 
                            </Row>
                            <Row className="mb-3">
                                <Col md={6}>
                                    <Form.Group as={Col} controlId="formGridLastName">
                                        <Form.Label>Cost (in $)<span>*</span></Form.Label>
                                        <Form.Control onKeyDown={BlockInvalidNumber} type="number" name="price" value={formValues.price} pattern='[0-9]*' onChange={handleChange}/>
                                        <p className="error">{formErrors.price}</p>
                                    </Form.Group>
                                </Col>
                                <Col md={6}>
                                    <Form.Group as={Col} controlId="formGridFirstName">
                                        <Form.Label>Duration (HH : MM) <span>*</span></Form.Label>
                                        <span className='hours-min-combo'>
                                        <Form.Select aria-label="Default select example" name="hours" onChange={handleChange}>
                                            <option value=''>Select Hours...</option>
                                            <option value='0'>00</option>
                                            <option value='1'>01</option>
                                            <option value='2'>02</option>
                                            <option value='3'>03</option>
                                            <option value='4'>04</option>
                                            <option value='5'>05</option>
                                            <option value='6'>06</option>
                                            <option value='7'>07</option>
                                            <option value='8'>08</option>
                                            <option value='9'>09</option>
                                            <option value='10'>10</option>
                                            <option value='11'>11</option>
                                            <option value='12'>12</option>
                                        </Form.Select>
                                        <Form.Select aria-label="Default select example" name="minutes" onChange={handleChange}>
                                            <option value=''>Select Minutes...</option>
                                            <option value='0'>00</option>
                                            <option value='15'>15</option>
                                            <option value='30'>30</option>
                                            <option value='45'>45</option>
                                        </Form.Select>
                                        </span>
                                        <span className="error">{formErrors.duration}</span>
                                    </Form.Group>
                                </Col>
                            </Row>
                            <Row>
                                {
                                    loader === true ?
                                        <div style={{textAlign:'center'}}>
                                            <InfinitySpin height="80" width="200" radius="9" color="#D49D29" ariaLabel="loading" wrapperStyle wrapperClass />
                                        </div>
                                    :
                                        <Col style={{textAlign : 'center'}} className='stylistCommonBtn'>
                                            <Button variant="dark" className='upload-btn' type='submit'>Next</Button>
                                        </Col>
                                }
                            </Row>
                        </Form>
                    </div>
            }
        </>
    )
}