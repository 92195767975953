import React from 'react';
import { Row, Col, Card, Button, Image, Container, Stack, Table, Modal, OverlayTrigger} from "react-bootstrap";
import userImage from '../../../../images/userimage.png';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { useEffect } from 'react';
import { useState } from 'react';
import { customerClientDetail, postAddFavouriteClient, postRemoveFavouriteClient } from '../../../../Service';
import { InfinitySpin } from 'react-loader-spinner';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {faUserAlt,faMapPin,faPhone, faHeart} from '@fortawesome/free-solid-svg-icons';
import CustomerHeader from '../../Assets/CustomerHeader';
import moment from 'moment';
import DesignServicesIcon from '@mui/icons-material/DesignServices';
import PaidIcon from '@mui/icons-material/Paid';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import EventAvailableIcon from '@mui/icons-material/EventAvailable';
import SendMessagePopup from './Popup/SendMessagePopup';
import ServiceListPopup from './Popup/ServiceListPopup';
import { useSelector } from 'react-redux';
import Tooltip from 'react-bootstrap/Tooltip';
import { faHeart as farHeart } from '@fortawesome/free-regular-svg-icons';
import Swal from "sweetalert2";
import { JsonEncode, JsonDecode } from '../../../Reusable/JsonModify';

export default function StylistCustomer(){
    // initial state declaration
    const [ clientDetail, setClientDetail ] = useState({});
    const [loader, setLoader] = useState(true);
    const [ loadMore, setLoadMore ] = useState(false);
    const [show, setShow] = useState(false);
    const [portfolioImage, setPortfolioImage] = useState('');
    const [daySlotList, setDaySlotList] = useState({mondayOpen : '', mondayClose : '', tuesdayOpen : '', tuesdayClose : '', wednessdayOpen : '', wednessdayClose : '',
    thursdayOpen : '', thursdayClose : '', fridayOpen : '', fridayClose : '', saturdayOpen : '', saturdayClose : '', sundayOpen : '' , sundayClose : '' });
    const [breakSlotList, setBreakSlotList] = useState({mondayOpen : '', mondayClose : '', tuesdayOpen : '', tuesdayClose : '', wednessdayOpen : '', wednessdayClose : '',
    thursdayOpen : '', thursdayClose : '', fridayOpen : '', fridayClose : '', saturdayOpen : '', saturdayClose : '', sundayOpen : '' , sundayClose : '' })
    const paramsName = useParams();
    const [passEye, setPassEye] = useState(false);
    const [confirmPassEye, setConfrimPassEye] = useState(false);
    const [allDayOpen, setAllDayOpen] = useState();
    const [colorName, setColorName] = useState('');
    const [sendMsgPopupStatus, setSendMsgPopupStatus] = useState(false);
    const [stylistTimezone, setStylistTimezone] = useState('');
    const [favouriteProfessional, setFavouriteProfessional] = useState(false);
    const [pageReload, setPageReload] = useState(false);
    const [serviceListPopupStatus, setServiceListPopupStatus] = useState(false);

    const navigate = useNavigate();

    // get customer data from store
    const userData =  useSelector(store => store.user['customeruser']);

    // get stylist data from api
    useEffect(()=>{
        (async () => {
            let clientDetailId = {};
            if(userData !== null && userData !== undefined){
                clientDetailId = {'clientId' : paramsName.id, 'customerId' : userData.id};
            }else{
                clientDetailId = {'clientId' : paramsName.id, 'customerId' : 0};
            }

            let jsonData = await JsonEncode(clientDetailId).then(result => {
                return result
            }).catch(error => {
                Swal.fire({
                    position: 'center',
                    icon: 'error',
                    title: 'Oops...',
                    showConfirmButton: false,
                    text: error,
                    timer: 3000
                }); 
            });
            
            if ( jsonData ) {
                let loginPayload = {data : jsonData};
                customerClientDetail(loginPayload).then((res) => handleClientDetail(res));
            } else {
                Swal.fire({
                    position: 'center',
                    icon: 'error',
                    title: 'Oops...',
                    showConfirmButton: false,
                    text: 'Something went wrong, Please try after sometimes',
                    timer: 3000
                }); 
            }
        })();
    },[pageReload]);

    const handleClose = () => {
        setShow(false);
        setPortfolioImage('');
    }

    const handleClientDetail = async (response) => {
        let decryptData = await JsonDecode(response.data.data).then(result => {
            return result
        }).catch(error => {
            Swal.fire({
                position: 'center',
                icon: 'error',
                title: 'Oops...',
                showConfirmButton: false,
                text: error,
                timer: 3000
            }); 
        });

        if(decryptData.statusCode === 200){
            setClientDetail(decryptData);
            getAvailableSlots(decryptData.clientBookingSlotList, decryptData.allDay);
            setAllDayOpen(decryptData.allDay);
            colorValue(decryptData.backGroundColor);
            setLoader(false);
            if(userData !== null){
                setFavouriteProfessional(decryptData.favClient);
            }
        }else{
            setLoader(false);
        }
    }

  const colorValue = (values) => {
      if (values === '#2666c0') {
          setColorName('blueBg');
      }else if (values === '#27ae61') {
          setColorName('greenBg');
      }else if (values === '#fb8231') {
          setColorName('yellowBg')
      }else if (values === '#d94c43') {
          setColorName('redBg')
      }else{
          setColorName('');
      }
  }

    //get converted time to local time
    function getLocalTime(time) {
        if(time){
            return moment(moment().format('YYYY-MM-DD')+' '+time).format('hh:mm A')
        }else{
            return '';
        }
    }

    const getAvailableSlots = (data, available) => {
        if(available === true){
            data.map((list) => {
                let localOpenTime = getLocalTime(list.opening);
                let localCloseTime = getLocalTime(list.closing);
                if(list.sectionType == 'Work'){
                    if(list.day == 'Monday'){
                        setDaySlotList(prevState => ({...prevState, ['mondayOpen'] : localOpenTime, ['mondayClose'] : localCloseTime}))
                        let timeZ = list.timeZone.split(' ');
                        setStylistTimezone(timeZ[0]);
                    }
                    if (list.day == 'Tuesday') {
                        setDaySlotList(prevState => ({...prevState, ['tuesdayOpen'] : localOpenTime, ['tuesdayClose'] : localCloseTime}))
                    }
                    if (list.day == 'Wednesday') {
                        setDaySlotList(prevState => ({...prevState, ['wednessdayOpen'] : localOpenTime, ['wednessdayClose'] : localCloseTime}))
                    }
                    if (list.day == 'Thursday') {
                        setDaySlotList(prevState => ({...prevState, ['thursdayOpen'] : localOpenTime, ['thursdayClose'] : localCloseTime}))
                    }
                    if (list.day == 'Friday') {
                        setDaySlotList(prevState => ({...prevState, ['fridayOpen'] : localOpenTime, ['fridayClose'] : localCloseTime}))
                    }
                    if (list.day == 'Saturday') {
                        setDaySlotList(prevState => ({...prevState, ['saturdayOpen'] : localOpenTime, ['saturdayClose'] : localCloseTime}))
                    }
                    if (list.day == 'Sunday') {
                        setDaySlotList(prevState => ({...prevState, ['sundayOpen'] : localOpenTime, ['sundayClose'] : localCloseTime}))
                    }
                }else if(list.sectionType == 'Break'){
                    if(list.day == 'Monday'){
                        setBreakSlotList(prevState => ({...prevState, ['mondayOpen'] : localOpenTime, ['mondayClose'] : localCloseTime}))
                    }
                    if(list.day == 'Tuesday'){
                        setBreakSlotList(prevState => ({...prevState, ['tuesdayOpen'] : localOpenTime, ['tuesdayClose'] : localCloseTime}))
                    }
                    if(list.day == 'Wednesday'){
                        setBreakSlotList(prevState => ({...prevState, ['wednessdayOpen'] : localOpenTime, ['wednessdayClose'] : localCloseTime}))
                    }
                    if(list.day == 'Thursday'){
                        setBreakSlotList(prevState => ({...prevState, ['thursdayOpen'] : localOpenTime, ['thursdayClose'] : localCloseTime}))
                    }
                    if(list.day == 'Friday'){
                        setBreakSlotList(prevState => ({...prevState, ['fridayOpen'] : localOpenTime, ['fridayClose'] : localCloseTime}))
                    }
                    if(list.day == 'Saturday'){
                        setBreakSlotList(prevState => ({...prevState, ['saturdayOpen'] : localOpenTime, ['saturdayClose'] : localCloseTime}))
                    }
                    if(list.day == 'Sunday'){
                        setBreakSlotList(prevState => ({...prevState, ['sundayOpen'] : localOpenTime, ['sundayClose'] : localCloseTime}))
                    }
                }
            })
        }
    }

    const handleLoadMore = () => {
        setLoadMore(true);
    }

    const handleLoadLess = () => {
        setLoadMore(false);
    }

    const portfolioImageHandler = (imageData) => {
        setShow(true);
        setPortfolioImage(imageData);
    }

    const sendMessageHandleClick = () => {
        setSendMsgPopupStatus(true);
    }

    const sendMessagePopupGetBack = (data) => {
        setSendMsgPopupStatus(data);
    }

    // add and remove favourite icon click 
    const favouritehandleClick = (type) => {
        let apiParams = {'customerId' : userData.id, 'clientId' : paramsName.id};
        if(type === 'add'){
            setLoader(true);
            postAddFavouriteClient(apiParams).then(res => favouriteResponse(res));
        }else if(type === 'remove'){
            setLoader(true);
            postRemoveFavouriteClient(apiParams).then(res => favouriteResponse(res));
        }
    }

    // add and remove favourite response
    const favouriteResponse = (res) => {
        if(res.data.statusCode === 200){
            setPageReload(prevState => !prevState);
        }else{
            Swal.fire({
                position: 'center',
                icon: 'error',
                title: 'Oops...',
                showConfirmButton: false,
                text: res.data.statusMessage,
                timer: 3000
            }); 
        }
    }

    // get decimal two degit cost
    const getFullCost = (dollar) => {
        let roundCost = (Math.floor(dollar * 100) / 100).toFixed(2);
        return roundCost;
    }

    const serviceListHandleClick = () => {
        setServiceListPopupStatus(true);
    }

    const serviceListPopupGetBack = () => {
        setServiceListPopupStatus(false);
    }

    return(
        <div>
            <CustomerHeader />
            <section className='customer-profile-bg'>
                <div className='custom-profile'>
                    {
                        loader === true ?
                            <Container>
                                <Row>
                                    <Col md='12'>
                                        <div style={{textAlign : 'center'}}>
                                            <InfinitySpin height="80" width="200" radius="9" color="#D49D29" ariaLabel="loading" wrapperStyle wrapperClass />
                                        </div>
                                    </Col>
                                </Row>
                            </Container>
                        :
                            <Container>
                                <Row className='cust-sty'>
                                    <Col md='5' >
                                        <Card className="text-center d-block" >
                                            <div className='left-profile'>
                                                <Image variant="top" src={clientDetail.profilePic ? clientDetail.profilePic : userImage} />
                                            </div>
                                            <Card.Text><FontAwesomeIcon icon={faUserAlt}  style={{marginRight : '6px'}}/>{clientDetail.clientName}</Card.Text>
                                            <Button variant="secondary" className='customer-stylist-name'>{clientDetail.professionName}</Button>
                                            {
                                                clientDetail.portfolioImageList ?
                                                    clientDetail.portfolioImageList.length > 0   ?
                                                        clientDetail.portfolioImageList.length < 5 ?
                                                            <>
                                                                <div className='stylist-portfolio text-start' style={{marginTop:'12px'}}>
                                                                    <strong>Photos</strong>
                                                                    <Row>
                                                                        {
                                                                            clientDetail.portfolioImageList.map((item, index)=>(
                                                                                <Col className='mb-3 col-6' md={3}>
                                                                                    <div className='stylist-photos'>
                                                                                        <Image variant="top" className='img-responsive' src={item.imageUrl} style={{cursor : 'pointer'}}  onClick={() => portfolioImageHandler(item)}/>
                                                                                    </div>
                                                                                </Col>
                                                                            ))
                                                                        }
                                                                    </Row>
                                                                </div>
                                                            </>
                                                        :
                                                            <>
                                                                {
                                                                    !loadMore ?
                                                                        <>
                                                                            <div className='stylist-portfolio text-start' style={{marginTop:'12px'}}>
                                                                                <strong>Photos</strong>
                                                                                <Row>
                                                                                    {
                                                                                        clientDetail.portfolioImageList.slice(0, 4).map((item, index)=>(
                                                                                            <Col className='mb-3 col-6' md={3}>
                                                                                                <div className='stylist-photos'>
                                                                                                    <Image variant="top" className='img-responsive' src={item.imageUrl} style={{cursor : 'pointer'}}  onClick={() => portfolioImageHandler(item)}/>
                                                                                                </div>
                                                                                            </Col>
                                                                                        ))
                                                                                    }
                                                                                </Row>
                                                                                <Link to={''} className="load-more" onClick={handleLoadMore}>Show More</Link>
                                                                            </div>
                                                                        </>
                                                                    :
                                                                        <>
                                                                            <div className='stylist-portfolio text-start' style={{marginTop:'12px'}}>
                                                                                <strong>Photos</strong>
                                                                                <Row>
                                                                                    {
                                                                                        clientDetail.portfolioImageList.map((item, index)=>(
                                                                                            <Col className='mb-3 col-6' md={3}>
                                                                                                <div className='stylist-photos'>
                                                                                                    <Image variant="top" className='img-responsive' src={item.imageUrl} style={{cursor : 'pointer'}}  onClick={() => portfolioImageHandler(item)}/>
                                                                                                </div>
                                                                                            </Col>
                                                                                        ))
                                                                                    }
                                                                                </Row>
                                                                                <Link to={''} className="load-more" onClick={handleLoadLess}>Show Less</Link>
                                                                            </div>
                                                                        </>
                                                                }
                                                            </>
                                                    :
                                                        <></>
                                                :
                                                    <></>
                                            }
                                            <div className="text-start ">
                                                <hr/>
                                                <Row>
                                                    <Col sm={4} className='clientLogoImg'>
                                                        <div className='left-profile'>
                                                            <Image variant="top" src={clientDetail.logoImg ? clientDetail.logoImg : userImage} />
                                                        </div>
                                                    </Col>
                                                    <Col sm={8} className='stylistShopDetails'>
                                                        <h4 className='StylistShopName'>{clientDetail.barberShopName}</h4>
                                                        {
                                                            clientDetail.address1 ? 
                                                                <Col className='col-12' md='12' >
                                                                    <div className='clientContent'>
                                                                        <FontAwesomeIcon icon={faMapPin}  style={{color : '#D49D29'}} className='clientAdrs' />
                                                                        <p className='customer-stylist-detail-contact'>{clientDetail.address1 + ', ' + clientDetail.address2}<br></br>{clientDetail.city}, {clientDetail.state+' '+clientDetail.postalCode}</p>
                                                                    </div>
                                                                </Col>
                                                            : 
                                                                ''
                                                        }
                                                        {
                                                            clientDetail.phone ? 
                                                                <Col className='col-12 stylist-add-cus' md='12'>
                                                                    <div className='clientContent'>
                                                                        <FontAwesomeIcon icon={faPhone}  style={{color : '#D49D29'}} className='clientAdrs'/>
                                                                        <p className='customer-stylist-detail-contact'><a href={'tel:'+clientDetail.phone} style={{color : '#fff', textDecoration : 'none'}}>{clientDetail.phone}</a></p>
                                                                    </div>
                                                                </Col>
                                                            : 
                                                                ''
                                                        }
                                                        <Col className='col-12' md='12'>
                                                            <div className='clientSendMessage'>
                                                                <p onClick={sendMessageHandleClick}>Send Message</p>
                                                            </div>
                                                        </Col>
                                                    </Col>
                                                    <hr />
                                                </Row>
                                            </div>
                                            <div className="text-start ">
                                                <strong>Hours of Operation <span>( {stylistTimezone} )</span></strong>
                                                <Row className="justify-content-md-center text-center availbleTimeList">
                                                    <div className='booking-details'>
                                                        <Col md={12}>
                                                            <Row className='text-start'>
                                                                {
                                                                    daySlotList.mondayOpen || daySlotList.mondayClose ?
                                                                        <>
                                                                            <Col xs={5} md={6}>
                                                                                <h4 onClick={serviceListHandleClick} className='dayService'>Monday</h4>    
                                                                            </Col>
                                                                            <Col>
                                                                                <p>
                                                                                    <span onClick={serviceListHandleClick} className='dayService'>{daySlotList.mondayOpen} - {daySlotList.mondayClose}</span>
                                                                                </p>
                                                                            </Col>
                                                                        </>
                                                                    :
                                                                        <>
                                                                            <Col xs={5} md={6}>
                                                                                <h4>Monday</h4>    
                                                                            </Col>
                                                                            <Col>
                                                                                <p>
                                                                                    <span className='timeClosed'>Closed</span>
                                                                                </p> 
                                                                            </Col>
                                                                        </>
                                                                }
                                                            </Row>
                                                            <Row className='text-start'>
                                                                {
                                                                    daySlotList.tuesdayOpen || daySlotList.tuesdayClose ?
                                                                        <>
                                                                            <Col xs={5} md={6}>
                                                                                <h4 onClick={serviceListHandleClick} className='dayService'>Tuesday</h4>    
                                                                            </Col>
                                                                            <Col>
                                                                                <p>
                                                                                    <span onClick={serviceListHandleClick} className='dayService'>{daySlotList.tuesdayOpen} - {daySlotList.tuesdayClose}</span>
                                                                                </p>
                                                                            </Col>
                                                                        </>
                                                                    :
                                                                        <>
                                                                            <Col xs={5} md={6}>
                                                                                <h4>Tuesday</h4>    
                                                                            </Col>
                                                                            <Col>
                                                                                <p>
                                                                                    <span className='timeClosed'>Closed</span>
                                                                                </p> 
                                                                            </Col>
                                                                        </>
                                                                }
                                                            </Row>
                                                            <Row className='text-start'>
                                                                {
                                                                    daySlotList.wednessdayOpen || daySlotList.wednessdayClose ?
                                                                        <>
                                                                            <Col xs={5} md={6}>
                                                                                <h4 onClick={serviceListHandleClick} className='dayService'>Wednesday</h4>    
                                                                            </Col>
                                                                            <Col>
                                                                                <p>
                                                                                    <span onClick={serviceListHandleClick} className='dayService'>{daySlotList.wednessdayOpen} - {daySlotList.wednessdayClose}</span>
                                                                                </p>
                                                                            </Col>
                                                                        </>
                                                                    :
                                                                        <>
                                                                            <Col xs={5} md={6}>
                                                                                <h4>Wednesday</h4>    
                                                                            </Col>
                                                                            <Col>
                                                                                <p>
                                                                                    <span className='timeClosed'>Closed</span>
                                                                                </p> 
                                                                            </Col>
                                                                        </>
                                                                }
                                                            </Row>
                                                            <Row className='text-start'>
                                                                {
                                                                    daySlotList.thursdayOpen || daySlotList.thursdayClose ?
                                                                        <>
                                                                            <Col xs={5} md={6}>
                                                                                <h4 onClick={serviceListHandleClick} className='dayService'>Thursday</h4>    
                                                                            </Col>
                                                                            <Col>
                                                                                <p>
                                                                                    <span onClick={serviceListHandleClick} className='dayService'>{daySlotList.thursdayOpen} - {daySlotList.thursdayClose}</span>
                                                                                </p>
                                                                            </Col>
                                                                        </>
                                                                    :
                                                                        <>
                                                                            <Col xs={5} md={6}>
                                                                                <h4>Thursday</h4>    
                                                                            </Col>
                                                                            <Col>
                                                                                <p>
                                                                                    <span className='timeClosed'>Closed</span>
                                                                                </p> 
                                                                            </Col>
                                                                        </>
                                                                }
                                                            </Row>
                                                            <Row className='text-start'>
                                                                {
                                                                    daySlotList.fridayOpen || daySlotList.fridayClose ?
                                                                        <>
                                                                            <Col xs={5} md={6}>
                                                                                <h4 onClick={serviceListHandleClick} className='dayService'>Friday</h4>    
                                                                            </Col>
                                                                            <Col>
                                                                                <p>
                                                                                    <span onClick={serviceListHandleClick} className='dayService'>{daySlotList.fridayOpen} - {daySlotList.fridayClose}</span>
                                                                                </p>
                                                                            </Col>
                                                                        </>
                                                                    :
                                                                        <>
                                                                            <Col xs={5} md={6}>
                                                                                <h4>Friday</h4>    
                                                                            </Col>
                                                                            <Col>
                                                                                <p>
                                                                                    <span className='timeClosed'>Closed</span>
                                                                                </p> 
                                                                            </Col>
                                                                        </>
                                                                }
                                                            </Row>
                                                            <Row className='text-start'>
                                                                {
                                                                    daySlotList.saturdayOpen || daySlotList.saturdayClose ?
                                                                        <>
                                                                            <Col xs={5} md={6}>
                                                                                <h4 onClick={serviceListHandleClick} className='dayService'>Saturday</h4>    
                                                                            </Col>
                                                                            <Col>
                                                                                <p>
                                                                                    <span onClick={serviceListHandleClick} className='dayService'>{daySlotList.saturdayOpen} - {daySlotList.saturdayClose}</span>
                                                                                </p>
                                                                            </Col>
                                                                        </>
                                                                    :
                                                                        <>
                                                                            <Col xs={5} md={6}>
                                                                                <h4>Saturday</h4>    
                                                                            </Col>
                                                                            <Col>
                                                                                <p>
                                                                                    <span className='timeClosed'>Closed</span>
                                                                                </p> 
                                                                            </Col>
                                                                        </>
                                                                }
                                                            </Row>
                                                            <Row className='text-start'>
                                                                {
                                                                    daySlotList.sundayOpen || daySlotList.sundayClose ?
                                                                        <>
                                                                            <Col xs={5} md={6}>
                                                                                <h4 onClick={serviceListHandleClick} className='dayService'>Sunday</h4>    
                                                                            </Col>
                                                                            <Col>
                                                                                <p>
                                                                                    <span onClick={serviceListHandleClick} className='dayService'>{daySlotList.sundayOpen} - {daySlotList.sundayClose}</span>
                                                                                </p>
                                                                            </Col>
                                                                        </>
                                                                    :
                                                                        <>
                                                                            <Col xs={5} md={6}>
                                                                                <h4>Sunday</h4>    
                                                                            </Col>
                                                                            <Col>
                                                                                <p>
                                                                                    <span className='timeClosed'>Closed</span>
                                                                                </p> 
                                                                            </Col>
                                                                        </>
                                                                }
                                                            </Row>
                                                        </Col>
                                                    </div>
                                                </Row>
                                            </div>
                                        </Card>
                                    </Col>
                                    <Col md='7' style={{position : 'relative'}}>
                                        {
                                            userData !== null ?
                                                <div className='customerFavouriteIcon profilePage'>
                                                    {
                                                        favouriteProfessional === true ?
                                                            <OverlayTrigger 
                                                                placement="bottom"
                                                                overlay={<Tooltip id="button-tooltip-2">Remove from favourites</Tooltip>}
                                                            >
                                                                <FontAwesomeIcon icon={faHeart} onClick={() => favouritehandleClick('remove')}/>
                                                            </OverlayTrigger>
                                                        :
                                                            <OverlayTrigger 
                                                                placement="bottom"
                                                                overlay={<Tooltip id="button-tooltip-2">Add to favourites</Tooltip>}
                                                            >
                                                                <FontAwesomeIcon icon={farHeart} onClick={() => favouritehandleClick('add')}/>
                                                            </OverlayTrigger>
                                                    }
                                                </div>
                                            :
                                                ''
                                        }
                                        <Stack direction='horizontal' className='backStack'>
                                            <div>
                                                <h2>Services Offered</h2>
                                            </div>
                                            {/*<div className='ms-auto'>
                                              <Button variant="outline-secondary" onClick={handlePrevPage}>Back</Button>
                                                    </div>*/}
                                        </Stack>
                                        <div className="table-responsive serviceOfferDsk">
                                            <Table striped bordered hover variant="dark">
                                                <thead>
                                                    <tr>
                                                        <th>Service</th>
                                                        <th>Cost</th>
                                                        <th>Duration</th>
                                                        <th>Select Service</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {
                                                        clientDetail.serviceOfferedList ?
                                                            clientDetail.serviceOfferedList.map((list, index) => (
                                                                <tr key={index}>
                                                                    <td>{list.subServiceName}<span style={{display : 'block', fontSize :'12px'}}><i>{list.serviceDescription}</i></span></td>
                                                                    <td>${getFullCost(list.price)}</td>
                                                                    <td>{list.duration} Minutes</td>
                                                                    <td>
                                                                        {
                                                                            allDayOpen === true ?
                                                                                <Link to={"../customer/bookingcalendar/"+paramsName.id+"/"+list.productId} className='btn btn-success' >Select</Link>
                                                                            :
                                                                                '-'
                                                                        }
                                                                    </td>
                                                                </tr>
                                                            ))
                                                        :
                                                            <></>
                                                    }
                                                </tbody>
                                            </Table>
                                        </div>
                                        <div className='serviceOfferMbl'>
                                            {
                                                clientDetail.serviceOfferedList ?
                                                    clientDetail.serviceOfferedList.map((list, index) => (
                                                        <Card className='mb-2' key={index}>
                                                            <Card.Body>
                                                                <Card.Text>
                                                                    <Row>
                                                                        <Col>
                                                                            <Stack direction="vertical" gap={1} className='serviceListOffered'>
                                                                                <div className="serviceOfferContent"><DesignServicesIcon /> {list.subServiceName}<br /><span style={{display : 'block', fontSize :'12px'}}><i>({list.serviceDescription})</i></span></div>
                                                                                <div className=''><PaidIcon /> {getFullCost(list.price)}</div>
                                                                                <div className=''><AccessTimeIcon /> {list.duration} Minutes</div>
                                                                                <div>
                                                                                {
                                                                                    allDayOpen === true ?
                                                                                        <><EventAvailableIcon /> <Link to={"../customer/bookingcalendar/"+paramsName.id+"/"+list.productId} className='btn btn-success'>Select Service</Link></>
                                                                                    :
                                                                                        ''
                                                                                }
                                                                                </div>
                                                                                {/* <div><EventAvailableIcon /> <Link to={"../customer/bookingcalendar/"+paramsName.id+"/"+list.productId} className='btn btn-success'>Check Availabilty</Link></div> */}
                                                                            </Stack>
                                                                        </Col>
                                                                    </Row>
                                                                </Card.Text>
                                                            </Card.Body>
                                                        </Card>
                                                  ))
                                                :
                                                    <></>
                                            }
                                        </div>
                                        <Modal show={show} onHide={handleClose} className="bookingModal">
                                            <Modal.Header closeButton>
                                                <Modal.Title></Modal.Title>
                                            </Modal.Header>
                                            <Modal.Body>
                                                {
                                                    portfolioImage ?
                                                        <>
                                                            <Image variant="top" className='img-responsive' src={portfolioImage.imageUrl}/>
                                                            <br></br><br></br>
                                                            <p style={{wordBreak : 'break-word'}}>{portfolioImage.description}</p>
                                                        </>
                                                    :
                                                        ''
                                                }
                                            </Modal.Body>
                                        </Modal>
                                        {
                                            sendMsgPopupStatus === true ?
                                                <SendMessagePopup data={clientDetail} getBack={sendMessagePopupGetBack}/>
                                            : 
                                                ''
                                        }
                                        {
                                            serviceListPopupStatus ? 
                                                <ServiceListPopup allDayOpen={allDayOpen} service={clientDetail.serviceOfferedList} getBack={serviceListPopupGetBack}/>
                                            :
                                                ''
                                        }
                                    </Col>
                                </Row>
                            </Container>
                    }
                </div>
            </section>
        </div>
    )
}       