import { React, useState } from "react";
import { Modal, Table, Card, Row, Col, Stack } from "react-bootstrap"; 
import { Link, useNavigate, useParams } from 'react-router-dom';
import DesignServicesIcon from '@mui/icons-material/DesignServices';
import PaidIcon from '@mui/icons-material/Paid';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import EventAvailableIcon from '@mui/icons-material/EventAvailable';

export default function ServiceListPopup({allDayOpen, service, getBack}){

    const paramsName = useParams();
    const navigate = useNavigate();

    // initial state declaration
    const [popupShow, setPopupShow] = useState(true);

    // popup close fucntion
    const popupHandleClose = () => {
        getBack(false);
        setPopupShow(false);
    }

    // get decimal two degit cost
    const getFullCost = (dollar) => {
        let roundCost = (Math.floor(dollar * 100) / 100).toFixed(2);
        return roundCost;
    }

    return(
        <Modal show={popupShow} onHide={popupHandleClose} className="custom-modal addtippopup" >
            <Modal.Header closeButton>
                <Modal.Title>Services Offered</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div className="table-responsive serviceOfferDsk">
                    <Table striped bordered hover variant="dark">
                        <thead>
                            <tr>
                                <th>Service</th>
                                <th>Cost</th>
                                <th>Duration</th>
                                <th>Select Service</th>
                            </tr>
                        </thead>
                        <tbody>
                            {
                                service ?
                                    service.map((list, index) => (
                                        <tr key={index}>
                                            <td>{list.subServiceName}<span style={{display : 'block', fontSize :'12px'}}><i>{list.serviceDescription}</i></span></td>
                                            <td>${list.price.toFixed(2)}</td>
                                            <td>{list.duration} Minutes</td>
                                            <td>
                                                {
                                                    allDayOpen === true ?
                                                        <Link to={"../customer/bookingcalendar/"+paramsName.id+"/"+list.productId} className='btn btn-success' >Select</Link>
                                                    :
                                                        '-'
                                                }
                                            </td>
                                        </tr>
                                    ))
                                :
                                    <></>
                            }
                        </tbody>
                    </Table>
                </div>

                <div className='serviceOfferMbl'>
                    {
                        service ?
                            service.map((list, index) => (
                                <Card className='mb-2' key={index}>
                                    <Card.Body>
                                        <Card.Text>
                                            <Row>
                                                <Col>
                                                    <Stack direction="vertical" gap={1} className='serviceListOffered'>
                                                        <div className="serviceOfferContent"><DesignServicesIcon /> {list.subServiceName}<br /><span style={{display : 'block', fontSize :'12px'}}><i>({list.serviceDescription})</i></span></div>
                                                        <div className=''><PaidIcon /> {list.price.toFixed(2)}</div>
                                                        <div className=''><AccessTimeIcon /> {list.duration} Minutes</div>
                                                        <div>
                                                        {
                                                            allDayOpen === true ?
                                                                <>
                                                                    <EventAvailableIcon /> 
                                                                    <Link to={"../customer/bookingcalendar/"+paramsName.id+"/"+list.productId} className='btn btn-success'>Select Service</Link>
                                                                </>
                                                            :
                                                                ''
                                                        }
                                                        </div>
                                                    </Stack>
                                                </Col>
                                            </Row>
                                        </Card.Text>
                                    </Card.Body>
                                </Card>
                            ))
                        :
                            <></>
                    }
                </div>
            </Modal.Body>
        </Modal>
    )
}