import { React, useEffect, useState } from 'react';
import { Col, Container, Image, Row, Button, Stack, OverlayTrigger, Popover } from 'react-bootstrap'; 
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleExclamation } from '@fortawesome/free-solid-svg-icons';
import userImage from "../../../images/booking-profile.png";
import Topbar from '../Assets/Topbar';
import LeftSidebar from '../Assets/LeftSidebar';
import { InfinitySpin } from 'react-loader-spinner';
import { useParams, useNavigate } from 'react-router-dom';
import moment from 'moment';
import { Email, Phone } from '@mui/icons-material';
import { postAdminBookingDetails } from '../../../Service';


export default function AdminSingleBookingDetails() {

    const params = useParams();
    const navigate = useNavigate();

    const [bookingData, setBookingData] = useState({});
    const [apiError, setApiError] = useState(false);
    const [pageLoader, setPageLoader]  = useState(true);

    useEffect(() => {
        let bookingDetailsParams = { 'bookingId' : params.bookingId  };
        postAdminBookingDetails(bookingDetailsParams).then(res => getBookingDetails(res));
    }, []);

    const getBookingDetails = ( res ) => {

        if ( res.data.statusCode === 200 ) {

            setBookingData(res.data.customerBookingList[0]);
            setPageLoader(false);

        } else {

            setApiError(true);
            setPageLoader(false);

        }

    }

    // get decimal two degit cost
    const getFullCost = ( dollar ) => {
        let roundCost = (Math.floor(dollar * 100) / 100).toFixed(2);
        return roundCost;
    }

    const handlePrevPage = () => {
        navigate(-1);
    }

    return (
        <section className='dashboard'>
            <Container>
                <Row className='sideBarRow'>
                    <Col md={3} className='sideBarCol'>
                        <LeftSidebar/>
                    </Col>
                    <Col md={9}>
                        <div className='rightsidebar'>
                            <Topbar/>
                                <Row className="mb-3 main-title">
                                    <Col>
                                        <hr />
                                        <Stack direction='horizontal' className='stackInside'>
                                            <div >
                                                <h1>Booking Details</h1>
                                            </div>
                                            <div className="ms-auto">
                                                <Button variant="outline-secondary" onClick={() => handlePrevPage()}>Back</Button>
                                            </div>
                                        </Stack>
                                    </Col>
                                </Row>
                                {
                                    pageLoader ?
                                        <Row className="justify-content-md-center text-center">
                                            <div className='booking-details'>
                                                <InfinitySpin height="80" width="200" radius="9" color="#D49D29" ariaLabel="loading" wrapperStyle wrapperClass />
                                            </div>
                                        </Row>
                                        
                                    :
                                        apiError ? 
                                            <Row className="justify-content-md-center text-center">
                                                <div className='booking-details'>
                                                    <h4>Something went wrong. Please try after sometimes.</h4>
                                                </div>
                                            </Row>
                                        :
                                            <Row className="justify-content-md-center text-center">
                                                <div className='booking-details'>
                                                    <Col md={12}>
                                                        <div className='profile'>
                                                            <Image src={userImage} />
                                                        </div>
                                                        {
                                                            bookingData.bookingType != 'CLSB' ?
                                                                <>
                                                                    <h2>
                                                                        {bookingData.customerName}
                                                                        <span className='bookcal-cus-details'>
                                                                            <a href={"mailto:"+bookingData.email}><Email /> {bookingData.email}</a>

                                                                            <a href={"tel:"+bookingData.phoneNumber}><Phone /> {bookingData.phoneNumber}</a>
                                                                        </span>
                                                                    </h2>
                                                                    <Row className='text-start'>
                                                                        <Col>
                                                                            <h4>Service Offered</h4>    
                                                                        </Col>
                                                                        <Col>
                                                                            <p>{bookingData.serviceName}</p> 
                                                                        </Col>
                                                                    </Row>
                                                                    <Row className='text-start'>
                                                                        <Col>
                                                                            <h4>Date</h4>   
                                                                        </Col>
                                                                        <Col>
                                                                            <p>{ moment(new Date(bookingData.startDateTime)).format('MM-DD-YYYY') }</p> 
                                                                        </Col>
                                                                    </Row>
                                                                    <Row className='text-start'>
                                                                        <Col>
                                                                            <h4>Time</h4>  
                                                                        </Col>
                                                                        <Col>
                                                                            <p>
                                                                                {
                                                                                    moment(new Date(bookingData.startDateTime)).format('LT')
                                                                                    + " " + "-" +  " " +
                                                                                    moment(new Date(bookingData.endDateTime)).format('LT')
                                                                                }
                                                                            </p>
                                                                        </Col>
                                                                    </Row>
                                                                    <Row className='text-start'>
                                                                        <Col>
                                                                            <h4>Service Charge fee</h4> 
                                                                        </Col>
                                                                        <Col>
                                                                            <p>
                                                                                ${bookingData.payment.toFixed(2)}
                                                                                <sup className='payment-icon'>
                                                                                    <OverlayTrigger trigger="click" placement="left" rootClose={true}
                                                                                        overlay={
                                                                                            <Popover id={'popover-payment'} style={{"background":"#222", "color":"#fff"}}>
                                                                                            <div>
                                                                                                <h5>Service : ${bookingData.serviceCost.toFixed(2)} </h5>  
                                                                                                <h5>Tip : ${bookingData.tip.toFixed(2)}</h5>  
                                                                                                <h5>Platform Fees : ${bookingData.platformFees.toFixed(2)}</h5>  
                                                                                                <p><strong>Total : </strong>${bookingData.payment.toFixed(2)}</p>
                                                                                            </div>
                                                                                            </Popover>
                                                                                        }
                                                                                    >
                                                                                        <FontAwesomeIcon icon={faCircleExclamation} />
                                                                                    </OverlayTrigger>
                                                                                </sup>  
                                                                            </p>
                                                                        </Col>
                                                                    </Row>
                                                                    <Row className='text-start'>
                                                                        <Col>
                                                                            <h4>Payment Type</h4> 
                                                                        </Col>
                                                                        <Col>
                                                                            <p>{bookingData.paymentType}</p>
                                                                        </Col>
                                                                    </Row>
                                                                    <Row className='text-start'>
                                                                        <Col>
                                                                            <h4>Status</h4> 
                                                                        </Col>
                                                                        <Col>
                                                                            <p className='' style={{float: 'right'}}>
                                                                                {
                                                                                    bookingData.status === 'Booked' ? (bookingData.bookingType == 'CL' || bookingData.bookingType == 'CLEB') && bookingData.isCustomerRespondent === false ? 'Verification Pending' : bookingData.status : bookingData.status
                                                                                }
                                                                            </p>
                                                                        </Col>
                                                                    </Row>
                                                                    <Row className='text-start'>
                                                                        <Col>
                                                                            <h4>Booked / Edited By</h4> 
                                                                        </Col>
                                                                        <Col>
                                                                            <p className='' style={{float: 'right'}}>
                                                                                {
                                                                                    bookingData.bookingType == 'CS' ? 'Customer' : (bookingData.bookingType == 'CL' || bookingData.bookingType == 'CLEB') ? 'Professional' : 'Self Booking'
                                                                                }
                                                                            </p>
                                                                        </Col>
                                                                    </Row>
                                                                </>
                                                            :
                                                                <>
                                                                    <h2>Self Booking</h2>
                                                                    <Row className='text-start'>
                                                                        <Col>
                                                                            <h4>Date</h4>   
                                                                        </Col>
                                                                        <Col>
                                                                            <p>{ moment(new Date(bookingData.startDateTime)).format('MM-DD-YYYY') }</p> 
                                                                        </Col>
                                                                    </Row>
                                                                    <Row className='text-start'>
                                                                        <Col>
                                                                            <h4>Time</h4>  
                                                                        </Col>
                                                                        <Col>
                                                                            <p>
                                                                                {
                                                                                    moment(new Date(bookingData.startDateTime)).format('LT')
                                                                                    + " " + "-" +  " " +
                                                                                    moment(new Date(bookingData.endDateTime)).format('LT')
                                                                                }
                                                                            </p>
                                                                        </Col>
                                                                    </Row>
                                                                </>
                                                        }
                                                    </Col>
                                                </div>
                                            </Row>
                                }
                                
                        </div>
                    </Col>
                </Row>
            </Container>
        </section>
    )
}