export const BlockInvalidNumber = ( e ) => {
    ['e', 'E', '+', '-'].includes(e.key) && e.preventDefault()
}

export const BlockSQLInjection = ( e ) => {
    ['<', '>'].includes(e.key) && e.preventDefault()
}

export const HtmlTagValidation = ( value ) => {
    let status = true;
    let tagValidate =/<(.|\n)*?>/g; 

    if ( tagValidate.test(value) ) {
        status = false;
    }

    return status;
}

export const checkConfirmBooking = (e) => {
    const tooltipElement = e.appointments;
    
    setTimeout(() => {
        const listItems = document.querySelectorAll('.dx-list-item');
        listItems.forEach((data, index) => {
            let elementdata = tooltipElement[index].appointmentData;

            if ( elementdata.status == 'Completed' || elementdata.status == 'NoShow' ) {
                data.classList.add('completedBookingCalendar');
            }

        });
    }, 100);
    
};

export const bookingcalendarListColor = [
    {
        text : 'completedBooking',
        id : 1,
        color : '#008000'
    }, 
    {
        text : 'selfBooking',
        id : 2,
        color : '#6c757d'
    }, 
    {
        text : 'upcommingBokking',
        id : 3,
        color : '#ff5200'
    }
]