import { React, useState, useEffect } from 'react';
import { Container, Row, Col, Stack, Button, Table, Form} from "react-bootstrap";
import Topbar from '../Assets/Topbar';
import LeftSidebar from '../Assets/LeftSidebar';
import { adminTransactionList } from '../../../Service';
import { InfinitySpin } from 'react-loader-spinner';
import moment from 'moment';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFilter, faFilterCircleXmark, faArrowDownAZ, faArrowDownZA, faArrowDownWideShort, faArrowDownShortWide } from '@fortawesome/free-solid-svg-icons';
import PaginationDesign from '../Assets/Pagination';
import { HtmlTagValidation, BlockSQLInjection } from '../../Reusable';
import moments from 'moment-timezone';
import { useNavigate } from 'react-router-dom';

export default function ManageStylist() {
    // state declaration
    const [transactionList, setTransactionList] = useState([]);
    const [transactionLoader, setTransactionLoader] = useState(true);
    const searchInitialValues = {searchByProfessionalName : '', searchByCustomerName : '', searchByBusinessName : '', searchByStartDate : '', searchByEndDate : '', searchByMinimumAmount : '', searchByMaximumAmount : ''};
    const [searchFormValues, setSearchFormValues] = useState(searchInitialValues);
    const [searchStatus, setSearchStatus] = useState(false);
    const [searchErrors ,setSearchErrors] = useState('');
    const [currentPage, setCurrentPage] = useState(1);
    const [listCount, setListCount] = useState(0);
    const [perPage, setPerPage] = useState(10);
    const [sortBy, setSortBy] = useState('Date');
    const [sortDir, setSortDir] = useState('desc');
    const [searchBTNStatus, setSearchBTNStatus] = useState(false);
    const [clearbtnDisable, setClearbtnDisable] = useState(true);

    const navigate = useNavigate();

    // get transaction list from api
    useEffect(() => { 
        setTransactionLoader(true);
        let transactionParams = {'searchProfessionalName' : searchFormValues.searchByProfessionalName, 'searchCustomerName' : searchFormValues.searchByCustomerName, 'searchBusinessName' : searchFormValues.searchByBusinessName, 'searchStartDate' : searchFormValues.searchByStartDate, 'searchEndDate' : searchFormValues.searchByEndDate, 'pageNo' : currentPage, 'pageSize' : perPage, 'sortColumn' : sortBy, 'sortDirection' : sortDir, 'serachMinAmount' : searchFormValues.searchByMinimumAmount, 'searchMaxAmount' : searchFormValues.searchByMaximumAmount};
        adminTransactionList(transactionParams).then(res => initialResponse(res));
    },[currentPage, sortBy, sortDir, perPage]);

    // transaction api response
    const initialResponse = (response) => {
        if(response.data.statusCode === 200){
            let dataList = response.data.transactionList;
            let formatedList = [];
            dataList.map((list, index)=>{
                if(list.status !== 'Booked'){
                    let splitDate = list.date.split("/")[0];
                    let splitMonth = list.date.split("/")[1];
                    let splitYear = list.date.split("/")[2];
                    let dateFormat = splitMonth  +  "/"  + splitDate + "/" + splitYear;
                    let initialData = {"clientName": list.clientName, "createdDateTime": list.createdDateTime, "customerName": list.customerName, "date": dateFormat, "payment": list.payment, "shopName": list.shopName, "status": list.status, "time": list.time, "paymentStatus" : list.paymentStatus, 'timeZone' : list.timeZone, "transactionDateTime" : list.transactionDateTime, "receivedPayment" : list.receivedPayment, "subServiceName" : list.subServiceName, "bookingType" : list.bookingType, 'settlementLocalDateTime' : list.settlementLocalDateTime, 'bookingId' : list.bookingId
                    }
                    formatedList.push(initialData);
                }
            })
            setTransactionList(formatedList);
            setTransactionLoader(false);
            setListCount(response.data.filteredCount);
            setSearchBTNStatus(false);
        }else{
            setTransactionLoader(false);
        }
    }

    //get UTC time to Local time
    const getUTCtoLocal = (onlyTime) => {
        var local_date= moment.utc(onlyTime).local().format("YYYY-MM-DD HH:mm");

        return local_date;
    }

    // new filter button onclivk
    const searchOnClick = () => {
        setSearchStatus(true);
    }

    // clear filter button onclick
    const clearSearchOnClick = () => {
        setSearchStatus(false);
        setSearchFormValues(searchInitialValues);
        setSearchErrors('');
        setCurrentPage(1);
        setListCount(0);
        setPerPage(10);
        setSortBy('Date');
        setSortDir('desc');
        setTransactionLoader(true);
        setClearbtnDisable(true);

        let transactionParams = {'searchProfessionalName' : '', 'searchCustomerName' : '', 'searchBusinessName' : '', 'searchStartDate' : '', 'searchEndDate' : '', 'pageNo' : 1, 'pageSize' : 10, 'sortColumn' : 'Date', 'sortDirection' : 'desc', 'serachMinAmount' : '', 'searchMaxAmount' : ''};
        adminTransactionList(transactionParams).then(res => initialResponse(res));
    }

    // search button onclick
    const searchHandleChange = (e) => {
        const value = e.target.value;
        const field= e.target.name;
        if(field === 'searchByMinimumAmount' || field === 'searchByMaximumAmount'){
            let priceLength = value.length;
            if(priceLength <= 5){
                setSearchFormValues(prevState => ({
                    ...prevState,
                    [field]:  value  
                }));
            }
        }else{
            setSearchFormValues(prevState => ({
              ...prevState,
              [field]:  value  
            }));
        }
    }
    
    const getLocalTimeZone = () => {

        const getZoneCode = moments.tz.guess();
        // return getZoneCode;
        var timeZoneOffset = new Date().getTimezoneOffset();
        // return moment.tz.zone(getZoneCode).abbr(timeZoneOffset);
    }

    // search api response
    const searchHandleSearch = (e) => {
        e.preventDefault();
        const validate = searchValidate(searchFormValues);
        setSearchErrors(validate.search);
        if(validate.status){
            setCurrentPage(1);
            setPerPage(10);
            setTransactionLoader(true);
            setSearchBTNStatus(true);
            setClearbtnDisable(false);

            let transactionParams = {'searchProfessionalName' : searchFormValues.searchByProfessionalName, 'searchCustomerName' : searchFormValues.searchByCustomerName, 'searchBusinessName' : searchFormValues.searchByBusinessName, 'searchStartDate' : searchFormValues.searchByStartDate, 'searchEndDate' : searchFormValues.searchByEndDate, 'pageNo' : 1, 'pageSize' : 10, 'sortColumn' : sortBy, 'sortDirection' : sortDir, 'serachMinAmount' : searchFormValues.searchByMinimumAmount, 'searchMaxAmount' : searchFormValues.searchByMaximumAmount};
            adminTransactionList(transactionParams).then(res => initialResponse(res));
        }
    }

    // search input fields validation
    const searchValidate = (values) => {
        const errors = {};
        errors.status = true;
        var numbers = /^[0-9]+$/;
        let costStart = Number(values.searchByMinimumAmount);
        let costEnd = Number(values.searchByMaximumAmount);
        
        if((values.searchByProfessionalName == '' || values.searchByProfessionalName.trim() == '') && (values.searchByCustomerName == '' || values.searchByCustomerName.trim() == '') && (values.searchByBusinessName == '' || values.searchByBusinessName.trim() == '') && (values.searchByStartDate == '' || values.searchByStartDate.trim() == '') && (values.searchByEndDate == '' || values.searchByEndDate.trim() == '') && (values.searchByMinimumAmount == '' || values.searchByMinimumAmount.trim() === '') && (values.searchByMaximumAmount == '' || values.searchByMaximumAmount.trim() === '')){
            errors.search = 'Provide any search term';
            errors.status = false;
        }

        if(values.searchByStartDate || values.searchByEndDate){
            if(values.searchByStartDate == '' || values.searchByEndDate == ''){
                errors.search = 'Transactions start date and end date are required';
                errors.status = false;
            }else if(values.searchByStartDate && values.searchByEndDate){
                let endDate = moment(values.searchByEndDate).format('YYYY-MM-DD');
                let StartDate = moment(values.searchByStartDate).format('YYYY-MM-DD');

                if(StartDate > endDate){
                    errors.search = 'Transactions end date cannot be less than transactions start date';
                    errors.status = false;
                }
            }
        }

        if(values.searchByMinimumAmount || values.searchByMaximumAmount){
          if(values.searchByMinimumAmount == '' || values.searchByMaximumAmount == ''){
              errors.search = 'Service cost Min. value and service cost Max. value are required';
              errors.status = false;
          }else if(values.searchByMinimumAmount && values.searchByMaximumAmount){
                if(costEnd == 0){
                    errors.search = 'Max. Value cannot be zero';
                    errors.status = false;
                }else if(costStart > costEnd){
                    errors.search = 'Max. Value cannot be higher than Min. Value';
                    errors.status = false;
                }else if(!values.searchByMinimumAmount.match(numbers) || !values.searchByMaximumAmount.match(numbers)){
                    errors.search='Enter valid cost';
                    errors.status=false;
                }
          }
        }

        const validations = {
            searchByProfessionalName: HtmlTagValidation(values.searchByProfessionalName),
            searchByCustomerName: HtmlTagValidation(values.searchByCustomerName),
            searchByBusinessName: HtmlTagValidation(values.searchByBusinessName)
        };

        Object.entries(validations).forEach(([key, value]) => {
            if (!value) {
                errors.search = 'Invalid input';
                errors.status = false;
            }
        });

        return errors;
    }

    // call back function from pagination
    const getBackCurrentPage = (page, perPage) => {
      setPerPage(perPage);
      setCurrentPage(page);
    }

    // sorting icon onclick
    const sortingOnClick = (data) => {
      setSortBy(data.by);
      setSortDir(data.dir);
    }

    // get decimal two degit cost
    const getFullCost = (dollar) => {
        let roundCost = (Math.floor(dollar * 100) / 100).toFixed(2);
        return roundCost;
    }

    const BookingDetails = ( data ) => {
        navigate('../admin/AdminSingleBookingDetails/'+data.bookingId);
    }

    return(
        <section className='dashboard'>
            <Container>
              <Row className='sideBarRow'>
                  <Col md={3} className='sideBarCol'>
                      <LeftSidebar/>
                  </Col>
                  <Col md={9}>
                      <div className='rightsidebar'>
                          <Topbar/>
                          <Row className="mb-3 main-title">
                              <Col>
                                  <h1>Transactions</h1>
                              </Col>
                          </Row> 
                          <Row className="mb-3 managesearch">
                              <Col>
                                  <Stack direction="horizontal" className="align-items-end" gap={2}>
                                      <div className="add-btn ms-auto search-status-btn">
                                          {
                                              searchStatus == false ?
                                                  <Button variant="primary" onClick={searchOnClick}>New Filter <FontAwesomeIcon icon={faFilter} /></Button>
                                              :
                                                  ''
                                          }
                                      </div>
                                  </Stack>
                              </Col>
                          </Row> 
                          <Row>
                              <Form onSubmit={searchHandleSearch}>
                                  <Row className='searchRow'>
                                      {
                                          searchStatus == false ? 
                                              ''
                                          :
                                              <>
                                                  <Row>
                                                      <Col lg={6}>
                                                          <Row>
                                                              <Col md={6}>
                                                                  <Form.Group as={Col} controlId="formGridSearchName">
                                                                      <Form.Label>Professional Name</Form.Label>
                                                                      <Form.Control onKeyDown={BlockSQLInjection} type="text" name='searchByProfessionalName' placeholder='Professional name...' onChange={searchHandleChange}/>
                                                                  </Form.Group>
                                                              </Col>
                                                              <Col md={6}>
                                                                  <Form.Group as={Col} controlId="formGridSearchName">
                                                                      <Form.Label>Customer Name</Form.Label>
                                                                      <Form.Control onKeyDown={BlockSQLInjection} type="text" name='searchByCustomerName' placeholder='Customer name...' onChange={searchHandleChange}/>
                                                                  </Form.Group>
                                                              </Col>
                                                          </Row>
                                                      </Col>
                                                      <Col lg={6}>
                                                          <Row>
                                                              <Col md={4}>
                                                                  <Form.Group as={Col} controlId="formGridSearchName">
                                                                      <Form.Label>Business Name</Form.Label>
                                                                      <Form.Control onKeyDown={BlockSQLInjection} type="text" name='searchByBusinessName' placeholder='Business name...' onChange={searchHandleChange}/>
                                                                  </Form.Group>
                                                              </Col>
                                                              <Col md={4}>
                                                                  <Form.Group as={Col} controlId="formGridSearchName">
                                                                      <Form.Label>Start Date</Form.Label>
                                                                      <Form.Control type="date" name='searchByStartDate' onChange={searchHandleChange}/>
                                                                  </Form.Group>
                                                              </Col>
                                                              <Col md={4}>
                                                                  <Form.Group as={Col} controlId="formGridSearchName">
                                                                      <Form.Label>End Date</Form.Label>
                                                                      <Form.Control type="date" name='searchByEndDate' onChange={searchHandleChange}/>
                                                                  </Form.Group>
                                                              </Col>
                                                          </Row>
                                                      </Col>
                                                  </Row>
                                                  <Row className='searchNewRow'>
                                                      <Col lg={6}>
                                                          <Row>
                                                              <Col md={6}>
                                                                  <Form.Group as={Col} controlId="formGridSearchName">
                                                                      <Form.Label>Min. Value (in $)</Form.Label>
                                                                      <Form.Control type="number" placeholder='Service cost...' name='searchByMinimumAmount' onChange={searchHandleChange} value={searchFormValues.searchByMinimumAmount}/>
                                                                  </Form.Group>
                                                              </Col>
                                                              <Col md={6}>
                                                                  <Form.Group as={Col} controlId="formGridSearchName">
                                                                      <Form.Label>Max. Value (in $)</Form.Label>
                                                                      <Form.Control type="number" placeholder='Service cost...' name='searchByMaximumAmount' onChange={searchHandleChange} value={searchFormValues.searchByMaximumAmount}/>
                                                                  </Form.Group>
                                                              </Col>
                                                          </Row>
                                                      </Col>
                                                      <Col className="searcchAllBtn adminTransClearSearch" lg={6}>
                                                          <Button variant="primary" type='submit'>Search</Button>
                                                          <Button variant="danger" disabled={clearbtnDisable} onClick={clearSearchOnClick} className='search-form-clear-btn'>Clear Filter <FontAwesomeIcon icon={faFilterCircleXmark} /></Button>
                                                      </Col>
                                                  </Row>
                                              </> 
                                      }
                                      <span className='error'>{searchErrors}</span>
                                  </Row>
                              </Form>
                          </Row>
                          <Row>
                              <Col>
                                  <div className="table-responsive" id="no-more-tables"> 
                                      <Table striped bordered hover variant="dark">
                                          <thead>
                                              <tr>
                                                  <th>S.No</th>
                                                  <th>
                                                      Business<br></br> Name
                                                      {
                                                          listCount > 0 ?
                                                              <span className='sort'> {sortBy == 'businessName' ? sortDir == 'asc' ? <FontAwesomeIcon icon={faArrowDownZA}  onClick={() => sortingOnClick({by : 'businessName', dir : 'desc'})}/> : <FontAwesomeIcon icon={faArrowDownAZ} onClick={() => sortingOnClick({by : 'businessName', dir : 'asc'})}/> : <FontAwesomeIcon icon={faArrowDownAZ} onClick={() => sortingOnClick({by : 'businessName', dir : 'asc'})}/>}</span>
                                                          : 
                                                              ''
                                                      }
                                                  </th>
                                                  <th>
                                                      Professional<br></br> Name
                                                      {
                                                          listCount > 0 ?
                                                              <span className='sort'> {sortBy == 'professionalName' ? sortDir == 'asc' ? <FontAwesomeIcon icon={faArrowDownZA}  onClick={() => sortingOnClick({by : 'professionalName', dir : 'desc'})}/> : <FontAwesomeIcon icon={faArrowDownAZ} onClick={() => sortingOnClick({by : 'professionalName', dir : 'asc'})}/> : <FontAwesomeIcon icon={faArrowDownAZ} onClick={() => sortingOnClick({by : 'professionalName', dir : 'asc'})}/>}</span>
                                                          : 
                                                              ''
                                                      }
                                                  </th>
                                                  <th>Customer<br></br> Name</th>
                                                  <th>
                                                    Check Out Date
                                                    {
                                                        listCount > 0 ?
                                                            <span className='sort'> {sortBy == 'Date' ? sortDir == 'asc' ? <FontAwesomeIcon icon={faArrowDownWideShort}  onClick={() => sortingOnClick({by : 'Date', dir : 'desc'})}/> : <FontAwesomeIcon icon={faArrowDownShortWide} onClick={() => sortingOnClick({by : 'Date', dir : 'asc'})}/> : <FontAwesomeIcon icon={faArrowDownShortWide} onClick={() => sortingOnClick({by : 'Date', dir : 'asc'})}/>}</span>
                                                        : 
                                                            ''
                                                    }
                                                  </th>
                                                  <th>Time</th>
                                                  <th>Amount</th>
                                                  <th>Service<br></br> Offered</th>
                                                  <th>Booked / <br></br>Edited By</th>
                                                  <th>Booking<br></br> Status</th>
                                                  <th>Payment<br></br> Status</th>
                                                  <th>Booking<br></br> Details</th>
                                              </tr>
                                          </thead>
                                          <tbody>
                                              {
                                                  transactionLoader === true ?
                                                      <tr>
                                                          <td colSpan='12' style={{textAlign : 'center', paddingLeft : '0px'}}><InfinitySpin height="80" width="200" radius="9" color="#D49D29" ariaLabel="loading" wrapperStyle wrapperClass /></td>
                                                      </tr>
                                                  :
                                                      transactionList.length > 0 ?
                                                          transactionList.map((data, index) => {
                                                              return(
                                                                <tr key={index}>
                                                                    <td className='table-row-number'>{index + 1 + (currentPage * perPage) - perPage}</td>
                                                                    <td data-title="Business Name">{data.shopName}</td>
                                                                    <td data-title="Professional Name">{data.clientName}</td>
                                                                    <td data-title="Customer Name">{data.customerName}</td>
                                                                    <td data-title="Check Out Date">{moment(data.settlementLocalDateTime).format('MM-DD-YYYY')}</td>
                                                                    <td data-title="Time">{moment(data.settlementLocalDateTime).format('hh:mm A')}</td>
                                                                    <td data-title="Amount">${data.receivedPayment.toFixed(2)}</td>
                                                                    <td data-title="Service Offered">{data.subServiceName}</td>
                                                                    <td data-title="Booked By">{data.bookingType == 'CS' ? 'Customer' : (data.bookingType == 'CL' || data.bookingType == 'CLEB') ? 'Professional' : data.bookingType == 'CLSB' ? 'Self Booking' : ''}</td>
                                                                    <td data-title="Booking Status">
                                                                        {
                                                                            data.status === 'Completed' ?
                                                                                <Button variant="success" className="admin-trans-btn success-btn">{data.status}</Button>
                                                                            :   data.status === 'Cancelled' ?
                                                                                <Button variant="danger" className="admin-trans-btn danger-btn">{data.status}</Button>
                                                                            :   data.status === 'Booked' ?
                                                                                <Button variant="success" className="admin-trans-btn success-btn">{data.status}</Button>
                                                                            :
                                                                                <Button variant="warning" className="admin-trans-btn warning-btn">{data.status}</Button>
                                                                        }
                                                                    </td>
                                                                    <td data-title="Payment Status">{data.paymentStatus == '' ? 'N/A' : data.paymentStatus}</td>
                                                                    <td data-title="Booking History" className='bookhistory-btn'>
                                                                        <Button variant="primary" onClick={() => BookingDetails(data)}>View</Button>
                                                                    </td>
                                                                </tr>
                                                              )
                                                          })
                                                      :
                                                          <tr>
                                                              <td colSpan='12' style={{textAlign : 'center'}} className='noDataFound'>No Data Found...</td>
                                                          </tr>
                                              }
                                          </tbody>
                                      </Table>
                                  </div>
                              </Col>
                          </Row>  
                          
                          {
                              listCount > 0 ?
                                  <PaginationDesign currentPage={currentPage} listCount={listCount} perPage={perPage} getBack={getBackCurrentPage}/>
                              : 
                                  ''
                          }
                      </div>
                  </Col>
              </Row>
            </Container>
        </section>
    )
} 