import React, { useState, useEffect, useRef } from 'react';
import { Container, Row, Col, Stack, Button, Form } from "react-bootstrap";
import { useDispatch } from 'react-redux';
import { Link, useNavigate } from "react-router-dom";
import { setStylistToken, setStylistUserData } from '../../../reducers/User/action';
import { doclientlogin, postResendVerificationEmail } from '../../../Service';
import Swal from "sweetalert2";
import { InfinitySpin } from 'react-loader-spinner';
import { useSelector } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEye, faEyeSlash } from "@fortawesome/free-solid-svg-icons";
import ReCAPTCHA from "react-google-recaptcha";
import { HtmlTagValidation, BlockSQLInjection} from '../../Reusable';
import { JsonEncode, JsonDecode } from '../../Reusable/JsonModify';

export default function Login() {
    // initial state declaration
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const recaptcha = useRef();

    const initialValues = {email: "", password: "" };
    const [formValues, setFormValues] = useState(initialValues);
    const [formErrors, setFormErrors] = useState({});
    const [loader, setLoader] = useState(false);
    const [passEye, setPassEye] = useState(false);

    // get customer privacy preference data from store
    const privacyStatus =  useSelector(store => store.user['privacypreference']);

    // get stylist data from store
    const userData =  useSelector(store => store.user['stylistuser']);
    const stylistLogoutUrl = useSelector(store => store.user.stylistLogoutUrl);

    useEffect(() => {
        
        if ( privacyStatus === true ) {
            var btn1 = document.getElementById("termsfeed_pc1_sticky_dnsmpi_element_open");
            var btn2 = document.getElementById("termsfeed_pc1_sticky_element_open");

            if ( btn1 && btn2 ) {
                btn1.classList.remove('ppbtnhide');
                btn2.classList.remove('ppbtnhide');
            }

        } else if ( privacyStatus === false ) {
            var btn1 = document.getElementById("termsfeed_pc1_sticky_dnsmpi_element_open");
            var btn2 = document.getElementById("termsfeed_pc1_sticky_element_open");

            if ( btn1 && btn2 ) {
                btn1.classList.add('ppbtnhide');
                btn2.classList.add('ppbtnhide');
            }

        }

        if ( userData != null ) {

            if ( userData.userType == 'Old User' ) {
                navigate('/stylist/dashboard');
            } else if ( userData.userType == 'New User' ) {
                navigate('/stylist/step-I');
            }

        }

    }, [])

    // Stylist login form functionality start here
    const handleChange = ( e ) =>{
        const value = e.target.value;
        const field = e.target.name;
        setFormValues(prevState =>({
            ...prevState,
            [field]: value  }));
    }

    const handleSubmit = async ( e ) => {
        e.preventDefault();
        const errorCount = validate(formValues);
        setFormErrors(errorCount);

        if(errorCount.status){
            // formValues['reCaptchaToken'] = recaptcha.current.getValue();

            let jsonData = await JsonEncode(formValues).then(result => {
                return result
            }).catch(error => {
                Swal.fire({
                    position: 'center',
                    icon: 'error',
                    title: 'Oops...',
                    showConfirmButton: false,
                    text: error,
                    timer: 3000
                }); 
            });
            
            if ( jsonData ) {
                let loginPayload = {data : jsonData};
                doclientlogin(loginPayload).then(res => handleResponse(res));
                setLoader(true);
            } else {
                Swal.fire({
                    position: 'center',
                    icon: 'error',
                    title: 'Oops...',
                    showConfirmButton: false,
                    text: 'Something went wrong, Please try after sometimes',
                    timer: 3000
                }); 
            }

        }
        
    }

    const handleResponse = async ( response ) => {
        let decryptData = await JsonDecode(response.data.data).then(result => {
            return result
        }).catch(error => {
            Swal.fire({
                position: 'center',
                icon: 'error',
                title: 'Oops...',
                showConfirmButton: false,
                text: error,
                timer: 3000
            }); 
        });

        if ( decryptData.statusCode ===200 ) {
            dispatch(setStylistToken(decryptData.jwtToken));
            const userData = { shopName:decryptData.shopName, shopId:decryptData.shopId, professionName:decryptData.professionName, professionId:decryptData.professionId, firstName:decryptData.firstName, lastName:decryptData.lastName, address1:decryptData.address1, address2:decryptData.address2, city:decryptData.city, county:decryptData.county, state:decryptData.state, zipCode:decryptData.zipCode, clientId:decryptData.clientId, emailAddress:decryptData.emailAddress, phoneNumber:decryptData.phoneNumber, profileImage:decryptData.profileImage, userType:decryptData.userType, }
            dispatch(setStylistUserData(userData));
            setLoader(false);

            if ( decryptData.userType == 'Old User' ) {

                if ( stylistLogoutUrl === undefined || stylistLogoutUrl === null ) {
                    navigate('/stylist/dashboard');
                } else {
                    navigate(stylistLogoutUrl);
                }

            } else if ( decryptData.userType == 'New User' ) {
                navigate('/stylist/step-I');
            }

        } else if ( decryptData.statusCode === 400 ) {
            // recaptcha.current.reset();
            Swal.fire({
                position: 'center',
                icon: 'error',
                title: 'Oops...',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                confirmButtonText: 'Resend',
                text: "Your email has not yet verified. To resend the verification email, click on Resend button.",
            }).then((result) => {

                if ( result.isConfirmed ) {
                    let resendParams = {'email' : formValues.email, 'userType' : 'Client'};
                    postResendVerificationEmail(resendParams).then(res => resendVerificationEmailResponse(res));
                }

            })
            setFormValues(formValues);
            setLoader(false);
        } else if ( decryptData.statusCode === 202 ) {
            // recaptcha.current.reset();
            Swal.fire({
                title: "Your account has been suspended",
                text: "Please contact Admin - unextpro@gmail.com",
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                confirmButtonText: 'Contact Now'
            }).then((result) => {

                if ( result.isConfirmed ) {
                    window.location.href = "mailto:unextpro@gmail.com?subject=My professional account is suspended!";
                }

            })
            setFormValues(formValues);
            setLoader(false);
        } else {
            // recaptcha.current.reset();
            Swal.fire({
                position: 'center',
                icon: 'error',
                title: 'Oops...',
                showConfirmButton: false,
                text: decryptData.statusMessage,
                timer: 3000
            }); 
            setFormValues(formValues);
            setLoader(false);
        }  
    }

    const resendVerificationEmailResponse = ( res ) => {

        if ( res.data.statusCode === 200 ) {
            Swal.fire({
                position: 'center',
                icon: 'success',
                title: 'Email send successfully',
                showConfirmButton: false,
                timer: 3000
            });
        } else {
            Swal.fire({
                position: 'center',
                icon: 'error',
                title: 'Oops...',
                showConfirmButton: false,
                text: res.data.statusMessage,
                timer: 3000
            }); 
        }

    }

    const validate =( values ) => {
        const errors = {};
        const regex = /^[^\s@]+@[^\s@]+\.[^\s@]{2,}$/i;
        errors.status = true;

        if ( !values.email ) {
            errors.email = 'Email is required';
            errors.status = false;        
        } else if ( !regex.test(values.email) ) {
            errors.email ="This is not a valid email format!";
            errors.status = false;
        }

        if ( !values.password ) {
            errors.password = 'Password is required';
            errors.status = false;
        }

        // if ( recaptcha.current.getValue() === '' ) {
        //     errors.captcha = 'Captcha is required';
        //     errors.status = false;
        // }

        const validations = {
            email: HtmlTagValidation(values.email),
            password: HtmlTagValidation(values.password)
        };

        Object.entries(validations).forEach(([key, value]) => {

            if (!value) {
                errors[key] = 'Invalid input';
                errors.status = false;
            }

        });

        return errors;
    }

    const confrimPassView = () => {
        setPassEye(prevState => !prevState);
    }

    return(
        // stylist login form HTML start here
        <section className='prof-bg'>
            <Container className='login-bg'>
                <Row className="justify-content-md-center csm-login">
                <Col md="6"></Col>
                      <Col md="4">
                        <div className='text-center'>
                            <img src='images/logo.png'/>
                        </div>

                        <div className='stl-nm'>
                        <div className='left'>                  
                        <h1 className="text-center">Professional <br></br> <span>Login</span></h1>
                        </div>
                        <div className='right'>                    
                        <img src='images/prof-mb.png' className='img-res'/>
                        </div>
                        </div>
                      
                        
                        <Form onSubmit={handleSubmit}>
                            <Form.Group className="mb-4" controlId="formBasicEmail">
                                <Form.Label>Email <span>*</span></Form.Label>
                                <Form.Control autoComplete="off" onKeyDown={BlockSQLInjection} type="email" name='email' defaultValue={formValues.email} onChange={handleChange}/>
                                <p className='error'>{formErrors.email}</p>
                            </Form.Group>
                            <Form.Group className="mb-4 passwordRow" controlId="formBasicPassword">
                                <Form.Label>Password <span>*</span></Form.Label>
                                <FontAwesomeIcon icon={passEye === false ? faEye : faEyeSlash} className='passwordEye' onClick={confrimPassView}/>
                                <Form.Control autoComplete="off" onKeyDown={BlockSQLInjection} type={passEye === false ? 'password' : 'text'} name='password' defaultValue={formValues.password} onChange={handleChange}  />
                                <p className="error">{formErrors.password}{formErrors.invalid}</p>
                            </Form.Group>
                            {/* <Form.Group as={Col} controlId="formCaptchaLoad">
                                <Form.Label>Captcha <span>*</span></Form.Label>
                                <ReCAPTCHA ref={recaptcha} sitekey={process.env.REACT_APP_SITE_KEY} />
                                <p className="error">{formErrors.captcha}</p>
                            </Form.Group> */}
                            <Stack gap={3} className="mx-autos">
                                <div style={{textAlign : 'right'}}>
                                    <Link to="/stylistforgotpassword" className="text-end forgot" style={{"text-decoration": "none"}}>Forgot Password?</Link>
                                </div>
                                {
                                    loader === true ? 
                                        <div style={{textAlign : 'center'}}>
                                            <InfinitySpin height="80" width="200" radius="9" color="#D49D29" ariaLabel="loading" wrapperStyle wrapperClass />
                                        </div>
                                    :
                                        <Button variant="secondary" type='submit' onClick={handleSubmit}>Login <img src='images/arrow-right.svg'/></Button>
                                }
                                <div className="text-center backto">New professional? <Link to="/stylistregistration" className="forgot" style={{"text-decoration": "none"}}>Create an account</Link></div>
                            </Stack>
                        </Form>
                    </Col>
                    <Col md="2"></Col>
                </Row>
            </Container>
        </section>
    )
} 