import { React, useState, useEffect } from "react";
import { Col, Container, Image, Row, Stack, Button, OverlayTrigger, Popover } from "react-bootstrap";
import userImage from "../../../images/booking-profile.png";
import Topbar from "../Assets/Topbar";
import LeftSidebar from "../Assets/LeftSidebar";
import { useSelector } from "react-redux";
import moment from "moment";
import { faCircleExclamation } from "@fortawesome/free-solid-svg-icons";
import { useNavigate, useParams } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { postAdminBookingDetails } from "../../../Service";
import { InfinitySpin } from 'react-loader-spinner';

export default function AdminBookingDetails() {
    
    const navigate = useNavigate();
    const params = useParams();

    const [bookingData, setBookingData] = useState({});
    const [apiError, setApiError] = useState(false);
    const [pageLoader, setPageLoader]  = useState(true);
    const [bookingDate, setBookingDate] = useState('');
    const [bookingStartTIme, setBookingStartTIme] = useState('');
    const [bookingEndTIme, setBookingEndTIme] = useState('');

    useEffect(() => {
        let bookingDetailsParams = { 'bookingId' : params.bookingId  };
        postAdminBookingDetails(bookingDetailsParams).then(res => bookingDetailsHandleResponse(res));
    }, []);

    const bookingDetailsHandleResponse = ( res ) => {

        if ( res.data.statusCode === 200 ) {

            if ( res.data.customerBookingList.length > 0 ) {
                let bookingDetailsData = res.data.customerBookingList[0];
                let bookingDetailsSetup = { "startDate": bookingDetailsData.startDateTime, "endDate": bookingDetailsData.endDateTime, "text": bookingDetailsData.customerName === null ? 'Self Booking' : bookingDetailsData.customerName, "bookingId": bookingDetailsData.bookingId, "clientId": bookingDetailsData.clientId, "clientName": bookingDetailsData.clientName, "createdOnUtc": bookingDetailsData.createdOnUtc, "customerId": bookingDetailsData.customerId, "serviceName": bookingDetailsData.serviceName, "payment": bookingDetailsData.payment, "productId": bookingDetailsData.productId, "serviceId": bookingDetailsData.serviceId, "shopId": bookingDetailsData.shopId, "shopName": bookingDetailsData.shopName, "slotId": bookingDetailsData.slotId, "status": bookingDetailsData.status, "serviceCost" : bookingDetailsData.serviceCost, "platformFee" : bookingDetailsData.platformFees, "tips" : bookingDetailsData.tip, "bookingType" : bookingDetailsData.bookingType, "isCustomerRespondent" : bookingDetailsData.isCustomerRespondent, "paymentType" : bookingDetailsData.paymentType };

                setBookingDate(moment(new Date(bookingDetailsSetup.startDate)).format("MM-DD-YYYY"));
                setBookingStartTIme(moment(new Date(bookingDetailsSetup.startDate)).format("LT"));
                setBookingEndTIme(moment(new Date(bookingDetailsSetup.endDate)).format("LT"));
                setBookingData(bookingDetailsSetup);
            } else {
                setApiError(true);
            }

            setPageLoader(false);

        } else {
            setApiError(true);
            setPageLoader(false);
        }

    }

    const handlePrevPage = () => {
        navigate(-1);
    };

    // get decimal two degit cost
    const getFullCost = (dollar) => {
        let roundCost = (Math.floor(dollar * 100) / 100).toFixed(2);
        return roundCost;
    }

    return (
        <section className="dashboard">
            <Container>
                <Row className="sideBarRow">
                    <Col md={3} className="sideBarCol">
                        <LeftSidebar />
                    </Col>
                    <Col md={9}>
                        <div className="rightsidebar">
                            <Topbar />
                            <Row className="mb-3 main-title">
                                <Col>
                                    <hr />
                                    <Stack direction="horizontal" className="stackInside">
                                        <div>
                                            <h1>Booking Details</h1>
                                        </div>
                                        <div className="ms-auto">
                                            <Button variant="outline-secondary" onClick={handlePrevPage}>Back</Button>
                                        </div>
                                    </Stack>
                                </Col>
                            </Row>

                            {
                                pageLoader ?
                                    <Row className="justify-content-md-center text-center">
                                        <div className='booking-details'>
                                            <InfinitySpin height="80" width="200" radius="9" color="#D49D29" ariaLabel="loading" wrapperStyle wrapperClass />
                                        </div>
                                    </Row>
                                :
                                    apiError ?
                                        <Row className="justify-content-md-center text-center">
                                            <div className='booking-details'>
                                                <h4>Something went wrong. Please try after sometimes.</h4>
                                            </div>
                                        </Row>
                                    :
                                        <Row className="justify-content-md-center text-center">
                                            <div className="booking-details">
                                                <Col md={12}>
                                                    <div className="profile">
                                                        <Image src={userImage} />{" "}
                                                    </div>
                                                    <h2>{bookingData.text}</h2>
                                                    <Row className="text-start">
                                                        <Col>
                                                            <h4>Professional Name</h4>
                                                        </Col>
                                                        <Col>
                                                            <p>{bookingData.clientName}</p>
                                                        </Col>
                                                    </Row>
                                                    {
                                                        bookingData.text !== 'Self Booking' ?
                                                        <>
                                                            <Row className="text-start">
                                                                <Col>
                                                                    <h4>Business Name</h4>
                                                                </Col>
                                                                <Col>
                                                                    <p>{bookingData.shopName}</p>
                                                                </Col>
                                                            </Row>
                                                            <Row className="text-start">
                                                                <Col>
                                                                    <h4>Service Offered</h4>
                                                                </Col>
                                                                <Col>
                                                                    <p>{bookingData.serviceName}</p>
                                                                </Col>
                                                            </Row>
                                                            <Row className="text-start">
                                                                <Col>
                                                                    <h4>Date</h4>
                                                                </Col>
                                                                <Col>
                                                                    <p>{bookingDate}</p>
                                                                </Col>
                                                            </Row>
                                                            <Row className="text-start">
                                                                <Col>
                                                                    <h4>Time</h4>
                                                                </Col>
                                                                <Col>
                                                                    <p>{bookingStartTIme + " " + "-" + " " + bookingEndTIme}</p>
                                                                </Col>
                                                            </Row>
                                                            <Row className="text-start">
                                                                <Col>
                                                                    <h4>Service Charge fee</h4>
                                                                </Col>
                                                                <Col>
                                                                    <p>
                                                                        ${bookingData.payment.toFixed(2)}
                                                                        <sup className="payment-icon">
                                                                        <OverlayTrigger
                                                                            trigger="click"
                                                                            placement="left"
                                                                            rootClose={true}
                                                                            overlay={
                                                                            <Popover
                                                                                id={"popover-payment"}
                                                                                style={{ background: "#222", color: "#fff" }}
                                                                            >
                                                                                <div>
                                                                                <h5>
                                                                                    Service : $
                                                                                    {bookingData.serviceCost.toFixed(2)}{" "}
                                                                                </h5>
                                                                                <h5>
                                                                                    Tip : ${bookingData.tips.toFixed(2)}
                                                                                </h5>
                                                                                <h5>
                                                                                    Platform Fees : $
                                                                                    {bookingData.platformFee.toFixed(2)}
                                                                                </h5>
                                                                                <p>
                                                                                    <strong>Total : </strong>$
                                                                                    {bookingData.payment.toFixed(2)}
                                                                                </p>
                                                                                </div>
                                                                            </Popover>
                                                                            }
                                                                        >
                                                                            <FontAwesomeIcon icon={faCircleExclamation} />
                                                                        </OverlayTrigger>
                                                                        </sup>
                                                                    </p>
                                                                </Col>
                                                            </Row>
                                                            <Row className="text-start">
                                                                <Col>
                                                                    <h4>Payment Type</h4>
                                                                </Col>
                                                                <Col>
                                                                    <p>{bookingData.paymentType}</p>
                                                                </Col>
                                                            </Row>
                                                            <Row className="text-start">
                                                                <Col>
                                                                    <h4>Status</h4>
                                                                </Col>
                                                                <Col>
                                                                    <p className="" style={{ float: "right" }}>
                                                                        {bookingData.status === 'Booked' ? (bookingData.bookingType == 'CL' || bookingData.bookingType == 'CLEB') && bookingData.isCustomerRespondent === false ? 'Verification Pending' : bookingData.status : bookingData.status}
                                                                    </p>
                                                                </Col>
                                                            </Row>
                                                            <Row className="text-start">
                                                                <Col>
                                                                    <h4>Booked / Edited By</h4>
                                                                </Col>
                                                                <Col>
                                                                    <p>
                                                                        {bookingData.bookingType == 'CS' ? 'Customer' : (bookingData.bookingType == 'CL' || bookingData.bookingType == 'CLEB') ? 'Professional' : 'Self Booking'}
                                                                    </p>
                                                                </Col>
                                                            </Row>
                                                        </>
                                                        :
                                                        <>
                                                            <Row className="text-start">
                                                                <Col>
                                                                    <h4>Date</h4>
                                                                </Col>
                                                                <Col>
                                                                    <p>{bookingDate}</p>
                                                                </Col>
                                                            </Row>
                                                            <Row className="text-start">
                                                                <Col>
                                                                    <h4>Time</h4>
                                                                </Col>
                                                                <Col>
                                                                    <p>
                                                                        {bookingStartTIme + " " + "-" + " " + bookingEndTIme}
                                                                    </p>
                                                                </Col>
                                                            </Row>
                                                        </>
                                                    }
                                                </Col>
                                            </div>
                                        </Row>
                            }   

                        </div>
                    </Col>
                </Row>
            </Container>
        </section>
    );
}
