import React, { useRef, useState } from 'react';
import { Container, Row, Col, Form, Card, Button, OverlayTrigger, Image } from "react-bootstrap";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faGear, faLocationDot, faUserAlt, faFilter, faFilterCircleXmark, faHeart } from '@fortawesome/free-solid-svg-icons';
import { faHeart as farHeart } from '@fortawesome/free-regular-svg-icons'
import { Link } from 'react-router-dom';
import { customerClientList, postAddFavouriteClient, postRemoveFavouriteClient } from '../../../../Service';
import { useEffect } from 'react';
import { InfinitySpin } from 'react-loader-spinner';
import CustomerHeader from '../../Assets/CustomerHeader';
import Tooltip from 'react-bootstrap/Tooltip';
import { useSelector } from 'react-redux';
import { HtmlTagValidation, BlockSQLInjection } from '../../../Reusable';

export default function CustomerHome() {
  //initial state declaration
  const [clientList, setClientList] = useState([]);
  const [loader, setLoader] = useState(true);
  const searchInitialValues = { searchByShopName: '', searchByProfessional: '', searchByState: '', searchByCity: '', searchByZip: '' };
  const [searchFormValues, setSearchFormValues] = useState(searchInitialValues);
  const [searchErrors, setSearchErrors] = useState('');
  const [searchBTNStatus, setSearchBTNStatus] = useState(false);
  const [searchStatus, setSearchStatus] = useState(false);
  const [clearbtnDisable, setClearbtnDisable] = useState(true);
  const [listReload, setListReload] = useState(false);
  const [showFavourites, setShowFavourites] = useState(false);
  const [hideFilterStatus, setHideFilterStatus] = useState(false);

  // get customer data from store
  const userData = useSelector(store => store.user['customeruser']);

  // get client list by api
  useEffect(() => {
    let params = {};
    if (userData === null || userData === undefined) {
      params = { 'clientName': searchFormValues.searchByProfessional.trim(), 'shopName': searchFormValues.searchByShopName.trim(), 'zipPostalCode': searchFormValues.searchByZip, 'city': searchFormValues.searchByCity.trim(), 'state': searchFormValues.searchByState.trim(), 'sortColumn': "id", 'sortDirection': "desc", 'customerId': 0 };
    } else {
      params = { 'clientName': searchFormValues.searchByProfessional.trim(), 'shopName': searchFormValues.searchByShopName.trim(), 'zipPostalCode': searchFormValues.searchByZip, 'city': searchFormValues.searchByCity.trim(), 'state': searchFormValues.searchByState.trim(), 'sortColumn': "id", 'sortDirection': "desc", 'customerId': userData.id };

    }
    customerClientList(params).then((res) => clientListResponse(res));
  }, [listReload]);

  const clientListResponse = (response) => {
    if (response.data.statusCode === 200) {
      if (showFavourites === true) {
        let favouriteArray = [];
        let allClient = response.data.clientList;
        allClient.map((data) => {
          if (data.favourite === true) {
            favouriteArray.push(data);
          }
        });
        setClientList(favouriteArray);
      } else {
        setClientList(response.data.clientList);
      }
      setLoader(false);
    } else {
      setLoader(false);
    }
  }

  // search functionalities start here
  const searchOnClick = () => {
    setSearchStatus(true);
  }

  const hideFilter = () => {
    setSearchStatus(false);
    setHideFilterStatus(true);
  }

  const clearSearchOnClick = () => {
    setSearchStatus(false);
    setSearchFormValues(searchInitialValues);
    setSearchErrors('');
    setLoader(true);
    setShowFavourites(false);
    setHideFilterStatus(false);

    let params = {};
    if (userData === null || userData === undefined) {
      params = { 'clientName': '', 'shopName': '', 'zipPostalCode': '', 'city': '', 'state': '', 'sortColumn': "id", 'sortDirection': "desc", 'customerId': 0 };
    } else {
      params = { 'clientName': '', 'shopName': '', 'zipPostalCode': '', 'city': '', 'state': '', 'sortColumn': "id", 'sortDirection': "desc", 'customerId': userData.id }
    }

    customerClientList(params).then((res) => clearSearchResponse(res));
  }

  const clearSearchResponse = (response) => {
    if (response.data.statusCode === 200) {
      setClientList(response.data.clientList);
      setSearchBTNStatus(false);
      setClearbtnDisable(true);
      setTimeout(() => {
        setLoader(false);
      }, 1000);
    } else {
      setLoader(false);
      setSearchBTNStatus(false);
      setClearbtnDisable(true);
    }
  }

  const searchHandleChange = (e) => {
    const value = e.target.value;
    const field = e.target.name;

    if (field === 'searchByProfessional' || field === 'searchByState' || field === 'searchByCity') { // name field only alphabets 
      const newValue = e.target.value.replace(/[^a-zA-Z\s]/g, "");
      setSearchFormValues(prevState => ({
        ...prevState,
        [field]: newValue
      }));
    } else {
      setSearchFormValues(prevState => ({
        ...prevState,
        [field]: value
      }));
    }

  }

  const searchHandleSearch = (e) => {
    e.preventDefault();
    const validate = searchValidate(searchFormValues);
    setSearchErrors(validate.search);
    if (validate.status) {
      // setSearchBTNStatus(true);

      let params = {};

      if (userData === null || userData === undefined) {
        params = { 'clientName': searchFormValues.searchByProfessional.trim(), 'shopName': searchFormValues.searchByShopName.trim(), 'zipPostalCode': searchFormValues.searchByZip, 'city': searchFormValues.searchByCity.trim(), 'state': searchFormValues.searchByState.trim(), 'sortColumn': "id", 'sortDirection': "desc", 'customerId': 0 };
      } else {
        params = { 'clientName': searchFormValues.searchByProfessional.trim(), 'shopName': searchFormValues.searchByShopName.trim(), 'zipPostalCode': searchFormValues.searchByZip, 'city': searchFormValues.searchByCity.trim(), 'state': searchFormValues.searchByState.trim(), 'sortColumn': "id", 'sortDirection': "desc", 'customerId': userData.id };
      }

      customerClientList(params).then((res) => clientListSearchResponse(res));
      setLoader(true);
      setClearbtnDisable(false);
      setShowFavourites(false);
    }
  }

  const clientListSearchResponse = (response) => {
    if (response.data.statusCode === 200) {
      setClientList(response.data.clientList);
      setSearchBTNStatus(false);
      setLoader(false);
    } else {
      setLoader(false);
      setSearchBTNStatus(false);
    }
  }

  const searchValidate = (values) => {
    const errors = {};
    errors.status = true;

    if ((values.searchByShopName == '' || values.searchByShopName.trim() === '') && (values.searchByProfessional == '' || values.searchByProfessional.trim() === '') && (values.searchByState == '' || values.searchByState.trim() === '') && (values.searchByCity == '' || values.searchByCity.trim() === '') && (values.searchByZip == '' || values.searchByZip.trim() === '')) {
      errors.search = 'Provide any search term';
      errors.status = false;
    }

    let tagValidate = HtmlTagValidation(values.searchByShopName);

    if(!tagValidate){
      errors.search = 'Invalid Input';
      errors.status = false;
    }
    return errors;
  }

  // add and remove favourite icon click 
  const favouritehandleClick = (data, type) => {
    let apiParams = { 'customerId': userData.id, 'clientId': data.clientId };
    if (type === 'add') {
      setLoader(true);
      postAddFavouriteClient(apiParams).then(res => favouriteResponse(res));
    } else if (type === 'remove') {
      setLoader(true);
      postRemoveFavouriteClient(apiParams).then(res => favouriteResponse(res));
    }
  }

  // add and remove favourite response
  const favouriteResponse = (res) => {
    if (res.data.statusCode === 200) {
      setClientList([]);
      setListReload(prevState => !prevState);
    } else {
      setLoader(false);
    }
  }

  const showFavouritesHandleClick = (value) => {
    setShowFavourites(value);
    setLoader(true);
    setSearchBTNStatus(false);
    setClearbtnDisable(true);
    setSearchStatus(false);
    setSearchFormValues(searchInitialValues);
    setSearchErrors('');
    let params = {};
    if (userData === null || userData === undefined) {
      params = { 'clientName': '', 'shopName': '', 'zipPostalCode': '', 'city': '', 'state': '', 'sortColumn': "id", 'sortDirection': "desc", 'customerId': 0 };
    } else {
      params = { 'clientName': '', 'shopName': '', 'zipPostalCode': '', 'city': '', 'state': '', 'sortColumn': "id", 'sortDirection': "desc", 'customerId': userData.id };
    }
    customerClientList(params).then((res) => favouriteListResponse(res, value));
  }

  const favouriteListResponse = (response, status) => {
    if (response.data.statusCode === 200) {
      if (status === true) {
        let favouriteArray = [];
        let allClient = response.data.clientList;
        allClient.map((data) => {
          if (data.favourite === true) {
            favouriteArray.push(data);
          }
        });
        setClientList(favouriteArray);
      } else {
        setClientList(response.data.clientList);
      }
      setTimeout(() => {
        setLoader(false);
      }, 1000);
    } else {
      setLoader(false);
    }
  };

  const customerLogoutCallback = (value) => {
    if (value === true) {
      setShowFavourites(false);
      setLoader(true);
      setSearchBTNStatus(false);
      setClearbtnDisable(true);
      setSearchStatus(false);
      setSearchFormValues(searchInitialValues);
      setSearchErrors('');
      setListReload(prevState => !prevState);
    }
  }

  return (
    <div >
      <CustomerHeader getBack={customerLogoutCallback} />
      <section className='home-bg'>
        <main>
          <Container className="mb-4">
            <Row className='home-search-show'>
              {
                searchStatus == false ?
                  <Button variant="primary" onClick={searchOnClick}>{hideFilterStatus === true ? 'View Filter' : 'New Filter'} <FontAwesomeIcon icon={faFilter} /></Button>
                  :
                  ''
              }
            </Row>
            {
              searchStatus === true ?
                <Row className='home-search-mobile'>
                  <Col>
                    <div className='home-search'>
                      <Form onSubmit={searchHandleSearch}>
                        <Row className='searchRow custom-home-search'>
                          <Col xl={8}>
                            <Row>
                              <Col lg={6}>
                                <div className='hideFilter'>
                                  <Button onClick={hideFilter}>X</Button>
                                </div>
                                <Row>
                                  <Col md={6}>
                                    <Form.Group as={Col} controlId="formGridSearchName">
                                      <Form.Label>Shop Name</Form.Label>
                                      <Form.Control onKeyDown={BlockSQLInjection} type="text" name='searchByShopName' placeholder='Shop name...' onChange={searchHandleChange} value={searchFormValues.searchByShopName} />
                                    </Form.Group>
                                  </Col>
                                  <Col md={6}>
                                    <Form.Group as={Col} controlId="formGridSearchName">
                                      <Form.Label>Professional Name</Form.Label>
                                      <Form.Control type="text" name='searchByProfessional' placeholder='Professional name...' onChange={searchHandleChange} value={searchFormValues.searchByProfessional} />
                                    </Form.Group>
                                  </Col>
                                </Row>
                              </Col>
                              <Col lg={6}>
                                <Row>
                                  <Col md={4}>
                                    <Form.Group as={Col} controlId="formGridSearchName">
                                      <Form.Label>City</Form.Label>
                                      <Form.Control type="text" name='searchByCity' placeholder='City...' onChange={searchHandleChange} value={searchFormValues.searchByCity} />
                                    </Form.Group>
                                  </Col>
                                  <Col md={4}>
                                    <Form.Group as={Col} controlId="formGridSearchName">
                                      <Form.Label>State</Form.Label>
                                      <Form.Control type="text" name='searchByState' placeholder='State...' onChange={searchHandleChange} value={searchFormValues.searchByState} />
                                    </Form.Group>
                                  </Col>
                                  <Col md={4}>
                                    <Form.Group as={Col} controlId="formGridSearchName">
                                      <Form.Label>Zipcode</Form.Label>
                                      <Form.Control type="number" name='searchByZip' placeholder='ZipCode...' onChange={searchHandleChange} value={searchFormValues.searchByZip} />
                                    </Form.Group>
                                  </Col>
                                </Row>
                              </Col>
                            </Row>
                          </Col>
                          <Col xl={4} className="searcchAllBtn">
                            <Button variant="primary" type='submit' disabled={searchBTNStatus}>Search</Button>
                            <Button variant="danger" disabled={clearbtnDisable} onClick={clearSearchOnClick} className='home-clear-btn'>Clear Filter <FontAwesomeIcon icon={faFilterCircleXmark} /></Button>
                          </Col>
                          <span className='error'>{searchErrors}</span>
                        </Row>
                      </Form>
                    </div>
                  </Col>
                </Row>
                :
                ''
            }
            <Row className='home-search-desktop'>
              <Col>
                <div className='home-search'>
                  <Form onSubmit={searchHandleSearch}>
                    <Row className='searchRow custom-home-search'>
                      <Col xl={8}>
                        <Row>
                          <Col lg={6}>
                            <Row>
                              <Col md={6}>
                                <Form.Group as={Col} controlId="formGridSearchName">
                                  <Form.Label>Shop Name</Form.Label>
                                  <Form.Control onKeyDown={BlockSQLInjection} type="text" name='searchByShopName' placeholder='Shop name...' onChange={searchHandleChange} value={searchFormValues.searchByShopName} />
                                </Form.Group>
                              </Col>
                              <Col md={6}>
                                <Form.Group as={Col} controlId="formGridSearchName">
                                  <Form.Label>Professional Name</Form.Label>
                                  <Form.Control type="text" name='searchByProfessional' placeholder='Professional name...' onChange={searchHandleChange} value={searchFormValues.searchByProfessional} />
                                </Form.Group>
                              </Col>
                            </Row>
                          </Col>
                          <Col lg={6}>
                            <Row>
                              <Col md={4}>
                                <Form.Group as={Col} controlId="formGridSearchName">
                                  <Form.Label>City</Form.Label>
                                  <Form.Control type="text" name='searchByCity' placeholder='City...' onChange={searchHandleChange} value={searchFormValues.searchByCity} />
                                </Form.Group>
                              </Col>
                              <Col md={4}>
                                <Form.Group as={Col} controlId="formGridSearchName">
                                  <Form.Label>State</Form.Label>
                                  <Form.Control type="text" name='searchByState' placeholder='State...' onChange={searchHandleChange} value={searchFormValues.searchByState} />
                                </Form.Group>
                              </Col>
                              <Col md={4}>
                                <Form.Group as={Col} controlId="formGridSearchName">
                                  <Form.Label>Zipcode</Form.Label>
                                  <Form.Control type="number" name='searchByZip' placeholder='ZipCode...' onChange={searchHandleChange} value={searchFormValues.searchByZip} />
                                </Form.Group>
                              </Col>
                            </Row>
                          </Col>
                        </Row>
                      </Col>
                      <Col xl={4} className="searcchAllBtn">
                        <Button variant="primary" type='submit' disabled={searchBTNStatus}>Search</Button>
                        <Button variant="danger" disabled={clearbtnDisable} onClick={clearSearchOnClick} className='home-clear-btn'>Clear Filter <FontAwesomeIcon icon={faFilterCircleXmark} /></Button>
                      </Col>
                      <span className='error'>{searchErrors}</span>
                    </Row>
                  </Form>
                </div>
              </Col>
            </Row>
          </Container>
          <Container fluid>
            <Row>
              <Col md="4">
                <div className='clientList'>
                  {
                    (userData === null || userData === undefined ) && loader === false ?
                      ''
                      :
                      <div className='showFavouriteText'>
                        {
                          showFavourites === false ?
                            <p className='show' onClick={() => showFavouritesHandleClick(true)}>Show Favourite Professional's</p>
                            :
                            <p className='hide' onClick={() => showFavouritesHandleClick(false)}>Show All Professional's</p>
                        }
                      </div>
                  }

                  {
                    loader === true ?
                      <div style={{ textAlign: 'center' }}>
                        <InfinitySpin height="80" width="200" radius="9" color="#D49D29" ariaLabel="loading" wrapperStyle wrapperClass />
                      </div>
                      :
                      clientList.length > 0 ?
                        clientList.map((item, index) => {
                          let nameSplit = item.clientName.replace(/ /g, '_');
                          return (
                            <Card className="mb-2 customerClient" key={index}>
                              {
                                userData === null || userData === undefined ?
                                  ""
                                  :
                                  <div className='customerFavouriteIcon'>
                                    {
                                      item.favourite === true ?
                                        <OverlayTrigger
                                          placement="bottom"
                                          overlay={<Tooltip id="button-tooltip-2">Remove from favourites</Tooltip>}
                                        >
                                          <FontAwesomeIcon icon={faHeart} onClick={() => favouritehandleClick(item, 'remove')} />
                                        </OverlayTrigger>
                                        :
                                        <OverlayTrigger
                                          placement="bottom"
                                          overlay={<Tooltip id="button-tooltip-2">Add to favourites</Tooltip>}
                                        >
                                          <FontAwesomeIcon icon={farHeart} onClick={() => favouritehandleClick(item, 'add')} />
                                        </OverlayTrigger>
                                    }
                                  </div>
                              }
                              <Link to={'/customer/stylist/' + nameSplit + '/' + item.clientId}>
                                <Card.Body>
                                  <Card.Title>{item.shopName}</Card.Title>
                                  <Card.Text>
                                    <FontAwesomeIcon icon={faLocationDot} />  {item.location}
                                  </Card.Text>
                                  <hr />
                                  <Card.Text>
                                    <Row>
                                      <Col md={6}>
                                        <div className='location-stylist'>
                                          <div className='icon'>
                                            {
                                              item.profilePhoto ?
                                                <Image src={item.profilePhoto} style={{width : '30px', height : '30px', borderRadius : '50%', objectFit : 'cover'}}/>
                                              :
                                                <FontAwesomeIcon icon={faUserAlt} />
                                            }
                                            {/* <FontAwesomeIcon icon={faUserAlt} /> */}
                                          </div>
                                          <div className='content'>
                                            <strong>Professional</strong>
                                            <p>{item.clientName}</p>
                                          </div>
                                        </div>
                                      </Col>
                                      <Col md={6}>
                                        {
                                          item.serviceList.length > 0 ?
                                            <div className='location-stylist'>
                                              <div className='icon'>
                                                <FontAwesomeIcon icon={faGear} />
                                              </div>
                                              <div className='content'>
                                                <strong>Services</strong>
                                                <p>
                                                  {
                                                    item.serviceList.map((list, index) => (
                                                      <span key={index}>
                                                        {(index ? ', ' : '') + list.services}
                                                      </span>
                                                    ))
                                                  }
                                                </p>
                                              </div>
                                            </div>
                                            :
                                            ''
                                        }
                                      </Col>
                                    </Row>
                                  </Card.Text>
                                </Card.Body>
                              </Link>
                            </Card>
                          )
                        })
                        :
                        <p className='customer-home-notfound'>Professionals not found</p>
                  }
                </div>
              </Col>
              <Col md="8" className='locatioMapCol' >
                <div className='aboutContent'>
                  <h4>About Us</h4>
                  <div className="separator">
                    <div className='separate-dot'></div>
                  </div>
                  <p>
                    We, as founders of UNEXT accumulated 30 years in the service industry and during that time we directly experienced the challenges of
                    operating as individual professionals. While honing our talents, we found that most booking platforms granted bigger businesses
                    an unfair advantage while overlooking the needs of individual professionals. We understand the importance of building personal
                    relationships with our clients through a conveyance of services we provide. Big Business tends to only understand money, but we
                    put relationships over money. Other apps are all about gaining a profit through monthly fees, maintenance fees, dynamic pricing, etc).
                    Businesses who are profit centric do not tend to have relationships with their consumers, the clients, but we, the service
                    professionals do. If you find that you want more relationship focused employment, this app is for you.</p>

                  <p>”We, as founders of UNEXT amassed a wealth of combined knowledge and skills that we could apply toward enabling other entrepreneurs to
                    in utilizing and sharing their God-given talents. WE also wanted to acknowledge the trend and answer the call to help people find ways to
                    access and support local businesses. We recognized that by developing a booking platform that provided individual entrepreneurs with a chance
                    to compete with bigger businesses we could actualize their mutual vison to create change.</p>
                  <p>
                    This led us to embark on devising an innovative booking platform that operates for the betterment of individual entrepreneurs. We decided to name
                    their app UNEXT because in the service industry when clients inquire about availability the response is almost always “YOU NEXT”. We wanted to
                    that sense of presence and attentiveness to the needs of those who came to us for support. We describe the app as a “fair alternative” for
                    individuals who want more equitable treatment from their booking platform. We created UNEXT with the intent of giving back the power where
                    it rightfully belongs… in the hands of the hardworking, talented individuals in our communities!.”</p>
                  <br />
                  <h4>Value proposition</h4>
                  <div className="separator">
                    <div className='separate-dot'></div>
                  </div>
                  <p>UNEXT’s platform creates a more equitable booking system that grants individual professionals the chance to compete against the big
                    business competitors. UNEXT receives ZERO fees for transactions. This means UNEXT has ZERO monthly fees and ZERO maintenance fees.
                    These differentiators will create a more even playing field.</p>
                </div>
              </Col>
            </Row>
          </Container>
        </main>
      </section>
    </div>
  )
}   