import { React, useState, useEffect } from "react";
import { Col, Container, Row, Image, Table, Tab, Stack, TabContent, TabPane, Modal, Nav, Button, Form} from "react-bootstrap";
import { doCustomerUpdateProfile, doCustomerChangePassword } from '../../../../Service';
import Swal from "sweetalert2";
import { useSelector, useDispatch } from 'react-redux';
import { setCustomerUserData } from '../../../../reducers/User/action';
import { InfinitySpin } from 'react-loader-spinner';
import CustomerHeader from "../../Assets/CustomerHeader";
import { postCreateCustomerCreditCard, postCustomerAccountDetails, postCustomerCardDelete, patchUpdateCustomerCardStatus } from '../../../../Service';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEye, faEyeSlash } from "@fortawesome/free-solid-svg-icons";
import deleteIcon from "../../../../images/delete.png";
import { HtmlTagValidation, BlockSQLInjection } from "../../../Reusable";
import { JsonEncode, JsonDecode } from "../../../Reusable/JsonModify";

export default function CustomerSettings() {

    // initial state declaration
    const dispatch = useDispatch();
    const userinitialValues = { firstName: "", lastName: "", phone: "", email: "" };
    const changeinitialValues = { currentPassword: "", newPassword: "", confrimPassword: ""};
    const [userformValues, setUserformValues] = useState(userinitialValues);
    const [userFormErrors, setUserFormErrors] = useState({});
    const [changeformValues, setChangeformValues] = useState(changeinitialValues);
    const [changeFormErrors, setChangeFormErrors] = useState({});
    const [passwordLoader, setPasswordLoader] = useState(false);
    const [userLoader, setUserLoader] = useState(false);
    const [cardList, setCardList] = useState([]);
    const [tableLoader, setTableLoader] = useState(true);
    const [show, setShow] = useState(false);
    const initialCard = {name : '', number : '', expiry : '', cvc : '', setprimarycard : false};
    const [card, setCard] = useState(initialCard);
    const [cardNumber, setCardNumber] = useState('');
    const [cardExpiry, setCardExpiry] = useState('');
    const [formErrors, setFormErrors] = useState({});
    const [loader, setLoader] = useState(false);
    const [cardUpdate, setCardUpdate] = useState(false);
    const [passEye, setPassEye] = useState(false);
    const [confrimPassEye, setConfrimPassEye] = useState(false);
    const [primaryPopup, setPrimaryPopup] = useState(false);
    const [changePrimaryCard, setChangePrimaryCard] = useState(false);
    const [changePrimaryCardData, setChangePrimaryCardData] = useState({});
    const [primaryCardUpdateLoader, setPrimaryCardUpdateLoader] = useState(false);

    // get customer data from store
    const userData =  useSelector(store => store.user['customeruser']);
  
    // set user data into form fields
    useEffect(() => {
        if(userData){
            setUserformValues({ firstName : userData.firstName, lastName : userData.lastName, phone : userData.phone, email : userData.email });
        }
    },[]);

    // update user details form functionality start here
    const handleChange = (e) => {
        const value=e.target.value;
        const field=e.target.name;
        setUserformValues(prevState => ({
            ...prevState,
            [field]:  value  
        }));

        if(field === 'firstName' || field === 'lastName'){ // name field only alphabets 
            const newValue=e.target.value.replace(/[^a-zA-Z\s]/g,"");
            setUserformValues(prevState => ({
                ...prevState,
                [field]:  newValue  
            }));
        }

        const formatPhoneNumber = (numberValue) => {
            if (!numberValue) return numberValue;
            const phoneNumber = numberValue.replace(/[^\d]/g, "");
            const phoneNumberLength = phoneNumber.length;
            //if (phoneNumberLength < 4) return phoneNumber;
            if (phoneNumberLength < 4) {
                return `(${phoneNumber.slice(0, 3)}`;
            }
            if (phoneNumberLength < 7) {
                return `(${phoneNumber.slice(0, 3)}) ${phoneNumber.slice(3)}`;
            }
            return `(${phoneNumber.slice(0, 3)}) ${phoneNumber.slice(
                3,
                6
            )}-${phoneNumber.slice(6, 10)}`;
        }

        if(field === 'phone'){
            const numberValue = e.target.value;
            const formattedPhoneNumber = formatPhoneNumber(numberValue);
            setUserformValues(prevState => ({
                ...prevState,
                [field]:  formattedPhoneNumber  })
            );
        }

    }

    const handleSubmit = async (e) => {
        e.preventDefault();
        const errorCount = validate(userformValues);
        setUserFormErrors(errorCount);
        if(errorCount.status){
            userformValues['id']=userData.id;

            let jsonData = await JsonEncode(userformValues).then(result => {
                return result
            }).catch(error => {
                Swal.fire({
                    position: 'center',
                    icon: 'error',
                    title: 'Oops...',
                    showConfirmButton: false,
                    text: error,
                    timer: 3000
                }); 
            });
            
            if ( jsonData ) {
                let loginPayload = {data : jsonData};
                doCustomerUpdateProfile(loginPayload).then(res => handleResponse(res));
                setUserLoader(true);
            } else {
                Swal.fire({
                    position: 'center',
                    icon: 'error',
                    title: 'Oops...',
                    showConfirmButton: false,
                    text: 'Something went wrong, Please try after sometimes',
                    timer: 3000
                }); 
            }
            
        }
    }

    const handleResponse = (res) => {
        if(res.data.statusCode === 200){
            Swal.fire({
                position: 'center',
                icon: 'success',
                title: res.data.statusMessage,
                showConfirmButton: false,
                timer: 3000
            });
            const updateduserData = {id : userData.id, email : userformValues.email, firstName : userformValues.firstName, lastName : userformValues.lastName, gender : userData.gender, phone : userformValues.phone};
            dispatch(setCustomerUserData(updateduserData));
            setUserLoader(false)
        }else{
            Swal.fire({
                position: 'center',
                icon: 'error',
                title: 'Oops...',
                showConfirmButton: false,
                text: res.data.statusMessage,
                timer: 3000
            }); 
            setUserLoader(false);
        }
    }

    const validate = (values) => {
        const errors = {};
        const regex = /^[^\s@]+@[^\s@]+\.[^\s@]{2,}$/i;
        errors.status = true;
        //var numbers = /^[0-9]+$/;

        if(!values.firstName  || values.firstName.trim() === ''){
            errors.firstName = 'Firstname is required';
            errors.status = false;
        }

        if(!values.lastName  || values.lastName.trim() === ''){
            errors.lastName = 'Lastname is required';
            errors.status = false;
        }

        if(!values.phone){
            errors.phone = 'Phone is required';
            errors.status = false;
        }else if(values.phone){
            const numval = values.phone.split('');
            if(numval[1] === '0'){
                errors.phone = 'Enter valid phone number';
                errors.status = false;
            }else if(values.phone && values.phone.length !== 14){
                errors.phone = 'Phone number must have 10 digit';
                errors.status = false;
            } //else if(values.phone && !values.phone.match(numbers)){
                //errors.phone='Enter valid phone number';
                //errors.status=false;
            //}
        } 

        if(!values.email){
            errors.email = 'Email is required';
            errors.status = false;
        } else if (!regex.test(values.email)) {
            errors.email = "This is not a valid email format!";
            errors.status = false;
        }

        return errors;
  }

    // Change password form functionality start here
    const passwordChange = (e) => {
        const value=e.target.value;
        const field=e.target.name;
        setChangeformValues(prevState => ({
            ...prevState,
            [field]:  value  
        }));
    }
      
    const changeSubmit = async (e) => {
        e.preventDefault();
        const errorCount = passwordvalidate(changeformValues);
        setChangeFormErrors(errorCount);
        if(errorCount.status){
            changeformValues['email']=userData.email;

            let jsonData = await JsonEncode(changeformValues).then(result => {
                return result
            }).catch(error => {
                Swal.fire({
                    position: 'center',
                    icon: 'error',
                    title: 'Oops...',
                    showConfirmButton: false,
                    text: error,
                    timer: 3000
                }); 
            });
            
            if ( jsonData ) {
                let loginPayload = {data : jsonData};
                doCustomerChangePassword(loginPayload).then(res => handleChangeResponse(res));
                setPasswordLoader(true);
            } else {
                Swal.fire({
                    position: 'center',
                    icon: 'error',
                    title: 'Oops...',
                    showConfirmButton: false,
                    text: 'Something went wrong, Please try after sometimes',
                    timer: 3000
                }); 
            }
            
        }
    }

    const handleChangeResponse = (res) => {
        if(res.data.statusCode === 200){
            Swal.fire({
                position: 'center',
                icon: 'success',
                title: res.data.statusMessage,
                showConfirmButton: false,
                timer: 3000
            });
            setChangeformValues(changeinitialValues);
            setPasswordLoader(false);
        }else if(res.data.statusCode === 401){
            Swal.fire({
                position: 'center',
                icon: 'error',
                title: 'Oops...',
                showConfirmButton: false,
                text: 'Invalid current password',
                timer: 3000
            }); 
            setPasswordLoader(false);
        }else{
            Swal.fire({
                position: 'center',
                icon: 'error',
                title: 'Oops...',
                showConfirmButton: false,
                text: res.data.statusMessage,
                timer: 3000
            }); 
        }
    }

    const passwordvalidate = (values) => {
        const errors = {};
        errors.status = true;
        const passRegex = /(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[!@#$%^&*()+=-\?;,./{}|\":<>\[\]\\\' ~_-]).{8,}/;

        if(!values.currentPassword){
            errors.currentPassword = 'Current Password is required';
            errors.status = false;
        }

        if(!values.newPassword){
            errors.newPassword = 'New Password is required';
            errors.status = false;
        }else if(values.newPassword && !passRegex.test(values.newPassword)){
            errors.newPassword = 'Password should contain atleast a Uppercase, a Lowercase, a Number and a Special character';
            errors.status = false;
        }

        if(!values.confrimPassword){
            errors.confirmPassword = 'Confirm Password is required';
            errors.status = false;
        }

        if(values.newPassword && values.confrimPassword && values.newPassword === values.currentPassword ){
            errors.checkPassword = 'New password and Current Password should not be same';
            errors.status = false;
        }

        if(values.newPassword && values.confrimPassword && values.newPassword !== values.confrimPassword){
            errors.confirmPassword = 'New password and Confirm Password does not match';
            errors.status = false;
        }

        const validations = {
            currentPassword: HtmlTagValidation(values.currentPassword),
            newPassword: HtmlTagValidation(values.newPassword),
            confirmPassword : HtmlTagValidation(values.confrimPassword)
        };

        Object.entries(validations).forEach(([key, value]) => {
            if (!value) {
                errors[key] = 'Invalid input';
                errors.status = false;
            }
        });
        
        return errors;
    } 

    // card functionalities start here

    // Booking slot add popup open function
    const handleShow = () => {
        setShow(true);
    };

    // Booking slot edit popup close function
    const handleClose = () => {
        setShow(false);
        setCard(initialCard);
        setCardNumber('');
        setFormErrors({});
        setCardExpiry('');
    };

    // get card list 
    useEffect(() => {
        (async () => {
            setChangePrimaryCard(false);
            setChangePrimaryCardData({});
            let whereUser = {'customer_Id' : userData.id}

            let jsonData = await JsonEncode(whereUser).then(result => {
                return result
            }).catch(error => {
                Swal.fire({
                    position: 'center',
                    icon: 'error',
                    title: 'Oops...',
                    showConfirmButton: false,
                    text: error,
                    timer: 3000
                }); 
            });
            
            if ( jsonData ) {
                let loginPayload = {data : jsonData};
                postCustomerAccountDetails(loginPayload).then(res => initialResponse(res));
            } else {
                Swal.fire({
                    position: 'center',
                    icon: 'error',
                    title: 'Oops...',
                    showConfirmButton: false,
                    text: 'Something went wrong, Please try after sometimes',
                    timer: 3000
                }); 
            }
            
        })();
    }, [cardUpdate]);

    const initialResponse = async (res) => {
        let decryptData = await JsonDecode(res.data.data).then(result => {
            return result
        }).catch(error => {
            Swal.fire({
                position: 'center',
                icon: 'error',
                title: 'Oops...',
                showConfirmButton: false,
                text: error,
                timer: 3000
            }); 
        });

        if(decryptData.statusCode === 200){
            setCardList(decryptData.data)
            setTableLoader(false);
        }else{
            setTableLoader(false);
        }
    }

    const hanldeChange = (e) => {
        let value=e.target.value;
        let field=e.target.name;
        if(field == 'setprimarycard'){
            value = e.target.checked;
        }
        
        setCard(prevState => ({
            ...prevState,
            [field]: value }));
        
        if(field == 'number'){
                var val = e.target.value;
                const valArray = val.split(' ').join('').split('');
                
                var valSpace = val.split("");

                // to work with backspace
                if(valSpace[valSpace.length-1] == ' '){
                    var valSpaceN = valSpace.slice(0, -1)
                    val = valSpaceN.join("")
                    setCardNumber(val);
                    return;
                }

                if(isNaN(valArray.join('')))
                    return;
                if(valArray.length === 17)
                    return
                if(valArray.length % 4 === 0 && valArray.length <= 15) {
                    setCardNumber(e.target.value + " ");
                }else{
                    setCardNumber(e.target.value)
                }
        }

      if(field == 'expiry'){
            // var val = e.target.value;
            // const valArray = val.split('/').join('').split('');
            // var valSpace = val.split("")
            
            // // to work with backspace
            // if(valSpace[valSpace.length-1] == '/'){
            //     var valSpaceN = valSpace.slice(0, -1)
            //     val = valSpaceN.join("")
            //     setCardExpiry(val);
            //     return;
            // }
            // if(isNaN(valArray.join('')))
            //     return;
            // if(valArray.length === 5)
            //     return;
            // if(valArray.length === 2) {
            //     setCardExpiry(e.target.value + "/");
            // }else{

            //     setCardExpiry(e.target.value)
            // }

            let input = e.target.value.replace(/\D/g, "");

            if ( input.length > 4 ) {
                input = input.slice(0, 4);
            }

            if ( input.length > 2 ) {
                input = input.slice(0, 2) + "/" + input.slice(2);
            }

            setCardExpiry(input);

        }

    }

    const cardHandleSubmit = async (e) => {
        e.preventDefault();
        const errorCount = cardValidate(card);
        setFormErrors(errorCount);
        if(errorCount.status){
            let cardDetails = {};
            if(cardList.length == 0){
                cardDetails = {cardNumber : cardNumber.replace(/ /g, ''), expirationDate : cardExpiry.replace('/', ''), cardCode : card.cvc, customerId : userData.id, cardStatus : 1};
            }else if(cardList.length > 0){
                cardDetails = {cardNumber : cardNumber.replace(/ /g, ''), expirationDate : cardExpiry.replace('/', ''), cardCode : card.cvc, customerId : userData.id, cardStatus : card.setprimarycard == false ? 0 : 1};
            }

            let jsonData = await JsonEncode(cardDetails).then(result => {
                return result
            }).catch(error => {
                Swal.fire({
                    position: 'center',
                    icon: 'error',
                    title: 'Oops...',
                    showConfirmButton: false,
                    text: error,
                    timer: 3000
                }); 
            });
            
            if ( jsonData ) {
                let loginPayload = {data : jsonData};
                postCreateCustomerCreditCard(loginPayload).then(res => handleCardResponse(res));
                setLoader(true);
            } else {
                Swal.fire({
                    position: 'center',
                    icon: 'error',
                    title: 'Oops...',
                    showConfirmButton: false,
                    text: 'Something went wrong, Please try after sometimes',
                    timer: 3000
                }); 
            }
            
        }
    }

    const handleCardResponse = (res) => {
        if(res.data.statusCode === 200){
            Swal.fire({
                position: 'center',
                icon: 'success',
                title: res.data.statusMessage,
                showConfirmButton: false,
                timer: 3000
            });
            setLoader(false);
            handleClose();
            setCardUpdate(prevCardUpdate => !prevCardUpdate);
        }else{
            Swal.fire({
                position: 'center',
                icon: 'error',
                title: 'Oops...',
                showConfirmButton: false,
                text: res.data.statusMessage,
                timer: 3000
            }); 
            setLoader(false);
        }
    }

    const cardValidate = (values) => {
        const errors = {};
        errors.status = true;

        if(!values.number  || values.number.trim() === ''){
            errors.number = 'Card number is required';
            errors.status = false;
        }

        if(!values.expiry  || values.expiry.trim() === ''){
            errors.expiry = 'Card expiry is required';
            errors.status = false;
        }else if(values.expiry){
            let cardSplit = values.expiry.split('/');
            if(cardSplit[0] > 12 || cardSplit[0] == '00'){
                errors.expiry = 'Enter valid month';
                errors.status = false;
            }
        }

        if(!values.cvc  || values.cvc.trim() === ''){
            errors.cvc = 'CVC is required';
            errors.status = false;
        }else if(values.cvc && (values.cvc.length < 3 || values.cvc.length > 4)){
            errors.cvc = 'CVV must be either 3 or 4 digits.';
            errors.status = false;
        }

        return errors;
    }

    const passView = () => {
        setPassEye(prevState => !prevState);
    }

    const confrimPassView = () => {
        setConfrimPassEye(prevState => !prevState);
    }

    const primaryCardChange = (e, data) => {
        if(e.target.checked === true){
            setChangePrimaryCard(true);
            setChangePrimaryCardData(data);
            setPrimaryPopup(true);
        }
    }

    // Set primary card popup close
    const handlePrimaryCardClose = () => {
        setPrimaryPopup(false);
        setChangePrimaryCard(false);
        setChangePrimaryCardData({});
    }

    const deleteCard = (data) => {
        Swal.fire({
            title: 'Are you sure?',
            text: "Do you want to delete this Card?",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes, delete it!'
        }).then((result) => {
            if (result.isConfirmed) {
                let deleteCardParams = {'cardId' : data.id, 'customerId' : data.customerId};
                postCustomerCardDelete(deleteCardParams).then(res => deleteCardResponse(res));

                const deleteCardResponse = (res) => {
                    if(res.data.statusCode == 200) {
                        Swal.fire({
                            position: 'center',
                            icon: 'success',
                            title: res.data.statusMessage,
                            showConfirmButton: false,
                            timer: 3000
                        });
                        setCardUpdate(prevCardUpdate => !prevCardUpdate);
                    }else{
                        Swal.fire({
                        position: 'center',
                        icon: 'error',
                        title: 'Oops...',
                        showConfirmButton: false,
                        text: res.data.statusMessage,
                        timer: 3000
                        }); 
                    }
                }
            }
        })
    }

    const setPrimaryCardHandleSubmit = (e) => {
        e.preventDefault();
        setPrimaryCardUpdateLoader(true);
        let setPrimaryCardParams = {'customerId' : changePrimaryCardData.customerId, 'customerCardId' : changePrimaryCardData.id};
        patchUpdateCustomerCardStatus(setPrimaryCardParams).then(res => setPrimaryCardResponse(res));
    }

    const setPrimaryCardResponse = (res) => {
        if(res.data.statusCode == 200){
            Swal.fire({
                position: 'center',
                icon: 'success',
                title: res.data.statusMessage,
                showConfirmButton: false,
                timer: 3000
            });
            setCardUpdate(prevCardUpdate => !prevCardUpdate);
            setPrimaryCardUpdateLoader(false);
            handlePrimaryCardClose();
        }else{
            Swal.fire({
                position: 'center',
                icon: 'error',
                title: 'Oops...',
                showConfirmButton: false,
                text: res.data.statusMessage,
                timer: 3000
            }); 
            setPrimaryCardUpdateLoader(false);
        }
    }

    return(
        <div>
            <CustomerHeader />
            <section className='history-bg cus-setting'>
                <div className='history-section'>
                    <Container>
                        <Row className="justify-content-md-center">
                            <Col md='12'>
                                <h1>Settings</h1>
                                <Tab.Container id="left-tabs-example" defaultActiveKey="profile">
                                    <Nav variant="pills" className="mb-4 justify-content-md-center customer-setting-tab">
                                        <Nav.Item>
                                            <Nav.Link eventKey="profile">Edit Profile</Nav.Link>
                                        </Nav.Item>
                                        <Nav.Item>
                                            <Nav.Link eventKey="cards">Cards</Nav.Link>
                                        </Nav.Item>
                                        <Nav.Item>
                                            <Nav.Link eventKey="password">Change Password</Nav.Link>
                                        </Nav.Item>
                                    </Nav>
                                    <hr/>
                                    <Tab.Content>
                                        {/* update customer profile panel */}
                                        <Tab.Pane eventKey="profile">
                                            <Row>
                                                <Form onSubmit={handleSubmit}>
                                                    <Col>
                                                        <h4 className='mb-3'>Edit Profile</h4>
                                                        <Row className="mb-3">
                                                            <Form.Group as={Col} controlId="formGridEmail">
                                                                <Form.Label>First Name <span>*</span></Form.Label>
                                                                <Form.Control type="text" placeholder="William" name='firstName' value={userformValues.firstName} onChange={handleChange}/>
                                                                <p className="error">{userFormErrors.firstName}</p>
                                                            </Form.Group>
                                                            <Form.Group as={Col} controlId="formGridPassword">
                                                                <Form.Label>Last Name <span>*</span></Form.Label>
                                                                <Form.Control type="text" placeholder="Jones" name='lastName' value={userformValues.lastName} onChange={handleChange}/>
                                                                <p className="error">{userFormErrors.lastName}</p>
                                                            </Form.Group>
                                                        </Row>
                                                        <Row className="mb-3">
                                                            <Form.Group as={Col} className="mb-3" controlId="formGridCity">
                                                                <Form.Label>Phone <span>*</span></Form.Label>
                                                                <Form.Control type='tel' placeholder="" name='phone' value={userformValues.phone} onChange={handleChange} maxLength={14}/>
                                                                <p className="error">{userFormErrors.phone}</p>
                                                            </Form.Group>
                                                            <Form.Group as={Col} controlId="formGridEmail">
                                                                <Form.Label>Email <span>*</span></Form.Label>
                                                                <Form.Control type="email" placeholder="william.jones@gmail.com" name='email' defaultValue={userformValues.email} onChange={handleChange}/>
                                                                <p className="error">{userFormErrors.email}</p>
                                                            </Form.Group>
                                                        </Row>
                                                        <div className=" text-center">
                                                            {
                                                                userLoader === true ?
                                                                    <InfinitySpin height="80" width="200" radius="9" color="#D49D29" ariaLabel="loading" wrapperStyle wrapperClass />
                                                                :
                                                                    <Button variant="primary" type="submit">Update</Button>
                                                            }
                                                        </div>  
                                                    </Col>
                                                </Form>
                                            </Row>
                                        </Tab.Pane>

                                        {/* My card section */}
                                        <Tab.Pane eventKey="cards">
                                            <Row>
                                                <Form onSubmit={changeSubmit}>
                                                    <Col>
                                                        <h4 className='mb-3'>Cards</h4>
                                                        <Row className="mb-3">
                                                            <Col className="add-profession">  
                                                                <Button variant="success" onClick={handleShow}>+ Add New Card</Button>                       
                                                            </Col>
                                                        </Row>
                                                        <Row className="mb-3">
                                                            <div className="table-responsive" id='no-more-tables'>
                                                                <Table striped bordered hover variant="dark" className="customer-multi-card">
                                                                        <thead>
                                                                            <tr>
                                                                                <th>Set Primary</th>
                                                                                <th>Card Type</th>
                                                                                <th>Card Number</th>
                                                                                <th>Remove Card</th>
                                                                            </tr>
                                                                        </thead>
                                                                        <tbody>
                                                                            {
                                                                                tableLoader === true ?
                                                                                    <tr>
                                                                                        <td colSpan='4' style={{textAlign : 'center'}}><InfinitySpin height="80" width="200" radius="9" color="#D49D29" ariaLabel="loading" wrapperStyle wrapperClass /></td>
                                                                                    </tr>
                                                                                :
                                                                                    cardList.length > 0 ?
                                                                                        cardList.map((data, index) => (
                                                                                            <tr key={index}>
                                                                                                {
                                                                                                    data.defaultCard === true ? 
                                                                                                        <td data-title='Set Primary' className="primaryCardTableData"><input type="checkbox" checked={data.defaultCard === true ? true : ''} onChange={(e) => primaryCardChange(e, data)}  style={{cursor : 'pointer'}}/></td>
                                                                                                    :
                                                                                                        changePrimaryCard === true && changePrimaryCardData.id == data.id ?
                                                                                                            <td data-title='Set Primary' className="primaryCardTableData"><input type="checkbox" checked={changePrimaryCard} onChange={(e) => primaryCardChange(e, data)}  style={{cursor : 'pointer'}}/></td>
                                                                                                        :
                                                                                                            <td data-title='Set Primary' className="primaryCardTableData"><input type="checkbox" onChange={(e) => primaryCardChange(e, data)} checked={false}  style={{cursor : 'pointer'}}/></td>
                                                                                                }
                                                                                                <td data-title='Card Type'>{data.accountType}</td>
                                                                                                <td data-title='Card Number'>{data.accountNumber}</td>
                                                                                                {
                                                                                                    data.defaultCard === false && cardList.length > 1 ?
                                                                                                        <td data-title='Remove Card'>
                                                                                                            <Stack direction="horizontal" gap={3} className='professionAction'>
                                                                                                                <Image src={deleteIcon} style={{cursor: 'pointer'}} onClick={() => deleteCard(data)} className="tableActionIcon"/>
                                                                                                                <Button className='actionText dltBtn' style={{cursor: 'pointer'}} onClick={() => deleteCard(data)}>Delete</Button>
                                                                                                            </Stack>
                                                                                                        </td>
                                                                                                    :
                                                                                                        cardList.length == 1 ?
                                                                                                            <td data-title='Remove Card'>
                                                                                                                <Stack direction="horizontal" gap={3} className='professionAction'>
                                                                                                                    <Image src={deleteIcon} style={{cursor: 'pointer'}} onClick={() => deleteCard(data)} className="tableActionIcon"/>
                                                                                                                    <Button className='actionText dltBtn' style={{cursor: 'pointer'}} onClick={() => deleteCard(data)}>Delete</Button>
                                                                                                                </Stack>
                                                                                                            </td>
                                                                                                        :
                                                                                                            ''
                                                                                                }
                                                                                            </tr>
                                                                                        ))
                                                                                    : 
                                                                                        <tr>
                                                                                            <td colSpan='4' style={{textAlign : 'center'}}>No data found...</td>
                                                                                        </tr>
                                                                            }
                                                                        </tbody>
                                                                </Table>
                                                            </div>
                                                        </Row>
                                                    </Col>
                                                </Form>
                                            </Row>
                                        </Tab.Pane>

                                        {/* update user current password */}
                                        <Tab.Pane eventKey="password">
                                            <Row>
                                                <Form onSubmit={changeSubmit}>
                                                    <Col>
                                                        <h4 className='mb-3'>Change Password</h4>
                                                        <p className="grey-txt">Change your account password</p>
                                                        <br />
                                                        <Row className="mb-3">
                                                            <Form.Group as={Col} className="mb-3 passwordRow" controlId="formGridCurrent">
                                                                <FontAwesomeIcon icon={passEye === false ? faEye : faEyeSlash} className='passwordEye' onClick={passView}/>
                                                                <Form.Label>Current Password <span>*</span></Form.Label>
                                                                <Form.Control onKeyDown={BlockSQLInjection} type={passEye === false ? 'password' : 'text'} name='currentPassword' value={changeformValues.currentPassword} onChange={passwordChange} />
                                                                <p className="error">{changeFormErrors.currentPassword}</p>
                                                            </Form.Group>
                                                            <Form.Group as={Col} className="mb-3 passwordRow" controlId="formGridNewPwd">
                                                                <FontAwesomeIcon icon={confrimPassEye === false ? faEye : faEyeSlash} className='passwordEye' onClick={confrimPassView}/>
                                                                <Form.Label>New Password <span>*</span></Form.Label>
                                                                <Form.Control onKeyDown={BlockSQLInjection} type={confrimPassEye === false ? 'password' : 'text'} name='newPassword' value={changeformValues.newPassword} onChange={passwordChange} />
                                                                <p className="error">{changeFormErrors.newPassword}</p>
                                                            </Form.Group>
                                                            <Form.Group as={Col} className="mb-3" controlId="formGridCfmPwd">
                                                                <Form.Label>Confirm New Password <span>*</span></Form.Label>
                                                                <Form.Control onKeyDown={BlockSQLInjection} type="text" name='confrimPassword' value={changeformValues.confrimPassword} onChange={passwordChange} />
                                                                <p className="error">{changeFormErrors.confirmPassword}</p>
                                                            </Form.Group>
                                                              <p className="error">{changeFormErrors.checkPassword}</p>
                                                        </Row>
                                                      
                                                        <div className=" text-center">
                                                            {
                                                                passwordLoader === true ?
                                                                    <InfinitySpin height="80" width="200" radius="9" color="#D49D29" ariaLabel="loading" wrapperStyle wrapperClass />
                                                                :
                                                                    <Button variant='primary' type="submit">Save</Button>
                                                            }
                                                        </div>
                                                    </Col>
                                                </Form>
                                            </Row>
                                        </Tab.Pane>
                                    </Tab.Content>
                                </Tab.Container>
                            </Col>
                        </Row>
                    </Container>

                    {/* Add card HTML start here */}
                    <Modal show={show} onHide={handleClose} className="custom-modal Add-Card">
                        <Form onSubmit={cardHandleSubmit}>
                            <Modal.Header closeButton>
                                <Modal.Title>Add New Card</Modal.Title>
                            </Modal.Header>
                            <Modal.Body>
                                <Row>
                                    <Col md='12'>
                                        <Row>
                                            <Form.Group as={Col} controlId="formGridFirstName">
                                                <Form.Label>Card Number <span>*</span></Form.Label>
                                                <Form.Control type="tel" name="number" value={cardNumber} onChange={hanldeChange} placeholder="1234 1234 1234 1234"/>
                                                <p className="error">{formErrors.number}</p>
                                            </Form.Group> 
                                        </Row>
                                        <Row>
                                            <Form.Group as={Col}  className="mb-6" controlId="formGridFirstName">
                                                <Form.Label>Exp Date <span>*</span></Form.Label>
                                                <Form.Control type="tel" name="expiry" value={cardExpiry} onChange={hanldeChange} placeholder="MM/YY"/>
                                                <p className="error">{formErrors.expiry}</p>
                                            </Form.Group> 
                                            <Form.Group as={Col}  className="mb-6" controlId="formGridFirstName">
                                                <Form.Label>CVC <span>*</span></Form.Label>
                                                <Form.Control type="number" name="cvc" onChange={hanldeChange} placeholder="123"/>
                                                <p className="error">{formErrors.cvc}</p>
                                            </Form.Group> 
                                        </Row>
                                        {
                                            cardList.length == 0 ? 
                                                ''
                                            :
                                                <Row>
                                                    <Form.Group as={Col}  className="mb-6" controlId="formGridFirstName">
                                                            <label className="setPrimaryLabel">
                                                                <input type="checkbox" name='setprimarycard' onChange={hanldeChange} />
                                                                Set as primary card
                                                            </label>    
                                                    </Form.Group> 
                                                </Row>
                                        }
                                    </Col>
                                </Row>
                            </Modal.Body>
                            <Modal.Footer>
                                {
                                    loader === true ?
                                        <InfinitySpin height="80" width="200" radius="9" color="#D49D29" ariaLabel="loading" wrapperStyle wrapperClass />
                                    :
                                        <Button variant="primary" className='addbtn' type='submit'>Add</Button>
                                }
                                <Button variant="outline-secondary" onClick={handleClose}>Cancel</Button>
                            </Modal.Footer>
                        </Form>
                    </Modal>

                    {/* Set Primary Card Popup HTML start here */}
                    <Modal show={primaryPopup} onHide={handlePrimaryCardClose} className="custom-modal set-primary-Card-popup">
                        <Form onSubmit={setPrimaryCardHandleSubmit}>
                            <Modal.Header closeButton>
                                <Modal.Title>Set as primary card</Modal.Title>
                            </Modal.Header>
                            <Modal.Body>
                                <Row>
                                    <p>Do you want to set this card as Primary card</p>
                                    <ul>
                                        <li><span>Card Type:</span> {changePrimaryCardData.accountType}</li>
                                        <li><span>Card Number:</span> {changePrimaryCardData.accountNumber}</li>
                                    </ul>
                                </Row>
                            </Modal.Body>
                            <Modal.Footer>
                                {
                                    primaryCardUpdateLoader === true ?
                                        <InfinitySpin height="80" width="200" radius="9" color="#D49D29" ariaLabel="loading" wrapperStyle wrapperClass />
                                    :
                                        <Button variant="primary" className='addbtn' type='submit'>Set as primary</Button>
                                }
                                <Button variant="outline-secondary" onClick={handlePrimaryCardClose}>Cancel</Button>
                            </Modal.Footer>
                        </Form>
                    </Modal>
                </div>
            </section>
        </div>
    )
}       