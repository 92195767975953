import { React, useState, useEffect } from "react";
import { Col, Container, Row, Button, Stack, Table, Card } from "react-bootstrap";
import Topbar from "../../Assets/Topbar";
import LeftSidebar from "../../Assets/LeftSidebar";
import { useSelector } from "react-redux";
import { stylistServiceList } from "../../../../Service";
import { InfinitySpin } from "react-loader-spinner";
import { useNavigate, Link } from "react-router-dom";
import DesignServicesIcon from '@mui/icons-material/DesignServices';
import PaidIcon from '@mui/icons-material/Paid';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import EventAvailableIcon from '@mui/icons-material/EventAvailable';

export default function EditBookingNewServiceList() {
    const [serviceList, setServiceList] = useState([]);
    const [servicePageLoader, setServicePageLoader] = useState(false);

    const navigate = useNavigate();

    // get Professional data from store
    const userData = useSelector((store) => store.user["stylistuser"]);

    // get booking details data from store
    const getBookingDetails = useSelector(store => store.user.bookingDetails);

    // get service list
    useEffect(() => {
        setServicePageLoader(true);
        const getServiceParams = {
            clientId: userData ? userData.clientId : 0,
            searchServiceName: "",
            pageNo: 1,
            pageSize: 500,
            sortColumn: "id",
            sortDirection: "asc",
        };
        stylistServiceList(getServiceParams).then((res) =>
            initialStylistServiceResponse(res)
        );
    }, []);

    const initialStylistServiceResponse = (response) => {
        setServicePageLoader(false);
        if (response.data.statusCode === 200) {
            setServiceList(response.data.productList);
        } else {
        }
    };

    const handlePrevPage = () => {
      navigate(-1);
    };

    // get decimal two degit cost
    const getFullCost = (dollar) => {
        let roundCost = (Math.floor(dollar * 100) / 100).toFixed(2);
        return roundCost;
    }

    return (
        <section className="dashboard">
            <Container>
                <Row className="sideBarRow">
                    <Col md={3} className="sideBarCol">
                        <LeftSidebar />
                    </Col>
                    <Col md={9}>
                        <div className="rightsidebar">
                            <Topbar />
                            <Row className="mb-3 main-title">
                                <Col>
                                    <hr />
                                    <Stack direction="horizontal" className="stackInside">
                                        <div>
                                            <h1>Select New Service</h1>
                                        </div>
                                        <div className="ms-auto">
                                            <Button variant="outline-secondary" onClick={handlePrevPage} >Back</Button>
                                        </div>
                                    </Stack>
                                </Col>
                            </Row>
                            <Row>
                                <div className="table-responsive serviceOfferDsk">
                                    <Table striped bordered hover variant="dark">
                                        <thead>
                                        <tr>
                                            <th>Service</th>
                                            <th>Cost</th>
                                            <th>Duration</th>
                                            <th>Action</th>
                                        </tr>
                                        </thead>
                                        <tbody>
                                            {   
                                                servicePageLoader === true ?
                                                    <tr style={{textAlign:'center'}}>
                                                        <td colSpan={4}><InfinitySpin height="80" width="200" radius="9" color="#D49D29" ariaLabel="loading" wrapperStyle wrapperClass /></td>
                                                    </tr>
                                                :
                                                    serviceList.length > 0 ?
                                                    serviceList.map((list, index) => (
                                                            <tr key={index}>
                                                                <td>{list.subServiceName}<span style={{display : 'block', fontSize :'12px'}}></span></td>
                                                                <td>${list.price.toFixed(2)}</td>
                                                                <td>{list.duration} Minutes</td>
                                                                <td><Link to={"../stylist/editbooking/"+userData.clientId+"/"+list.productId+'/'+getBookingDetails.customerId+'/'+getBookingDetails.bookingId} className='btn btn-success'>Select service</Link></td>
                                                            </tr>
                                                        ))
                                    
                                                    :
                                                        <tr>
                                                            <td colSpan={4} style={{textAlign : 'center'}}>No data found...</td>
                                                        </tr>
                                            }
                                        </tbody>
                                    </Table>
                                </div>
                                <div className='serviceOfferMbl'>
                                    {
                                        servicePageLoader === true ?
                                            <div style={{textAlign:'center'}}>
                                                <InfinitySpin height="80" width="200" radius="9" color="#D49D29" ariaLabel="loading" wrapperStyle wrapperClass />
                                            </div>
                                        :
                                            serviceList.length > 0 ?
                                                serviceList.map((list, index) => (
                                                    <Card className='mb-2' key={index}>
                                                        <Card.Body>
                                                            <Card.Text>
                                                                <Row>
                                                                    <Col>
                                                                        <Stack direction="vertical" gap={1} className='serviceListOffered'>
                                                                            <div className="serviceOfferContent"><DesignServicesIcon /> {list.subServiceName}<br /><span style={{display : 'block', fontSize :'12px'}}></span></div>
                                                                            <div className=''><PaidIcon /> {list.price.toFixed(2)}</div>
                                                                            <div className=''><AccessTimeIcon /> {list.duration} Minutes</div>
                                                                            <div><EventAvailableIcon /> <Link to={"../stylist/editbooking/"+userData.clientId+"/"+list.productId+'/'+getBookingDetails.customerId+'/'+getBookingDetails.bookingId} className='btn btn-success'>Select service</Link></div>
                                                                        </Stack>
                                                                    </Col>
                                                                </Row>
                                                            </Card.Text>
                                                        </Card.Body>
                                                    </Card>
                                                ))
                                            :
                                                ''
                                    }
                                </div>
                            </Row>
                        </div>
                    </Col>
                </Row>
            </Container>
        </section>
    );
}
