import { React, useState, useEffect, useRef } from 'react';
import { Container, Row, Col, Stack, Image, Button, Table, OverlayTrigger, Popover, Form, Overlay } from "react-bootstrap";
import profile from '../../../images/profile.png'
import deleteIcon from "../../../images/delete.png";
import ban from "../../../images/block.png";
import unsuspend from "../../../images/unsuspend.png";
import services from "../../../images/service.png";
import edit from "../../../images/edit.png";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowDownAZ, faArrowDownZA, faFilter, faFilterCircleXmark } from '@fortawesome/free-solid-svg-icons';
import Topbar from '../Assets/Topbar';
import LeftSidebar from '../Assets/LeftSidebar';
import { getAllBarberShop, getAllState, getAllProfessions, getAllClientList, deleteStylistbyAdmin, suspendStylistbyAdmin } from '../../../Service';
import Swal from "sweetalert2";
import { InfinitySpin } from 'react-loader-spinner';
import Tooltip from 'react-bootstrap/Tooltip';
import unservices from "../../../images/unservice.png";
import HomeIcon from '@mui/icons-material/Home';
import AlternateEmailIcon from '@mui/icons-material/AlternateEmail';
import PhoneIphoneIcon from '@mui/icons-material/PhoneIphone';
import PaginationDesign from '../Assets/Pagination';
import EditProfessionalPopup from './Popup/EditProfessionalPopup';
import AddProfessionalPopup from './Popup/AddProfessionalPopup';
import { HtmlTagValidation, BlockSQLInjection } from '../../Reusable';
import { JsonEncode } from '../../Reusable/JsonModify';

export default function ManageStylist() {
    // initial state diclaration
    const [clientList, setClientList] = useState([]);
    const [clientAdd, setClientAdd] = useState(false);
    const [clientLoader, setClientLoader] = useState(true);
    const [option, setOption] = useState([]);
    const [professionOption, setProfessionOption] = useState([]);
    const [stateProvinceOption, setStateProvinceOption] = useState([]);
    const searchInitialValues = {searchByName : '', searchByEmail : '', searchByProfession : '', searchByShopName : ''};
    const [searchFormValues, setSearchFormValues] = useState(searchInitialValues);
    const [searchStatus, setSearchStatus] = useState(false);
    const [searchErrors ,setSearchErrors] = useState('');
    const [currentPage, setCurrentPage] = useState(1);
    const [listCount, setListCount] = useState(0);
    const [perPage, setPerPage] = useState(10);
    const [sortBy, setSortBy] = useState('id');
    const [sortDir, setSortDir] = useState('asc');
    const [searchBTNStatus, setSearchBTNStatus] = useState(false);
    const [clearbtnDisable, setClearbtnDisable] = useState(true);
    const [editProfessionalStatus, setEditProfessionalStatus] = useState(false);
    const [editUserData, setEditUserData] = useState({});
    const [addProfessionalStatus, setAddProfessionalStatus] = useState(false);

    // get all initial api data
    useEffect(() => {
        getAllBarberShop().then(res => barberShopResponse(res));
        getAllProfessions().then(res => professionResponse(res));
        getAllState().then(res => stateResponse(res));
    },[]);

    // get professional list
    useEffect(() => {
        setClientLoader(true);
        let listParams = {'searchProfessionalName' : searchFormValues.searchByName, 'searchProfessionalEmail' : searchFormValues.searchByEmail, 'searchProfessionName' : searchFormValues.searchByProfession, 'searchShopName' : searchFormValues.searchByShopName, 'pageNo' : currentPage, 'pageSize' : perPage, 'sortColumn' : sortBy, 'sortDirection' : sortDir};
        getAllClientList(listParams).then(res => clientResponse(res));
    },[clientAdd, currentPage, sortBy, sortDir, perPage]);

    // shop list api response
    const barberShopResponse = (response) => {
        if(response.data.statusCode === 200){
            const barbershowOption = [];
            response.data.shopList.map((list, index) => {
                const newShopid = list.shopId+','+list.name;
                const op = {label : list.name, value : newShopid};
                barbershowOption.push(op);
            });

            setOption(barbershowOption);
        }
    }

    // state list api response
    const stateResponse = (response) => {
        if(response.data.statusCode === 200){
            const stateShowOption = [];
            response.data.stateList.map((list) => {
                const stateProvinceData = {label : list.stateName, value : list.stateId};
                stateShowOption.push(stateProvinceData);
            })
            setStateProvinceOption(stateShowOption);
        }
    }

    // profession list api response
    const professionResponse = (response) => {
        if(response.data.statusCode === 200){
            const professionShowOption = [];
            response.data.professionList.map((list, index) => {
                const professionId = list.id+','+list.name;
                const professionData = {label : list.name, value : professionId};
                professionShowOption.push(professionData);
            })
            setProfessionOption(professionShowOption);
        }
    }

    // all client list response
    const clientResponse = (response) => {
        if(response.data.statusCode === 200){
            setClientList(response.data.clientList);
            setClientLoader(false);
            setListCount(response.data.filteredCount);
            setSearchBTNStatus(false);
        }else{
            setClientLoader(false);
        }
    }

    //delete user icon onclick
    const deleteUser = (data)=> {
        Swal.fire({
            title: 'Are you sure?',
            text: "Do you want to delete this Professional?",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes, delete it!'
        }).then((result) => {
            if (result.isConfirmed) {
                var whereDelete = {"clientId" : data.clientId};
                deleteStylistbyAdmin(whereDelete).then(res => handleDeleteResponse(res));
            }
        })
    }

    // delete user api response
    const handleDeleteResponse = (response) => {
        if(response.data.statusCode === 200){  
            Swal.fire({
                position: 'center',
                icon: 'success',
                title: 'Professional deleted successfully!',
                showConfirmButton: false,
                timer: 3000
            });
            if(clientList.length === 1 && currentPage !== 1){
                setCurrentPage(currentPage - 1);
            }else{
                setClientAdd(prevClientAdd => !prevClientAdd);
            }
        } else { 
            const errors = {};
            Swal.fire({
                icon: 'error',
                title: 'Oops...',
                text: response.data.statusMessage,
                showConfirmButton: false,
                timer: 3000
            });
        } 
    }

    // suspend icon onclick 
    const suspendUser = (data) => {
        Swal.fire({
            title: 'Are you sure?',
            text: "Do you want to suspend this Professional?",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes, Suspend!'
        }).then(async (result) => {
            if (result.isConfirmed) {
                var whereSuspend = {"clientId" : data.clientId, 'suspend' : 0};

                let jsonData = await JsonEncode(whereSuspend).then(result => {
                    return result
                }).catch(error => {
                    Swal.fire({
                        position: 'center',
                        icon: 'error',
                        title: 'Oops...',
                        showConfirmButton: false,
                        text: error,
                        timer: 3000
                    }); 
                });
                
                if ( jsonData ) {
                    let loginPayload = {data : jsonData};
                    suspendStylistbyAdmin(loginPayload).then(res => handleSuspendResponse(res));
                } else {
                    Swal.fire({
                        position: 'center',
                        icon: 'error',
                        title: 'Oops...',
                        showConfirmButton: false,
                        text: 'Something went wrong, Please try after sometimes',
                        timer: 3000
                    }); 
                }
                
            }
        })
    }

    //suspend api response
    const handleSuspendResponse = (response) => {
      if(response.data.statusCode === 200){  
          Swal.fire({
              position: 'center',
              icon: 'success',
              title: 'Professional suspended successfully!',
              showConfirmButton: false,
              timer: 3000
          });
          setClientAdd(prevClientAdd => !prevClientAdd);
      } else { 
          const errors = {};
          Swal.fire({
              icon: 'error',
              title: 'Oops...',
              text: response.data.statusMessage,
              showConfirmButton: false,
              timer: 3000
          });
      } 
    }

    // unsuspend icon onclick
    const unSuspendUser = (data) => {
        Swal.fire({
            title: 'Are you sure?',
            text: "Do you want to unsuspend this Professional?",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes, Unsuspend!'
        }).then(async (result) => {
            if (result.isConfirmed) {
                var whereSuspend = {"clientId" : data.clientId, 'suspend' : 1};
                let jsonData = await JsonEncode(whereSuspend).then(result => {
                    return result
                }).catch(error => {
                    Swal.fire({
                        position: 'center',
                        icon: 'error',
                        title: 'Oops...',
                        showConfirmButton: false,
                        text: error,
                        timer: 3000
                    }); 
                });
                
                if ( jsonData ) {
                    let loginPayload = {data : jsonData};
                    suspendStylistbyAdmin(loginPayload).then(res => handleUnSuspendResponse(res));
                } else {
                    Swal.fire({
                        position: 'center',
                        icon: 'error',
                        title: 'Oops...',
                        showConfirmButton: false,
                        text: 'Something went wrong, Please try after sometimes',
                        timer: 3000
                    }); 
                }
                
            }
        })
    }

    //unsuspend api response
    const handleUnSuspendResponse = (response) => {
        if(response.data.statusCode === 200){  
            Swal.fire({
                position: 'center',
                icon: 'success',
                title: 'Professional unsuspended successfully!',
                showConfirmButton: false,
                timer: 3000
            });
            setClientAdd(prevClientAdd => !prevClientAdd);
        } else { 
            const errors = {};
            Swal.fire({
                icon: 'error',
                title: 'Oops...',
                text: response.data.statusMessage,
                showConfirmButton: false,
                timer: 3000
            });
        } 
    }

    const [showed, setShowed] = useState(false);
    const targeted = useRef(null);

    const [showPhone, setShowPhone] = useState(false);
    const targetPhone = useRef(null);

    const [showAdrs, setShowAdrs] = useState(false);
    const targetAdrs = useRef(null);

    // search functionalities start here
    const searchOnClick = () => {
      setSearchStatus(true);
    }

    const clearSearchOnClick = () => {
        setSearchStatus(false);
        setSearchFormValues(searchInitialValues);
        setSearchErrors('');
        setCurrentPage(1);
        setListCount(0);
        setPerPage(10);
        setSortBy('id');
        setSortDir('asc');
        setClientLoader(true);
        setClearbtnDisable(true);

        let listParams = {'searchProfessionalName' : '', 'searchProfessionalEmail' : '', 'searchProfessionName' : '', 'searchShopName' : '', 'pageNo' : 1, 'pageSize' : 10, 'sortColumn' : 'id', 'sortDirection' : 'asc'};
        getAllClientList(listParams).then(res => clientResponse(res));
    }

    const searchHandleChange = (e) => {
        const value = e.target.value;
        const field= e.target.name;
        setSearchFormValues(prevState => ({
            ...prevState,
            [field]:  value  
        }));
    }

    const searchHandleSearch = (e) => {
        e.preventDefault();
        const validate = searchValidate(searchFormValues);
        setSearchErrors(validate.search);
        if(validate.status){
          setCurrentPage(1);
          setPerPage(10);
          setClientLoader(true);
          setSearchBTNStatus(true);
          setClearbtnDisable(false);

          let listParams = {'searchProfessionalName' : searchFormValues.searchByName, 'searchProfessionalEmail' : searchFormValues.searchByEmail, 'searchProfessionName' : searchFormValues.searchByProfession, 'searchShopName' : searchFormValues.searchByShopName, 'pageNo' : 1, 'pageSize' : 10, 'sortColumn' : sortBy, 'sortDirection' : sortDir};
          getAllClientList(listParams).then(res => searchClientResponse(res));
        }
    }

    const searchClientResponse = (response) => {
        if(response.data.statusCode === 200){
          setClientList(response.data.clientList);
          setClientLoader(false);
          setListCount(response.data.filteredCount);
          setSearchBTNStatus(false);
        }else{
          setClientLoader(false);
        }
    }

    const searchValidate = (values) => {
        const errors = {};
        errors.status = true;
        
        if((values.searchByName == '' || values.searchByName.trim() === '') && (values.searchByEmail == '' || values.searchByEmail.trim() === '') && (values.searchByProfession == '' || values.searchByProfession.trim() === '') && (values.searchByShopName == '' || values.searchByShopName.trim() === '')){
            errors.search = 'Provide any search term';
            errors.status = false;
        }

        const validations = {
            searchByName: HtmlTagValidation(values.searchByName),
            searchByEmail: HtmlTagValidation(values.searchByEmail),
            searchByProfession: HtmlTagValidation(values.searchByProfession),
            searchByShopName: HtmlTagValidation(values.searchByShopName)
        };

        Object.entries(validations).forEach(([key, value]) => {
            if (!value) {
                errors.search = 'Invalid input';
                errors.status = false;
            }
        });

        return errors;
    }

    const getBackCurrentPage = (page, perPage) => {
        setPerPage(perPage);
        setCurrentPage(page);
    }

    const sortingOnClick = (data) => {
        setSortBy(data.by);
        setSortDir(data.dir);
    }

    // edit professional icon onclick
    const editUser = (userData) => {
        setEditProfessionalStatus(true);
        setEditUserData(userData)
    }

    // call back from edit professional popup
    const getStatusFromEditPopup = (popupStatus, reloadStatus) => {
        if(popupStatus === true){
            setEditProfessionalStatus(false);
            setEditUserData({});
        }

        if(reloadStatus === true){
            setEditProfessionalStatus(false);
            setEditUserData({});
            setClientAdd(prevClientAdd => !prevClientAdd);
        }
    }


    // add professional icon onclick
    const handleShow = () => {
        setAddProfessionalStatus(true);
    }

    // call back from add professional popup
    const getStatusFromAddPopup = (popupStatus, reloadStatus) => {
        if(popupStatus === true){
            setAddProfessionalStatus(false);
        }

        if(reloadStatus === true){
            setAddProfessionalStatus(false);
            setClientAdd(prevClientAdd => !prevClientAdd);
        }
    }

    // get decimal two degit cost
    const getFullCost = (dollar) => {
        let roundCost = (Math.floor(dollar * 100) / 100).toFixed(2);
        return roundCost;
    }

    return(
        <section className='dashboard'>
            <Container>
                <Row className='sideBarRow'>
                    <Col md={3} className='sideBarCol'>
                        <LeftSidebar/>
                    </Col>
                    <Col md={9}>
                        <div className='rightsidebar'>
                            <Topbar/>
                            <Row className="mb-3 main-title">
                                <Col>
                                    <h1>Manage Professionals</h1>
                                </Col>
                            </Row>
                            <Row className="mb-3 managesearch">
                                <Col>
                                    <Stack direction="horizontal" className="align-items-end" gap={2}>
                                        <div className="add-btn ms-auto">
                                            <Button variant="success" onClick={handleShow}>+ Add Professional</Button>
                                        </div>
                                        <div className="search-status-btn">
                                            {
                                                searchStatus == false ?
                                                    <Button variant="primary" onClick={searchOnClick}>New Filter <FontAwesomeIcon icon={faFilter} /></Button>
                                                : 
                                                    ''
                                            }
                                        </div>
                                    </Stack>
                                </Col>
                            </Row> 
                            <Row>
                                <Form onSubmit={searchHandleSearch}>
                                    <Row className='searchRow'>
                                        {
                                            searchStatus == false ? 
                                                ''
                                            :
                                                <>
                                                    <Col xl={8}>
                                                        <Row>
                                                            <Col lg={6}>
                                                                <Row>
                                                                    <Col md={6}>
                                                                        <Form.Group as={Col} controlId="formGridSearchName">
                                                                            <Form.Label>Professional Name</Form.Label>
                                                                            <Form.Control onKeyDown={BlockSQLInjection} type="text" name='searchByName' placeholder='Professional name...' defaultValue={searchFormValues.searchByName} onChange={searchHandleChange}/>
                                                                        </Form.Group>
                                                                    </Col>
                                                                    <Col md={6}>
                                                                        <Form.Group as={Col} controlId="formGridSearchName">
                                                                            <Form.Label>Professional Email</Form.Label>
                                                                            <Form.Control onKeyDown={BlockSQLInjection} type="text" name='searchByEmail' placeholder='Professional email...' defaultValue={searchFormValues.searchByEmail} onChange={searchHandleChange}/>
                                                                        </Form.Group>
                                                                    </Col>
                                                                </Row>
                                                            </Col>
                                                            <Col lg={6}>
                                                                <Row>
                                                                    <Col md={6}>
                                                                        <Form.Group as={Col} controlId="formGridSearchName">
                                                                            <Form.Label>Profession Name</Form.Label>
                                                                            <Form.Control onKeyDown={BlockSQLInjection} type="text" name='searchByProfession' placeholder='Profession name...' defaultValue={searchFormValues.searchByProfession} onChange={searchHandleChange}/>
                                                                        </Form.Group>
                                                                    </Col>
                                                                    <Col md={6}>
                                                                        <Form.Group as={Col} controlId="formGridSearchName">
                                                                            <Form.Label>Business Name</Form.Label>
                                                                            <Form.Control onKeyDown={BlockSQLInjection} type="text" name='searchByShopName' placeholder='Business name...' defaultValue={searchFormValues.searchByShopName} onChange={searchHandleChange}/>
                                                                        </Form.Group>
                                                                    </Col>
                                                                </Row>
                                                            </Col>
                                                        </Row>
                                                    </Col>
                                                    <Col xl={4} className="searcchAllBtn">
                                                        <Button variant="primary" type='submit' disabled={searchBTNStatus}>Search</Button>
                                                        <Button variant="danger" disabled={clearbtnDisable} onClick={clearSearchOnClick} className='search-form-clear-btn'>Clear Filter <FontAwesomeIcon icon={faFilterCircleXmark} /></Button>
                                                    </Col>
                                                </> 
                                        }
                                        <span className='error'>{searchErrors}</span>
                                    </Row>
                                </Form>
                            </Row>
                            <Row>
                                <Col>
                                    <div className="table-responsive" id='no-more-tables'>
                                        <Table striped bordered hover variant="dark">
                                            <thead>
                                                <tr>
                                                    <th>S.No</th>
                                                    <th>
                                                        Business Name
                                                        {
                                                            listCount > 0 ?
                                                                <span className='sort'> {sortBy == 'bussinessName' ? sortDir == 'asc' ? <FontAwesomeIcon icon={faArrowDownZA}  onClick={() => sortingOnClick({by : 'bussinessName', dir : 'desc'})}/> : <FontAwesomeIcon icon={faArrowDownAZ} onClick={() => sortingOnClick({by : 'bussinessName', dir : 'asc'})}/> : <FontAwesomeIcon icon={faArrowDownAZ} onClick={() => sortingOnClick({by : 'bussinessName', dir : 'asc'})}/>}</span>
                                                            : 
                                                                ''
                                                        }
                                                    </th>
                                                    <th>
                                                        Professionals Name
                                                        {
                                                            listCount > 0 ?
                                                                <span className='sort'> {sortBy == 'professionalName' ? sortDir == 'asc' ? <FontAwesomeIcon icon={faArrowDownZA}  onClick={() => sortingOnClick({by : 'professionalName', dir : 'desc'})}/> : <FontAwesomeIcon icon={faArrowDownAZ} onClick={() => sortingOnClick({by : 'professionalName', dir : 'asc'})}/> : <FontAwesomeIcon icon={faArrowDownAZ} onClick={() => sortingOnClick({by : 'professionalName', dir : 'asc'})}/>}</span>
                                                            :
                                                                ''
                                                        }
                                                    </th>
                                                    <th >Services </th>           
                                                    <th>
                                                        Profession
                                                        {
                                                            listCount > 0 ?
                                                                <span className='sort'> {sortBy == 'professionName' ? sortDir == 'asc' ? <FontAwesomeIcon icon={faArrowDownZA}  onClick={() => sortingOnClick({by : 'professionName', dir : 'desc'})}/> : <FontAwesomeIcon icon={faArrowDownAZ} onClick={() => sortingOnClick({by : 'professionName', dir : 'asc'})}/> : <FontAwesomeIcon icon={faArrowDownAZ} onClick={() => sortingOnClick({by : 'professionName', dir : 'asc'})}/>}</span>
                                                            :
                                                                ''
                                                        }
                                                    </th>
                                                    <th>Total Revenue </th>
                                                    <th>Profile</th>
                                                    <th>Action</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {
                                                    clientLoader === true ? 
                                                        <tr>
                                                            <td colSpan='8' style={{textAlign : 'center', paddingLeft : '0px'}}><InfinitySpin height="80" width="200" radius="9" color="#D49D29" ariaLabel="loading" wrapperStyle wrapperClass /></td>
                                                        </tr>
                                                    :
                                                        clientList.length > 0 ?
                                                            clientList.map((data, index) => (
                                                                <tr key={index}>
                                                                    <td className='table-row-number'>{index + 1 + (currentPage * perPage) - perPage}</td>
                                                                    <td data-title='Business Name'>{data.shopName}</td>
                                                                    <td data-title='First Name'>{data.firstName +' '+ data.lastName}</td>
                                                                    <td data-title='Services'> 
                                                                        {
                                                                        data.serviceList ?
                                                                            data.serviceList.length > 0 ?
                                                                                <OverlayTrigger
                                                                                    trigger="click"
                                                                                    placement="left"
                                                                                    rootClose={true}
                                                                                    overlay={
                                                                                        <Popover id={'popover-services'} style={{"background":"#222", "color":"#fff"}}>
                                                                                            <div className="mb-3">
                                                                                                <h2>List of Services </h2>
                                                                                                {
                                                                                                    data.serviceList.map((list, index)=>(
                                                                                                        <div key={index}>
                                                                                                            <p><strong>{list.subServiceName}</strong></p>
                                                                                                            <h5>Cost : ${list.cost.toFixed(2)} </h5>  
                                                                                                            <h5>Duration : {list.duration} Minutes</h5>  
                                                                                                            <hr/>
                                                                                                        </div>
                                                                                                    ))
                                                                                                }   
                                                                                            </div>
                                                                                        </Popover>
                                                                                    }
                                                                                >
                                                                                    <Image src={services} style={{cursor:'pointer'}}/>
                                                                                </OverlayTrigger>
                                                                            :
                                                                                <Image src={unservices} />
                                                                        :
                                                                            <></>
                                                                        }
                                                                    </td>           
                                                                    <td data-title='Profession'>{data.professionName}</td>
                                                                    <td data-title='Total Revenue'>${data.totalRevenue.toFixed(2)}</td>
                                                                    <td data-title='Profile'>
                                                                        <OverlayTrigger
                                                                            trigger="click"
                                                                            placement="left"
                                                                            rootClose={true}
                                                                            overlay={
                                                                                <Popover id={'popover-positioned-left'} style={{"background":"#222", "color":"#fff"}}>
                                                                                    <h2>Profile</h2>
                                                                                    <div className="mb-3 pop-profile-icon">
                                                                                        <h5 ref={targeted}  onMouseEnter={() => setShowed(true)} onMouseLeave={() => setShowed(false)}><strong><AlternateEmailIcon /></strong></h5>
                                                                                        <p><a href={'mailto:'+data.email} style={{textDecoration : 'none', color : '#000'}}>{data.email}</a></p>
                                                                                    </div>
                                                                                    <div className="mb-3 pop-profile-icon">
                                                                                        <h5 ref={targetPhone}  onMouseEnter={() => setShowPhone(true)} onMouseLeave={() => setShowPhone(false)}><strong><PhoneIphoneIcon /></strong></h5>
                                                                                        <p>{data.phone}</p>
                                                                                    </div>
                                                                                    <div className="mb-3 pop-profile-icon">
                                                                                        <h5 ref={targetAdrs}  onMouseEnter={() => setShowAdrs(true)} onMouseLeave={() => setShowAdrs(false)}><strong><HomeIcon /></strong></h5>
                                                                                        <p>{data.address1+', '+data.address2+', '+data.city}<br></br>{data.state+' '+data.zipPostalCode}</p>
                                                                                    </div>
                                                                                </Popover>
                                                                            }
                                                                        >
                                                                            <Image src={profile} style={{cursor : 'pointer'}}/>
                                                                        </OverlayTrigger>
                                                                        <Overlay target={targeted.current} show={showed} placement="bottom">
                                                                            {({
                                                                                placement: _placement,
                                                                                arrowProps: _arrowProps,
                                                                                show: _show,
                                                                                popper: _popper,
                                                                                hasDoneInitialMeasure: _hasDoneInitialMeasure,
                                                                                ...props
                                                                            }) => (
                                                                                <div
                                                                                    {...props}
                                                                                    style={{
                                                                                        position: 'absolute',
                                                                                        backgroundColor: '#000',
                                                                                        padding: '2px 5px',
                                                                                        color: '#fff',
                                                                                        borderRadius: 5,
                                                                                        zIndex: 9999,
                                                                                        fontSize: 13,
                                                                                        ...props.style,
                                                                                    }}
                                                                                >
                                                                                    Email
                                                                                </div>
                                                                            )}
                                                                        </Overlay>
                                                                        <Overlay target={targetPhone.current} show={showPhone} placement="bottom">
                                                                            {({
                                                                                placement: _placement,
                                                                                arrowProps: _arrowProps,
                                                                                show: _show,
                                                                                popper: _popper,
                                                                                hasDoneInitialMeasure: _hasDoneInitialMeasure,
                                                                                ...props
                                                                            }) => (
                                                                                <div
                                                                                    {...props}
                                                                                    style={{
                                                                                        position: 'absolute',
                                                                                        backgroundColor: '#000',
                                                                                        padding: '2px 5px',
                                                                                        color: '#fff',
                                                                                        borderRadius: 5,
                                                                                        zIndex: 9999,
                                                                                        fontSize: 13,
                                                                                        ...props.style,
                                                                                    }}
                                                                                >
                                                                                    Phone
                                                                                </div>
                                                                            )}
                                                                        </Overlay>
                                                                        <Overlay target={targetAdrs.current} show={showAdrs} placement="bottom">
                                                                            {({
                                                                                placement: _placement,
                                                                                arrowProps: _arrowProps,
                                                                                show: _show,
                                                                                popper: _popper,
                                                                                hasDoneInitialMeasure: _hasDoneInitialMeasure,
                                                                                ...props
                                                                            }) => (
                                                                                <div
                                                                                    {...props}
                                                                                    style={{
                                                                                        position: 'absolute',
                                                                                        backgroundColor: '#000',
                                                                                        padding: '2px 5px',
                                                                                        color: '#fff',
                                                                                        borderRadius: 5,
                                                                                        zIndex: 9999,
                                                                                        fontSize: 13,
                                                                                        ...props.style,
                                                                                    }}
                                                                                >
                                                                                    Address
                                                                                </div>
                                                                            )}
                                                                        </Overlay>
                                                                    </td>
                                                                    <td data-title='Action' className='professionalAction'>
                                                                        <Stack direction="horizontal" gap={3} className='professionAction'>
                                                                            <div className="">
                                                                                <Image src={deleteIcon} style={{cursor: 'pointer'}} onClick={() => deleteUser(data)} className='tableActionIcon' />
                                                                                <Button className='actionText dltBtn' style={{cursor: 'pointer'}} onClick={() => deleteUser(data)}>Delete</Button>
                                                                            </div>
                                                                            <div className=''>
                                                                                <Image src={edit} style={{cursor: 'pointer'}} onClick={() => editUser(data)} className='tableActionIcon' />
                                                                                <Button className='actionText dltBtn' style={{cursor: 'pointer'}} onClick={() => editUser(data)}>Edit</Button>
                                                                            </div>
                                                                            <div className='tableActionIcon'>
                                                                                {
                                                                                    data.isActive === 1 ? 
                                                                                        <OverlayTrigger
                                                                                            placement="bottom"
                                                                                            overlay={<Tooltip id="button-tooltip-2">Suspend</Tooltip>}
                                                                                        >
                                                                                            <Image src={ban} style={{cursor: 'pointer'}} onClick={() => suspendUser(data)}  />
                                                                                        </OverlayTrigger>
                                                                                    :
                                                                                        <OverlayTrigger
                                                                                            placement="bottom"
                                                                                            overlay={<Tooltip id="button-tooltip-2">Unsuspend</Tooltip>}
                                                                                            >
                                                                                            <Image src={unsuspend} style={{cursor: 'pointer'}} onClick={() => unSuspendUser(data)}/>
                                                                                        </OverlayTrigger>
                                                                                }
                                                                            </div>
                                                                            <div className="actionText">
                                                                                {
                                                                                    data.isActive === 1 ? 
                                                                                        <OverlayTrigger
                                                                                            placement="bottom"
                                                                                            overlay={<Tooltip id="button-tooltip-2">Suspend</Tooltip>}
                                                                                        >
                                                                                            <Button className='banBtn' style={{cursor: 'pointer'}} onClick={() => suspendUser(data)}>Suspend</Button>
                                                                                        </OverlayTrigger>
                                                                                    :
                                                                                        <OverlayTrigger
                                                                                            placement="bottom"
                                                                                            overlay={<Tooltip id="button-tooltip-2">Unsuspend</Tooltip>}
                                                                                            >
                                                                                                <Button className='unsuspendBtn' style={{cursor: 'pointer'}} onClick={() => unSuspendUser(data)}>Unsuspend</Button>
                                                                                        </OverlayTrigger>
                                                                                }
                                                                            </div>
                                                                        </Stack>
                                                                    </td>
                                                                </tr>
                                                            ))
                                                        :
                                                            <tr>
                                                                <td colSpan='8' style={{textAlign : 'center'}} className='noDataFound'>No Data Found...</td>
                                                            </tr>
                                                }
                                            </tbody>
                                        </Table>
                                    </div>
                                </Col>
                            </Row>  
                            {
                                listCount > 0 ?
                                    <PaginationDesign currentPage={currentPage} listCount={listCount} perPage={perPage} getBack={getBackCurrentPage}/>
                                : 
                                    ''
                            }

                            {
                                editProfessionalStatus === true ?
                                    <EditProfessionalPopup professionalData={editUserData} businessOptions={option} getBack={getStatusFromEditPopup} stateOptions={stateProvinceOption}/>
                                : 
                                    ''
                            }

                            {
                                addProfessionalStatus === true ?
                                    <AddProfessionalPopup getBack={getStatusFromAddPopup} businessOptions={option} professionOption={professionOption} stateOptions={stateProvinceOption}/>
                                :
                                    ''
                            }
                        </div>
                    </Col>
                </Row>
            </Container>
        </section>
    )
} 