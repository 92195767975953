import { React, useState, useEffect } from 'react';
import { customerBookingHistory } from '../../../Service';
import { InfinitySpin } from 'react-loader-spinner';
import { useParams, useNavigate } from 'react-router-dom';
import LeftSidebar from '../Assets/LeftSidebar';
import Topbar from '../Assets/Topbar';
import { Container, Row, Col, Button, Table, Stack, Form, OverlayTrigger, Popover } from "react-bootstrap";
import moment from 'moment';
import { useSelector } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFilter, faFilterCircleXmark, faCircleExclamation } from '@fortawesome/free-solid-svg-icons';
import PaginationDesign from '../Assets/Pagination';

export default function CustomerBookingHistory(){
    const params = useParams();
    const navigate = useNavigate();

    const [bookingList, setBookingList] = useState([]);
    const [bookingLoader, setBookingLoader] = useState(true);
    const searchInitialValues = {searchByStartDate : '', searchByEndDate : ''};
    const [searchFormValues, setSearchFormValues] = useState(searchInitialValues);
    const [searchStatus, setSearchStatus] = useState(false);
    const [searchErrors ,setSearchErrors] = useState('');
    const [currentPage, setCurrentPage] = useState(1);
    const [listCount, setListCount] = useState(0);
    const [perPage, setPerPage] = useState(10);
    const [sortBy, setSortBy] = useState('id');
    const [sortDir, setSortDir] = useState('desc');
    const [clearbtnDisable, setClearbtnDisable] = useState(true);
    const [customerName, setCustomerName] = useState('');

    // get customer data from store
    const userData =  useSelector(store => store.user['stylistuser']);

    // get service list data by api
    useEffect(() => {
        setBookingLoader(true);
        const whereCustomer = {'clientId' : userData ? userData.clientId : '', 'customerId' : params.customerId, 'searchStartDate' : searchFormValues.searchByStartDate, 'searchEndDate' : searchFormValues.searchByEndDate, 'pageNo' : currentPage, 'pageSize' : perPage, 'sortColumn' : sortBy, 'sortDirection' : sortDir};
        customerBookingHistory(whereCustomer).then(res => bookingHistoryResponse(res));
    },[currentPage, sortBy, sortDir, perPage]);

    const bookingHistoryResponse = (response) => {
        if(response.data.statusCode === 200) {
            setBookingList(response.data.customerBookingList);
            setBookingLoader(false);
            setListCount(response.data.filteredCount);
            setCustomerName(response.data.customerFirstName+' '+response.data.customerLastName);
        }else{
            setBookingLoader(false);
        }
    }

    //get UTC time to Local time
    const getUTCtoLocal = (onlyTime) => {
        var local_date= moment.utc(onlyTime).local().format("YYYY-MM-DD HH:mm");

        return local_date;
    }

    const handlePrevPage = () => {
        navigate(-1);
    }

    // search functionalities start here
    const searchOnClick = () => {
        setSearchStatus(true);
    }

    const clearSearchOnClick = () => {
        setSearchStatus(false);
        setSearchFormValues(searchInitialValues);
        setSearchErrors('');
        setCurrentPage(1);
        setListCount(0);
        setPerPage(10);
        setSortBy('id');
        setSortDir('desc');
        setClearbtnDisable(true);
        setBookingLoader(true);

        const whereCustomer = {'clientId' : userData.clientId, 'customerId' : params.customerId, 'searchStartDate' : '', 'searchEndDate' : '', 'pageNo' : 1, 'pageSize' : 10, 'sortColumn' : 'id', 'sortDirection' : 'asc'};
        customerBookingHistory(whereCustomer).then(res => bookingHistoryResponse(res));

    }

    const searchHandleChange = (e) => {
        const value = e.target.value;
        const field= e.target.name;
        setSearchFormValues(prevState => ({
            ...prevState,
            [field]:  value  
        }));
    }

    const searchHandleSearch = (e) => {
        e.preventDefault();
        const validate = searchValidate(searchFormValues);
        setSearchErrors(validate.search);
        if(validate.status){
            setCurrentPage(1);
            setPerPage(10);
            setBookingLoader(true);
            setClearbtnDisable(false);

            const whereCustomer = {'clientId' : userData.clientId, 'customerId' : params.customerId, 'searchStartDate' : searchFormValues.searchByStartDate, 'searchEndDate' : searchFormValues.searchByEndDate, 'pageNo' : 1, 'pageSize' : 10, 'sortColumn' : sortBy, 'sortDirection' : sortDir};
            customerBookingHistory(whereCustomer).then(res => searchBookingHistoryResponse(res));
        }
    }

    const searchBookingHistoryResponse = (response) => {
        if(response.data.statusCode === 200) {
            setBookingList(response.data.customerBookingList);
            setBookingLoader(false);
            setListCount(response.data.filteredCount);
        }else{
            setBookingLoader(false);
        }
    }

    const searchValidate = (values) => {
        const errors = {};
        errors.status = true;
        
        if(values.searchByStartDate == '' && values.searchByEndDate == ''){
            errors.search = 'Provide any search term';
            errors.status = false;
        }

        if(values.searchByStartDate || values.searchByEndDate){
            if(values.searchByStartDate == '' || values.searchByEndDate == ''){
                errors.search = 'Appointment start date and end date are required';
                errors.status = false;
            }else if(values.searchByStartDate && values.searchByEndDate){
                let endDate = moment(values.searchByEndDate).format('YYYY-MM-DD');
                let StartDate = moment(values.searchByStartDate).format('YYYY-MM-DD');
    
                if(StartDate > endDate){
                    errors.search = 'Appointment end date cannot be less than appointment start date';
                    errors.status = false;
                }
            }
        }

        return errors;
    }

    const getBackCurrentPage = (page, perPage) => {
        setPerPage(perPage);
        setCurrentPage(page);
    }

    // get decimal two degit cost
    const getFullCost = (dollar) => {
        let roundCost = (Math.floor(dollar * 100) / 100).toFixed(2);
        return roundCost;
    }

    return(
        <section className='dashboard'>
            <Container>
                <Row className='sideBarRow'>       
                    <Col md={3} className='sideBarCol'>
                        <LeftSidebar/>
                    </Col>
                    <Col md={9}>
                        <div className='rightsidebar'>
                            <Topbar/>
                            <Row className="mb-3 main-title">
                                <Col>
                                    <hr />
                                    <Stack direction='horizontal' className='stackInside'>
                                    <div >
                                        <h1>Booking History</h1>
                                        <p className='customerNameTitle'>{customerName}</p>
                                    </div>
                                    <div className="ms-auto">
                                        <Button variant="outline-secondary" onClick={handlePrevPage}>Back</Button>
                                    </div>
                                    </Stack>
                                    
                                </Col>
                            </Row>
                            {
                                searchStatus == false ?
                                    <Row className="mb-3 managesearch">
                                        <Col>
                                            <Stack direction="horizontal" className="align-items-end" gap={2}>
                                                <div className="add-btn ms-auto search-status-btn">
                                                    <Button variant="primary" onClick={searchOnClick}>New Filter <FontAwesomeIcon icon={faFilter} /></Button>
                                                </div>
                                            </Stack>
                                        </Col>
                                    </Row> 
                                :
                                    ''
                            }
                            <Row>
                                <Form onSubmit={searchHandleSearch}>
                                    <Row className='searchRow'>
                                        {
                                        searchStatus == false ? 
                                            ''
                                        :
                                            <>
                                                <Col xl={8}>
                                                    <Row>
                                                        <Col md={6}>
                                                            <Form.Group as={Col} controlId="formGridSearchName">
                                                                <Form.Label>Start Date</Form.Label>
                                                                <Form.Control type="date" name='searchByStartDate' onChange={searchHandleChange}/>
                                                            </Form.Group>
                                                        </Col>
                                                        <Col md={6}>
                                                            <Form.Group as={Col} controlId="formGridSearchName">
                                                                <Form.Label>End Date</Form.Label>
                                                                <Form.Control type="date" name='searchByEndDate' onChange={searchHandleChange}/>
                                                            </Form.Group>
                                                        </Col>
                                                    </Row>
                                                </Col>
                                                <Col xl={4} className="searcchAllBtn">
                                                    <Button variant="primary" type='submit'>Search</Button>
                                                    <Button variant="danger" disabled={clearbtnDisable} onClick={clearSearchOnClick} className='search-form-clear-btn'>Clear Filter <FontAwesomeIcon icon={faFilterCircleXmark} /></Button>
                                                </Col>
                                            </> 
                                        }
                                        <span className='error'>{searchErrors}</span>
                                    </Row>
                                </Form>
                            </Row>
                            <Row>
                                <Col >
                                    <div className="table-responsive" id="no-more-tables">
                                        <Table striped bordered hover variant="dark" style={{whiteSpace : 'nowrap'}}>
                                            <thead>
                                                <tr>
                                                    <th>S. No</th>
                                                    <th>Appointment<br></br> Date</th>
                                                    <th>Slot<br></br> Time</th>
                                                    <th>Business<br></br> Name</th>
                                                    <th>Professional<br></br> Name</th>
                                                    <th>Service<br></br> Offered</th>
                                                    <th>Booked / <br></br>Edited By</th>
                                                    <th>Booked<br></br> Date</th>
                                                    <th>Payment</th>
                                                    <th>Payment Type</th>
                                                    <th>Status</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {
                                                bookingLoader === true ? 
                                                    <tr>
                                                    <td colSpan='11' style={{textAlign : 'center', paddingLeft : '0px'}}><InfinitySpin height="80" width="200" radius="9" color="#D49D29" ariaLabel="loading" wrapperStyle wrapperClass /></td>
                                                    </tr>
                                                :
                                                    bookingList.length > 0 ? 
                                                        bookingList.map((data, index) => (
                                                            <tr key={index}>
                                                                <td className='table-row-number'>{index + 1 + (currentPage * perPage) - perPage}</td>
                                                                <td data-title="Appointment Date">{moment(data.appoinmentSlotDate).format('MM-DD-YYYY')}</td>
                                                                <td data-title="Slot Time">{data.startTime +' - '+data.endTime}</td>
                                                                <td data-title="Business Name">{data.shopName}</td>
                                                                <td data-title="Professional Name">{data.clientName}</td>
                                                                <td data-title="Service Offered">{data.subServiceName}</td>
                                                                <td data-title="Booked By">{data.bookingType == 'CS' ? 'Customer' : (data.bookingType == 'CL' || data.bookingType == 'CLEB') ? 'Professional' : data.bookingType == 'CLSB' ? 'Self Booking' : ''}</td>
                                                                <td data-title="Booked Date">{moment(getUTCtoLocal(data.createdDate)).format('MM-DD-YYYY')}</td>
                                                                <td data-title="Payment">
                                                                    ${data.payment.toFixed(2)}
                                                                    <sup className='payment-icon'>
                                                                        <OverlayTrigger trigger="click" placement="left" rootClose={true}
                                                                        overlay={
                                                                            <Popover id={'popover-payment'} style={{"background":"#222", "color":"#fff"}}>
                                                                            <div>
                                                                                <h5>Service : ${data.serviceCost.toFixed(2)} </h5>  
                                                                                <h5>Tip : ${data.tip.toFixed(2)}</h5>  
                                                                                <h5>Platform Fees : ${data.platformFees.toFixed(2)}</h5>  
                                                                                <p><strong>Total : </strong>${data.payment.toFixed(2)}</p>
                                                                            </div>
                                                                            </Popover>
                                                                        }
                                                                        >
                                                                        <FontAwesomeIcon icon={faCircleExclamation} />
                                                                        </OverlayTrigger>
                                                                    </sup>
                                                                </td>
                                                                <td data-title="Payment Type">{data.paymentType}</td>
                                                                <td data-title="Status">
                                                                    {
                                                                        data.bookingStatus === 'Completed' ?
                                                                            <Button variant="success" className="admin-trans-btn success-btn">{data.bookingStatus}</Button>
                                                                        : 
                                                                            data.bookingStatus === 'Canceled' ?
                                                                                <Button variant="danger" className="admin-trans-btn danger-btn">{data.bookingStatus}</Button>
                                                                            : 
                                                                                data.bookingStatus === 'Booked' ?
                                                                                    (data.bookingType == 'CL' || data.bookingType == 'CLEB') && data.isCustomerRespondent === false ?
                                                                                        <Button variant="danger" className="admin-trans-btn danger-btn">Verification Pending</Button>
                                                                                    :
                                                                                        <Button variant="success" className="admin-trans-btn success-btn">{data.bookingStatus}</Button>
                                                                                :
                                                                                    <Button variant="warning" className="admin-trans-btn warning-btn">{data.bookingStatus}</Button>
                                                                    }
                                                                </td>
                                                            </tr>
                                                        ))
                                                    :
                                                        <tr>
                                                            <td colSpan='11' style={{textAlign : 'center'}} className='noDataFound'>No Data Found...</td>
                                                        </tr>
                                                }
                                            </tbody>
                                        </Table>
                                    </div>
                                </Col>
                            </Row>
                            {
                                listCount > 0 ?
                                    <PaginationDesign currentPage={currentPage} listCount={listCount} perPage={perPage} getBack={getBackCurrentPage}/>
                                : 
                                    ''
                            }
                        </div>
                    </Col>
                </Row>
            </Container>
        </section>
    )
}