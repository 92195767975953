import { React, useState, useEffect } from "react";
import { Container, Row, Col, Stack, Form, Button, Table} from "react-bootstrap";
import moment from 'moment'; 
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFilter, faFilterCircleXmark, faArrowDownAZ, faArrowDownZA } from '@fortawesome/free-solid-svg-icons';
import { StylistTransactionList } from '../../../Service';
import { InfinitySpin } from 'react-loader-spinner';
import Topbar from '../Assets/Topbar';
import LeftSidebar from '../Assets/LeftSidebar';
import { useSelector } from 'react-redux';
import PaginationDesign from '../Assets/Pagination';
import { HtmlTagValidation, BlockSQLInjection } from "../../Reusable";
import { useNavigate } from "react-router-dom";

export default function ManageStylist() {
    // initial state declaration  
    const [transactionList, setTransactionList] = useState([]);
    const [transactionLoader, setTransactionLoader] = useState(true);
    const searchInitialValues = {searchByCustomerName : '', searchByBusinessName : '', searchByStartDate : '', searchByEndDate : '', searchByMinimumAmount : '', searchByMaximumAmount : ''};
    const [searchFormValues, setSearchFormValues] = useState(searchInitialValues);
    const [searchStatus, setSearchStatus] = useState(false);
    const [searchErrors ,setSearchErrors] = useState('');
    const [currentPage, setCurrentPage] = useState(1);
    const [listCount, setListCount] = useState(0);
    const [perPage, setPerPage] = useState(10);
    const [sortBy, setSortBy] = useState('completedBookingDate');
    const [sortDir, setSortDir] = useState('desc');
    const [clearbtnDisable, setClearbtnDisable] = useState(true);

    // get customer data from store
    const userData = useSelector(store => store.user['stylistuser']);
    const navigate = useNavigate();

    // get all transaction list
    useEffect(() => { 
        setTransactionLoader(true);
        const userId = {'clientId' : userData.clientId, 'shopName' : searchFormValues.searchByBusinessName, 'customerName' : searchFormValues.searchByCustomerName, 'startDate' : searchFormValues.searchByStartDate, 'endDate' : searchFormValues.searchByEndDate, 'pageNo' : currentPage, 'pageSize' : perPage, 'sortColumn' : sortBy, 'sortDirection' : sortDir, 'minimumAmount' : searchFormValues.searchByMinimumAmount, 'maximumAmount' : searchFormValues.searchByMaximumAmount};
        StylistTransactionList(userId).then(res => initialResponse(res));
    },[currentPage, sortBy, sortDir, perPage]);

    const initialResponse = (response) => {
        if(response.data.statusCode === 200){
            setTransactionList(response.data.transactionList);
            setTransactionLoader(false);
            setListCount(response.data.filteredCount);
        }else{
             setTransactionLoader(false);
        }
    }

    //get UTC time to Local time
    const getUTCtoLocal = (onlyTime) => {
        var local_date= moment.utc(onlyTime).local().format("YYYY-MM-DD HH:mm");

        return local_date;
    }

    // search functionalities start here
    const searchOnClick = () => {
        setSearchStatus(true);
    }

    const clearSearchOnClick = () => {
        setSearchStatus(false);
        setSearchFormValues(searchInitialValues);
        setSearchErrors('');
        setCurrentPage(1);
        setListCount(0);
        setPerPage(10);
        setSortBy('completedBookingDate');
        setSortDir('desc');
        setClearbtnDisable(true);
        setTransactionLoader(true);

        const userId = {'clientId' : userData.clientId, 'shopName' : '', 'customerName' : '', 'startDate' : '', 'endDate' : '', 'pageNo' : 1, 'pageSize' : 10, 'sortColumn' : 'completedBookingDate', 'sortDirection' : 'desc', 'minimumAmount' : '', 'maximumAmount' : ''};
        StylistTransactionList(userId).then(res => initialResponse(res));
    }

    const searchHandleChange = (e) => {
        const value = e.target.value;
        const field= e.target.name;
        if(field === 'searchByMinimumAmount' || field === 'searchByMaximumAmount'){
            let priceLength = value.length;
            if(priceLength <= 5){
                setSearchFormValues(prevState => ({
                    ...prevState,
                    [field]:  value  
                }));
            }
        }else{
            setSearchFormValues(prevState => ({
                ...prevState,
                [field]:  value  
            }));
        }
    }

    const searchHandleSearch = (e) => {
        e.preventDefault();
        const validate = searchValidate(searchFormValues);
        setSearchErrors(validate.search);
        if(validate.status){
            setCurrentPage(1);
            setPerPage(10);
            setTransactionLoader(true);
            setClearbtnDisable(false);

            const userId = {'clientId' : userData.clientId, 'shopName' : searchFormValues.searchByBusinessName, 'customerName' : searchFormValues.searchByCustomerName, 'startDate' : searchFormValues.searchByStartDate, 'endDate' : searchFormValues.searchByEndDate, 'pageNo' : 1, 'pageSize' : 10, 'sortColumn' : sortBy, 'sortDirection' : sortDir, 'minimumAmount' : searchFormValues.searchByMinimumAmount, 'maximumAmount' : searchFormValues.searchByMaximumAmount};
            StylistTransactionList(userId).then(res => searchTransanctionResponse(res));
        }
    }

    const searchTransanctionResponse = (response) => {
        if(response.data.statusCode === 200){
            setTransactionList(response.data.transactionList);
            setTransactionLoader(false);
            setListCount(response.data.filteredCount);
        }else{
             setTransactionLoader(false);
        }
    }

    const searchValidate = (values) => {
        const errors = {};
        errors.status = true;
        let numbers = /^[0-9]+$/;
        let costStart = Number(values.searchByMinimumAmount);
        let costEnd = Number(values.searchByMaximumAmount);
        if((values.searchByCustomerName == '' || values.searchByCustomerName.trim() === '') && (values.searchByBusinessName == '' || values.searchByBusinessName.trim() === '') && (values.searchByStartDate == '' || values.searchByStartDate.trim() === '') && (values.searchByEndDate == '' || values.searchByEndDate.trim() === '') && (values.searchByMinimumAmount == '' || values.searchByMinimumAmount.trim() === '') && (values.searchByMaximumAmount == '' || values.searchByMaximumAmount.trim() === '')){
            errors.search = 'Provide any search term';
            errors.status = false;
        }

        if(values.searchByStartDate || values.searchByEndDate){
            if(values.searchByStartDate == '' || values.searchByEndDate == ''){
                errors.search = 'Transactions start date and end date are required';
                errors.status = false;
            }else if(values.searchByStartDate && values.searchByEndDate){
                let endDate = moment(values.searchByEndDate).format('YYYY-MM-DD');
                let StartDate = moment(values.searchByStartDate).format('YYYY-MM-DD');
    
                if(StartDate > endDate){
                    errors.search = 'Transactions end date cannot be less than transactions start date';
                    errors.status = false;
                }
            }
        }

        if(values.searchByMinimumAmount || values.searchByMaximumAmount){
            if(values.searchByMinimumAmount == '' || values.searchByMaximumAmount == ''){
                errors.search = 'Service cost Min. value and service cost Max. value are required';
                errors.status = false;
            }else if(values.searchByMinimumAmount && values.searchByMaximumAmount){
                if(costEnd == 0){
                    errors.search = 'Max. Value cannot be zero';
                    errors.status = false;
                }else if(costStart > costEnd){
                    errors.search = 'Max. Value cannot be higher than Min. Value';
                    errors.status = false;
                }else if(!values.searchByMinimumAmount.match(numbers) || !values.searchByMaximumAmount.match(numbers)){
                    errors.search='Enter valid cost';
                    errors.status=false;
                }
            }
        }

        const validations = {
            customerName: HtmlTagValidation(values.searchByCustomerName),
            businessName: HtmlTagValidation(values.searchByBusinessName),
        };

        Object.entries(validations).forEach(([key, value]) => {
            if (!value) {
                errors.search = 'Invalid input';
                errors.status = false;
            }
        });

        return errors;
    }

    const getBackCurrentPage = (page, perPage) => {
        setPerPage(perPage);
        setCurrentPage(page);
    }

    const sortingOnClick = (data) => {
        setSortBy(data.by);
        setSortDir(data.dir);
    }

    // get decimal two degit cost
    const getFullCost = (dollar) => {
        let roundCost = (Math.floor(dollar * 100) / 100).toFixed(2);
        return roundCost;
    }

    const BookingDetails = ( data ) => {
        navigate('../stylist/StylistSingleBookingDetails/'+data.bookingId);
    }

    return(
        <section className='dashboard'>
            <Container>
                <Row className='sideBarRow'>
                    <Col md={3} className='sideBarCol'>
                        <LeftSidebar/>
                    </Col>
                    <Col md={9}>
                        <div className='rightsidebar'>
                            <Topbar/>
                            <Row className="mb-3 main-title">
                                <Col>
                                    <h1>Transactions</h1>
                                </Col>
                            </Row>  
                            {
                                searchStatus == false ?
                                    <Row className="mb-3 managesearch">
                                        <Col>
                                            <Stack direction="horizontal" className="align-items-end" gap={2}>
                                                <div className="add-btn ms-auto search-status-btn">
                                                    <Button variant="primary" onClick={searchOnClick}>New Filter <FontAwesomeIcon icon={faFilter} /></Button>
                                                </div>
                                            </Stack>
                                        </Col>
                                    </Row>
                                :
                                    ''
                            }
                            <Row>
                                <Form onSubmit={searchHandleSearch}>
                                    <Row className='searchRow'>
                                        {
                                            searchStatus == false ? 
                                                ''
                                            :
                                                <>
                                                    <Col xl={12}> 
                                                        <Row>
                                                            <Col lg={4}>
                                                                <Row>
                                                                    <Col md={6}>
                                                                        <Form.Group as={Col} controlId="formGridSearchName">
                                                                            <Form.Label>Business Name</Form.Label>
                                                                            <Form.Control onKeyDown={BlockSQLInjection} type="text" name='searchByBusinessName' placeholder='Business name...' onChange={searchHandleChange}/>
                                                                        </Form.Group>
                                                                    </Col>
                                                                    <Col md={6}>
                                                                        <Form.Group as={Col} controlId="formGridSearchName">
                                                                            <Form.Label>Customer Name</Form.Label>
                                                                            <Form.Control onKeyDown={BlockSQLInjection} type="text" name='searchByCustomerName' placeholder='Customer name...' onChange={searchHandleChange}/>
                                                                        </Form.Group>
                                                                    </Col>
                                                                </Row>
                                                            </Col>
                                                            <Col lg={4}>
                                                                <Row>
                                                                    <Col md={6}>
                                                                        <Form.Group as={Col} controlId="formGridSearchName">
                                                                            <Form.Label>Start Date</Form.Label>
                                                                            <Form.Control type="date" name='searchByStartDate' onChange={searchHandleChange}/>
                                                                        </Form.Group>
                                                                    </Col>
                                                                    <Col md={6}>
                                                                        <Form.Group as={Col} controlId="formGridSearchName">
                                                                            <Form.Label>End Date</Form.Label>
                                                                            <Form.Control type="date" name='searchByEndDate' onChange={searchHandleChange}/>
                                                                        </Form.Group>
                                                                    </Col>
                                                                </Row>
                                                            </Col>
                                                            <Col lg={4}>
                                                                <Row>
                                                                    <Col md={6}>
                                                                        <Form.Group as={Col} controlId="formGridSearchName">
                                                                            <Form.Label>Min. Value (in $)</Form.Label>
                                                                            <Form.Control onKeyDown={BlockSQLInjection} type="number" placeholder='Service cost...' name='searchByMinimumAmount' onChange={searchHandleChange} value={searchFormValues.searchByMinimumAmount}/>
                                                                        </Form.Group>
                                                                    </Col>
                                                                    <Col md={6}>
                                                                        <Form.Group as={Col} controlId="formGridSearchName">
                                                                            <Form.Label>Max. Value (in $)</Form.Label>
                                                                            <Form.Control onKeyDown={BlockSQLInjection} type="number" placeholder='Service cost...' name='searchByMaximumAmount' onChange={searchHandleChange} value={searchFormValues.searchByMaximumAmount}/>
                                                                        </Form.Group>
                                                                    </Col>
                                                                </Row>
                                                            </Col>
                                                        </Row>
                                                    </Col>
                                                    <Col xl={12} className="searcchAllBtn">
                                                        <Button variant="primary" type='submit'>Search</Button>
                                                        <Button variant="danger" disabled={clearbtnDisable} onClick={clearSearchOnClick} className='search-form-clear-btn'>Clear Filter <FontAwesomeIcon icon={faFilterCircleXmark} /></Button>
                                                    </Col>
                                                </> 
                                        }
                                        <span className='error'>{searchErrors}</span>
                                    </Row>
                                </Form>
                            </Row>
                            <Row>
                                <Col>
                                    <div className="table-responsive" id="no-more-tables">
                                        <Table striped bordered hover variant="dark">
                                            <thead>
                                                <tr>
                                                    <th>S. No</th>
                                                    <th>
                                                        Business<br></br> Name
                                                        {
                                                            listCount > 0 ?
                                                                <span className='sort'> {sortBy == 'shopName' ? sortDir == 'asc' ? <FontAwesomeIcon icon={faArrowDownZA}  onClick={() => sortingOnClick({by : 'shopName', dir : 'desc'})}/> : <FontAwesomeIcon icon={faArrowDownAZ} onClick={() => sortingOnClick({by : 'shopName', dir : 'asc'})}/> : <FontAwesomeIcon icon={faArrowDownAZ} onClick={() => sortingOnClick({by : 'shopName', dir : 'asc'})}/>}</span>
                                                            :
                                                            ''
                                                        }
                                                    </th>
                                                    <th>
                                                        Customer<br></br> Name
                                                        {
                                                            listCount > 0 ?
                                                                <span className='sort'> {sortBy == 'customerName' ? sortDir == 'asc' ? <FontAwesomeIcon icon={faArrowDownZA}  onClick={() => sortingOnClick({by : 'customerName', dir : 'desc'})}/> : <FontAwesomeIcon icon={faArrowDownAZ} onClick={() => sortingOnClick({by : 'customerName', dir : 'asc'})}/> : <FontAwesomeIcon icon={faArrowDownAZ} onClick={() => sortingOnClick({by : 'customerName', dir : 'asc'})}/>}</span>
                                                            :
                                                            ''
                                                        }
                                                    </th>
                                                    <th>
                                                        Check Out Date
                                                        {
                                                            listCount > 0 ?
                                                                <span className='sort'> {sortBy == 'completedBookingDate' ? sortDir == 'asc' ? <FontAwesomeIcon icon={faArrowDownZA}  onClick={() => sortingOnClick({by : 'completedBookingDate', dir : 'desc'})}/> : <FontAwesomeIcon icon={faArrowDownAZ} onClick={() => sortingOnClick({by : 'completedBookingDate', dir : 'asc'})}/> : <FontAwesomeIcon icon={faArrowDownAZ} onClick={() => sortingOnClick({by : 'completedBookingDate', dir : 'asc'})}/>}</span>
                                                            :
                                                            ''
                                                        }
                                                    </th>
                                                    <th>Time</th>
                                                    <th>Amount</th>
                                                    <th>Service<br></br> Offered</th>
                                                    <th>Booked / <br></br>Edited By</th>
                                                    <th>Booking<br></br> Status</th>
                                                    <th>Payment<br></br> Status</th>
                                                    <th>Booking<br></br> Details</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                            {
                                                transactionLoader === true ?
                                                    <tr>
                                                        <td colSpan='11' style={{textAlign : 'center', paddingLeft : '0px'}}><InfinitySpin height="80" width="200" radius="9" color="#D49D29" ariaLabel="loading" wrapperStyle wrapperClass /></td>
                                                    </tr>
                                                :
                                                    transactionList.length > 0 ?
                                                        transactionList.map((data, index) => (
                                                            <tr key={index}>
                                                                <td className='table-row-number'>{index + 1 + (currentPage * perPage) - perPage}</td>
                                                                <td data-title="Business Name">{data.shopName}</td>
                                                                <td data-title="Customer Name">{data.customerName}</td>
                                                                {/* <td data-title="Date">{moment(getUTCtoLocal(data.completedDateTime)).format('MM-DD-YYYY')}</td>
                                                                <td data-title="Time">{moment(getUTCtoLocal(data.completedDateTime)).format('hh:mm A')}</td> */}
                                                                <td data-title="Check Out Date">{moment(data.settlementLocalDateTime).format('MM-DD-YYYY')}</td>
                                                                <td data-title="Time">{moment(data.settlementLocalDateTime).format('hh:mm A')}</td>
                                                                <td data-title="Amount">${data.receivedPayment.toFixed(2)}</td>
                                                                <td data-title="Service Offered">{data.subService}</td>
                                                                <td data-title="Booked By">{data.bookingType == 'CS' ? 'Customer' : (data.bookingType == 'CL' || data.bookingType == 'CLEB') ? 'Professional' : data.bookingType == 'CLSB' ? 'Self Booking' : ''}</td>
                                                                <td data-title="Booking Status">
                                                                    {
                                                                        data.status === 'Completed' ?
                                                                            <Button variant="success" className="stylist-trans-btn success-btn">{data.status}</Button>
                                                                        : 
                                                                            data.status === 'Cancelled' ?
                                                                                <Button variant="danger" className="stylist-trans-btn danger-btn">{data.status}</Button>
                                                                            : 
                                                                                data.status === 'Booked' ?
                                                                                    <Button variant="success" className="stylist-trans-btn success-btn">{data.status}</Button>
                                                                                :
                                                                                    <Button variant="warning" className="stylist-trans-btn warning-btn">{data.status}</Button>
                                                                    }
                                                                </td>
                                                                <td data-title="Payment Status">{data.paymentStatus == '' ? 'N/A' : data.paymentStatus}</td>
                                                                <td data-title="Booking History" className='bookhistory-btn'>
                                                                    <Button variant="primary" onClick={() => BookingDetails(data)}>View</Button>
                                                                </td>
                                                            </tr>
                                                        ))
                                                    :
                                                    <tr>
                                                        <td colSpan='11' style={{textAlign : 'center'}} className='noDataFound'>No Data Found...</td>
                                                    </tr>
                                                }
                                            </tbody>
                                        </Table>
                                    </div>
                                </Col>
                            </Row>  
                            {
                                listCount > 0 ?
                                    <PaginationDesign currentPage={currentPage} listCount={listCount} perPage={perPage} getBack={getBackCurrentPage}/>
                                : 
                                    ''
                            }
                        </div>
                    </Col>
                </Row>
            </Container>
        </section>
    )
} 