import {React, useState, useEffect, useRef} from 'react';
import { Container, Row, Col, Stack, Button, Form } from "react-bootstrap";
import { Link, useNavigate } from "react-router-dom";
import { doCustomerLogin, postResendVerificationEmail } from '../../../Service';
import { setCustomerUserData, setCustomerToken } from '../../../reducers/User/action';
import { useDispatch, useSelector} from 'react-redux';
import Swal from "sweetalert2";
import { InfinitySpin } from 'react-loader-spinner';
import { setBookingUrl } from '../../../reducers/User/action';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEye, faEyeSlash } from "@fortawesome/free-solid-svg-icons";
import ReCAPTCHA from "react-google-recaptcha";
import { HtmlTagValidation, BlockSQLInjection} from '../../Reusable';
import { JsonEncode, JsonDecode } from '../../Reusable/JsonModify';

export default function Login() {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const recaptcha = useRef();

    // state declaration
    const initialValues = { email: "", password: "" };
    const [formValues, setFormValues] = useState(initialValues);
    const [formErrors, setFormErrors] = useState({});
    const [loader, setLoader] = useState(false);
    const [passEye, setPassEye] = useState(false);
    const navigateBack = useSelector(store => store.user.bookingurl);

    // get customer privacy preference data from store
    const privacyStatus =  useSelector(store => store.user['privacypreference']);

    // get customer data from store
    const userData =  useSelector(store => store.user['customeruser']);
    const customerLogoutUrl = useSelector(store => store.user.customerLogoutUrl);

    useEffect(() => {

        if ( privacyStatus === true ) {
            var btn1 = document.getElementById("termsfeed_pc1_sticky_dnsmpi_element_open");
            var btn2 = document.getElementById("termsfeed_pc1_sticky_element_open");

            if ( btn1 && btn2 ) {
                btn1.classList.remove('ppbtnhide');
                btn2.classList.remove('ppbtnhide');
            }

        } else if ( privacyStatus === false ) {
            var btn1 = document.getElementById("termsfeed_pc1_sticky_dnsmpi_element_open");
            var btn2 = document.getElementById("termsfeed_pc1_sticky_element_open");

            if ( btn1 && btn2 ) {
                btn1.classList.add('ppbtnhide');
                btn2.classList.add('ppbtnhide');
            }

        }

        if ( userData != null ) {
            navigate('/');
        }

    }, [])

    // input field handle change
    const hanldeChange = ( e ) => {
        const value=e.target.value;
        const field=e.target.name;
        setFormValues(prevState => ({
            ...prevState,
            [field]:  value  
        }));
    }

    // login onsubmit
    const handleSubmit = async ( e ) => {
        e.preventDefault();
        const errorCount = validate(formValues);
        setFormErrors(errorCount);

        if ( errorCount.status ) {
            // formValues['reCaptchaToken'] = recaptcha.current.getValue();
            
            let jsonData = await JsonEncode(formValues).then(result => {
                return result
            }).catch(error => {
                Swal.fire({
                    position: 'center',
                    icon: 'error',
                    title: 'Oops...',
                    showConfirmButton: false,
                    text: error,
                    timer: 3000
                }); 
            });
            
            if ( jsonData ) {
                let loginPayload = {data : jsonData};
                doCustomerLogin(loginPayload).then(res => handleResponse(res));
                setLoader(true);
            } else {
                Swal.fire({
                    position: 'center',
                    icon: 'error',
                    title: 'Oops...',
                    showConfirmButton: false,
                    text: 'Something went wrong, Please try after sometimes',
                    timer: 3000
                }); 
            }

        }

    }

    // login api response
    const handleResponse = async ( response ) => {
        let decryptData = await JsonDecode(response.data.data).then(result => {
            return result
        }).catch(error => {
            Swal.fire({
                position: 'center',
                icon: 'error',
                title: 'Oops...',
                showConfirmButton: false,
                text: error,
                timer: 3000
            }); 
        });

        if ( decryptData.statusCode === 200 ) {
            dispatch(setCustomerToken(decryptData.jwtToken));
            const userData = { id : decryptData.customerId, email : decryptData.emailAddress, firstName : decryptData.firstName, lastName : decryptData.lastName, gender : decryptData.gender, phone : decryptData.phoneNumber };
            dispatch(setCustomerUserData(userData));
            setLoader(false);

            if ( !navigateBack ) {

                if ( customerLogoutUrl === undefined || customerLogoutUrl === null ) {
                    navigate('/customer/home');
                } else {

                    if ( customerLogoutUrl === '/customer/settings' || customerLogoutUrl === '/customer/bookinghistory' ) {
                        navigate(customerLogoutUrl);
                    } else {
                        navigate('/customer/home');
                    }

                }

            }

            if ( navigateBack ) {
                navigate(-1);
                dispatch(setBookingUrl(false));
            }

        } else if( decryptData.statusCode === 400 ) {
            Swal.fire({
                position: 'center',
                icon: 'error',
                title: 'Oops...',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                confirmButtonText: 'Resend',
                text: "Your email has not yet verified. To resend the verification email, click on Resend button",
            }).then((result) => {

                if ( result.isConfirmed ) {
                    let resendParams = {'email' : formValues.email, 'userType' : 'Customer'};
                    postResendVerificationEmail(resendParams).then(res => resendVerificationEmailResponse(res));
                }

            })
            setFormValues(formValues);
            setLoader(false);
            // recaptcha.current.reset();
        } else {
            // recaptcha.current.reset();
            Swal.fire({
                position: 'center',
                icon: 'error',
                title: 'Oops...',
                showConfirmButton: false,
                text: decryptData.statusMessage ? decryptData.statusMessage : 'Something went wrong, Please try after sometimes.',
                timer: 3000
            }); 
            setFormValues(formValues);
            setLoader(false);
        }  

    }

    const resendVerificationEmailResponse = ( res ) => {

        if ( res.data.statusCode === 200 ) {
            Swal.fire({
                position: 'center',
                icon: 'success',
                title: 'Email send successfully',
                showConfirmButton: false,
                timer: 3000
            });
        } else {
            Swal.fire({
                position: 'center',
                icon: 'error',
                title: 'Oops...',
                showConfirmButton: false,
                text: res.data.statusMessage,
                timer: 3000
            }); 
        }

    }

    // input field validation
    const validate = ( values ) => {
        const errors = {};
        const regex = /^[^\s@]+@[^\s@]+\.[^\s@]{2,}$/i;
        errors.status = true;

        if ( !values.email ) {
            errors.email = 'Email is required';
            errors.status = false;
        } else if ( !regex.test(values.email) ) {
            errors.email = "This is not a valid email format!";
            errors.status = false;
        }

        if ( !values.password ) {
            errors.password = 'Password is required';
            errors.status = false;
        }

        // if ( recaptcha.current.getValue() === '' ) {
        //     errors.captcha = 'Captcha is required';
        //     errors.status = false;
        // }

        const validations = {
            email: HtmlTagValidation(values.email),
            password: HtmlTagValidation(values.password)
        };

        Object.entries(validations).forEach(([key, value]) => {

            if ( !value ) {
                errors[key] = 'Invalid input';
                errors.status = false;
            }

        });
        
        return errors;
    }

    // show password icon onclick
    const confrimPassView = () => {
        setPassEye(prevState => !prevState);
    }

    return(
        
        // Customer login form HTML start here
        <section className='csm-bg '>
            
            <Container className='login-bg'>
               <Row className="justify-content-md-center csm-login">
                <Col md="2"></Col>
                    <Col md="4">
                        <div className='text-center'>
                            <Link to="../customer" >
                                <img src='images/logo.png'/>
                            </Link>
                        </div>

                        <div className='stl-nm'>
                        <div className='left'>                  
                        <h1 className="text-center">Customer <br></br> <span>Login</span></h1>
                        </div>
                        <div className='right'>                    
                        <img src='images/customer-mb.png' className='img-res'/>
                        </div>
                        </div>


                       
                        <Form onSubmit={handleSubmit} >
                            <Form.Group className="mb-3" controlId="formBasicEmail">
                                <Form.Label>Email <span>*</span></Form.Label>
                                <Form.Control autoComplete="off" onKeyDown={BlockSQLInjection} type="email" placeholder="" name="email" defalutValue={formValues.email} onChange={hanldeChange} />
                                <p className="error">{formErrors.email}</p>
                            </Form.Group>
                            <Form.Group className="mb-3 passwordRow" controlId="formBasicPassword">
                                <Form.Label>Password <span>*</span></Form.Label>
                                <FontAwesomeIcon icon={passEye === false ? faEye : faEyeSlash} className='passwordEye' onClick={confrimPassView}/>
                                <Form.Control autoComplete="off" onKeyDown={BlockSQLInjection} type={passEye === false ? 'password' : 'text'} placeholder="" name="password" defalutValue={formValues.password} onChange={hanldeChange}/>
                                <p className="error">{formErrors.password}{formErrors.invalid}</p>
                            </Form.Group>
                            {/* <Form.Group as={Col} controlId="formCaptchaLoad">
                                <Form.Label>Captcha <span>*</span></Form.Label>
                                <ReCAPTCHA size="normal" ref={recaptcha} sitekey={process.env.REACT_APP_SITE_KEY} />
                                <p className="error">{formErrors.captcha}</p>
                            </Form.Group> */}
                            <Stack gap={3} className="mx-autos">
                                <div style={{textAlign : 'right'}}>
                                    <Link to="/customerforgotpassword" className="text-end forgot" style={{"text-decoration": "none"}}>Forgot Password?</Link>
                                </div>
                                {
                                    loader === true ? 
                                        <div style={{textAlign : 'center'}}>
                                            <InfinitySpin height="80" width="200" radius="9" color="#D49D29" ariaLabel="loading" wrapperStyle wrapperClass />
                                        </div>
                                    :
                                        <Button onClick={handleSubmit} type='submit'  variant="secondary">Login <img src='images/arrow-right.svg'/></Button>
                                }
                                <div className="text-center backto">New customer? <Link to="/customercreateanaccount" className="forgot" style={{"text-decoration": "none"}}>Create an account</Link></div>
                            </Stack>
                        </Form>
                    </Col>
                    <Col md="6">
                         
                    </Col>
                </Row>
            </Container>
        </section>
    )
} 