import {React, useEffect, useState} from 'react';
import { Container, Row, Col, Image, Card } from "react-bootstrap";
import Topbar from '../Assets/Topbar';
import LeftSidebar from '../Assets/LeftSidebar';
import { getAdminDashboard } from '../../../Service';
import { InfinitySpin } from 'react-loader-spinner';

export default function AdminDashboard() {
    // state declaration
    const [list, setList] = useState({stylistCount : 0, customerCount : 0, totalMonthRevenue : 0, totalYearRevenue : 0});
    const [loader, setLoader] = useState(true);

    // get dashboard data's from api
    useEffect(() => {
        getAdminDashboard().then(res => handleResponse(res))
    },[]); 

    // api response
    const handleResponse = (res) => {
        if(res.data.statusCode === 200){
            setLoader(false);
            setList({stylistCount : res.data.stylistCount, customerCount : res.data.customerCount, totalMonthRevenue : res.data.totalMonthRevenue, totalYearRevenue : res.data.totalYearRevenue});
        }
    }

    // get decimal two degit cost
    const getFullCost = (dollar) => {
        let roundCost = (Math.floor(dollar * 100) / 100).toFixed(2);
        return roundCost;
    }

    return(
        <section className='dashboard'>
            <Container>
                <Row className='sideBarRow'> 
                    <Col md={3} className='sideBarCol'>
                        <LeftSidebar/>
                    </Col>
                    <Col md={9} style={{position : 'relative'}}>
                        <div className='rightsidebar tab-view'>
                            <Topbar/>
                            <Row className="main-title">
                                <Col>
                                    <h1>Dashboard</h1>
                                </Col>
                            </Row>
                            {
                                loader === true ? 
                                    <Row xs={1} md={1} style={{marginTop : '24px'}}>
                                        <div style={{textAlign : 'center'}}>
                                              <InfinitySpin height="80" width="200" radius="9" color="#D49D29" ariaLabel="loading" wrapperStyle wrapperClass />
                                        </div>
                                    </Row>
                                :
                                    <Row xs={1} md={4} className="g-4">
                                        <Col className='dashboard-col'>
                                            <Card className="text-center" >
                                                <Card.Text>Professionals</Card.Text>
                                                <div className='circle-blue'>
                                                    <Image variant="top" src='../images/stylist.png' className="mb-4 " />
                                                </div>
                                                <Card.Title>{list.stylistCount}</Card.Title>
                                                <Card.Text className='total'>Total Active Professionals</Card.Text>
                                            </Card>
                                        </Col>
                                        <Col className='dashboard-col'>
                                            <Card className="text-center" >
                                                <Card.Text>Customers</Card.Text>
                                                <div className='circle-red'>
                                                    <Image variant="top" src='../images/clients.png'  />
                                                </div>
                                                <Card.Title>{list.customerCount}</Card.Title>
                                                <Card.Text className='total'>Total Customers</Card.Text>
                                            </Card>
                                        </Col>
                                        <Col className='dashboard-col'>
                                            <Card className="text-center" >
                                                <Card.Text>Month</Card.Text>
                                                <div className='circle-violet'>
                                                    <Image variant="top" src='../images/month.png' className="mb-4" />
                                                </div>
                                                <Card.Title>${list.totalMonthRevenue.toFixed(2)}</Card.Title>
                                                <Card.Text className='total'>Total Revenue</Card.Text>
                                            </Card>
                                        </Col>
                                        <Col className='dashboard-col'>
                                            <Card className="text-center" >
                                                <Card.Text>Year to date</Card.Text>
                                                <div className='circle-pink'>
                                                    <Image variant="top" src='../images/month.png' className="mb-4" />
                                                </div>
                                                <Card.Title>${list.totalYearRevenue.toFixed(2)}</Card.Title>
                                                <Card.Text className='total'>Total Revenue</Card.Text>
                                            </Card>
                                        </Col>
                                    </Row>
                            }
                        </div>
                    </Col>
                </Row>
            </Container>
        </section>
    )
} 