import { React, useState, useEffect } from 'react';
import { Container, Row, Col, Stack, Button, Table, Form } from "react-bootstrap";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowDownAZ, faArrowDownZA, faFilter, faFilterCircleXmark } from '@fortawesome/free-solid-svg-icons';
import Topbar from '../Assets/Topbar';
import LeftSidebar from '../Assets/LeftSidebar';
import { useSelector } from 'react-redux';
import { getStylistCustomerList } from '../../../Service';
import { InfinitySpin } from 'react-loader-spinner';
import moment from 'moment';
import moments from 'moment-timezone';
import { useNavigate } from 'react-router-dom';
import PaginationDesign from '../Assets/Pagination';
import { HtmlTagValidation, BlockSQLInjection } from '../../Reusable';

export default function ManageStylist() {
    // inital state declaration
    const navigate = useNavigate();

    const [customerList, setCustomerList] = useState([]);
    const [customerLoader, setCustomerLoader] = useState(true);
    const searchInitialValues = {searchByName : '', searchByEmail : ''};
    const [searchFormValues, setSearchFormValues] = useState(searchInitialValues);
    const [searchStatus, setSearchStatus] = useState(false);
    const [searchErrors ,setSearchErrors] = useState('');
    const [currentPage, setCurrentPage] = useState(1);
    const [listCount, setListCount] = useState(0);
    const [perPage, setPerPage] = useState(10);
    const [sortBy, setSortBy] = useState('name');
    const [sortDir, setSortDir] = useState('asc');
    const [searchBTNStatus, setSearchBTNStatus] = useState(false);
    const [clearbtnDisable, setClearbtnDisable] = useState(true);

    // get customer data from store
    const userData =  useSelector(store => store.user['stylistuser']);

    // booking popup open function
    const handleShow = (data) => {
        navigate('../stylist/CustomerBookingDetails/'+data.customerId)
    }

    // get client data by userid
    useEffect(() => {
        setCustomerLoader(true);
        const userId = {'clientId' : userData ? userData.clientId : '', customerName : searchFormValues.searchByName.trim(), customerEmail : searchFormValues.searchByEmail.trim(), pageSize : perPage, pageNumber : currentPage, sortColumn : sortBy, sortDirection : sortDir};
        getStylistCustomerList(userId).then(res => initialAPIResponse(res));
    },[currentPage, sortBy, sortDir, perPage]);

    const initialAPIResponse = (response) => {
        if(response.data.statusCode === 200) {
            setCustomerList(response.data.clientList);
            setCustomerLoader(false);
            setListCount(response.data.filteredCount);
            setSearchBTNStatus(false);
        }else{
            setCustomerLoader(false);
        }
    }

    // search functionalities start here
    const searchOnClick = () => {
        setSearchStatus(true);
    }

    const clearSearchOnClick = () => {
        setSearchStatus(false);
        setSearchFormValues(searchInitialValues);
        setSearchErrors('');
        setCurrentPage(1);
        setListCount(0);
        setPerPage(10);
        setSortBy('name');
        setSortDir('asc');
        setClearbtnDisable(true);
        
        setCustomerLoader(true);
        const userId = {'clientId' : userData ? userData.clientId : '', 'customerName' : '', 'customerEmail' : '', 'pageSize' : 10, 'pageNumber' : 1, 'sortColumn' : 'name', 'sortDirection' : 'asc'};
        getStylistCustomerList(userId).then(res => initialAPIResponse(res));
    }

    const searchHandleChange = (e) => {
        const value = e.target.value;
        const field= e.target.name;
        setSearchFormValues(prevState => ({
            ...prevState,
            [field]:  value  
        }));
    }

    const searchHandleSearch = (e) => {
        e.preventDefault();
        const validate = searchValidate(searchFormValues);
        setSearchErrors(validate.search);
        if(validate.status){
            setCurrentPage(1);
            setPerPage(10);
            setSortBy('name');
            setSortDir('asc');
            setCustomerLoader(true);
            setSearchBTNStatus(true);
            setClearbtnDisable(false);

            const userId = {'clientId' : userData ? userData.clientId : '', customerName : searchFormValues.searchByName.trim(), customerEmail : searchFormValues.searchByEmail.trim(), pageSize : 10, pageNumber : 1, sortColumn : 'name', sortDirection : 'asc'};
            getStylistCustomerList(userId).then(res => searchAPIResponse(res));
        }
    }

    const searchAPIResponse = (response) => {
        if(response.data.statusCode === 200) {
            setCustomerList(response.data.clientList);
            setCustomerLoader(false);
            setListCount(response.data.filteredCount);
            setSearchBTNStatus(false);
        }else{
            setCustomerLoader(false);
        }
    }

    const searchValidate = (values) => {
        const errors = {};
        errors.status = true;
        
        if((values.searchByName === '' || values.searchByName.trim() === '') && (values.searchByEmail === '' || values.searchByEmail.trim() === '')){
            errors.search = 'Provide any search term';
            errors.status = false;
        }

        const validations = {
            customerName: HtmlTagValidation(values.searchByName),
            customerEmail: HtmlTagValidation(values.searchByEmail)
        };

        Object.entries(validations).forEach(([key, value]) => {
            if (!value) {
                errors.search = 'Invalid input';
                errors.status = false;
            }
        });

        return errors;
    }

    const getBackCurrentPage = (page, perPage) => {
        setPerPage(perPage);
        setCurrentPage(page);
    }

    const sortingOnClick = (data) => {
        setSortBy(data.by);
        setSortDir(data.dir);
    }
  
    return(
        <section className='dashboard'>
            <Container>
                <Row className='sideBarRow'>
                    <Col md={3} className='sideBarCol'>
                        <LeftSidebar/>
                    </Col>
                    <Col md={9}>
                        <div className='rightsidebar'>
                            <Topbar/>
                            <Row className="mb-3 main-title">
                                <Col>
                                    <h1>Customer List</h1>
                                </Col>
                            </Row> 
                            {
                                searchStatus == false ?
                                    <Row className="mb-3 managesearch">
                                        <Col>
                                            <Stack direction="horizontal" className="align-items-end" gap={2}>
                                                <div className="add-btn ms-auto search-status-btn">
                                                    <Button variant="primary" onClick={searchOnClick}>New Filter <FontAwesomeIcon icon={faFilter} /></Button>
                                                </div>
                                            </Stack>
                                        </Col>
                                    </Row> 
                                :
                                    ''
                            }
                            <Row>
                                <Form onSubmit={searchHandleSearch}>
                                    <Row className='searchRow'>
                                        {
                                            searchStatus == true ? 
                                                <>
                                                    <Col xl={8}>
                                                        <Row>
                                                            <Col md={6}>
                                                                <Form.Group as={Col} controlId="formGridSearchName">
                                                                    <Form.Label>Customer Name</Form.Label>
                                                                    <Form.Control onKeyDown={BlockSQLInjection} type="text" name='searchByName' placeholder='Customer name...' onChange={searchHandleChange}/>
                                                                </Form.Group>
                                                            </Col>
                                                            <Col md={6}>
                                                                <Form.Group as={Col} controlId="formGridSearchName">
                                                                    <Form.Label>Customer Email</Form.Label>
                                                                    <Form.Control onKeyDown={BlockSQLInjection} type="text" name='searchByEmail' placeholder='Customer email...' onChange={searchHandleChange}/>
                                                                </Form.Group>
                                                            </Col>
                                                        </Row>
                                                    </Col>
                                                    <Col xl={4} className="searcchAllBtn">
                                                        <Button variant="primary" type='submit' disabled={searchBTNStatus}>Search</Button>
                                                        <Button variant="danger" disabled={clearbtnDisable} onClick={clearSearchOnClick} className='search-form-clear-btn'>Clear Filter <FontAwesomeIcon icon={faFilterCircleXmark} /></Button>
                                                    </Col>
                                                    <span className='error'>{searchErrors}</span>
                                                </>
                                            :
                                                ''
                                        }
                                    </Row>
                                </Form>
                            </Row>
                            <Row>
                                <Col >
                                    <div className="table-responsive" id="no-more-tables">
                                        <Table striped bordered hover variant="dark" className='emailwrap'>
                                            <thead>
                                                <tr>
                                                    <th>S. No</th>
                                                    <th>
                                                        Customer Name 
                                                        {
                                                            listCount > 0 ?
                                                                <span className='sort'> {sortBy == 'name' ? sortDir == 'asc' ? <FontAwesomeIcon icon={faArrowDownZA}  onClick={() => sortingOnClick({by : 'name', dir : 'desc'})}/> : <FontAwesomeIcon icon={faArrowDownAZ} onClick={() => sortingOnClick({by : 'name', dir : 'asc'})}/> : <FontAwesomeIcon icon={faArrowDownAZ} onClick={() => sortingOnClick({by : 'name', dir : 'asc'})}/>}</span>
                                                            :
                                                                ''
                                                        }
                                                    </th>
                                                    <th>Email</th>
                                                    <th>Phone</th>
                                                    <th>Booking History</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {
                                                    customerLoader === true ?
                                                        <tr>
                                                            <td colSpan='5' style={{textAlign : 'center', paddingLeft : '0px'}}><InfinitySpin height="80" width="200" radius="9" color="#D49D29" ariaLabel="loading" wrapperStyle wrapperClass /></td>
                                                        </tr>
                                                    :
                                                        customerList.length > 0 ? 
                                                            customerList.map((data, index) => (
                                                                <tr key={index}>
                                                                    <td className='table-row-number'>{index + 1 + (currentPage * perPage) - perPage}</td>
                                                                    <td data-title="Customer Name">{data.firstName +' '+ data.lastName}</td>
                                                                    <td data-title="Email" className='mobile-email'><a style={{textDecoration : 'none', color : '#fff'}} href={'mailto:'+data.email}>{data.email}</a></td>
                                                                    <td data-title="Phone"><a  style={{textDecoration : 'none', color : '#fff'}} href={'tel:'+data.phone} >{data.phone}</a></td>
                                                                    <td data-title="Booking History" className='bookhistory-btn'>
                                                                        <Button variant="primary" onClick={() => handleShow(data)}>View</Button>
                                                                    </td>
                                                                </tr>
                                                            ))
                                                        : 
                                                            <tr>
                                                                <td colSpan='5' style={{textAlign : 'center'}} className='noDataFound'>No Data Found...</td>
                                                            </tr>
                                                }
                                            </tbody>
                                        </Table>
                                    </div>
                                </Col>    
                            </Row>
                            {
                                listCount > 0 ?
                                    <PaginationDesign currentPage={currentPage} listCount={listCount} perPage={perPage} getBack={getBackCurrentPage}/>
                                :   
                                    ''
                            }
                        </div>
                    </Col>
                </Row>
            </Container>
        </section>
    )
} 