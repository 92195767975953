import { useState } from "react"
import ReactCrop from "react-image-crop"
import "react-image-crop/dist/ReactCrop.css"
import { Row, Button, Modal, Form } from "react-bootstrap";
import { profileImageUpdate, logoImageUpdate } from '../../../../../Service';
import { useSelector } from 'react-redux';
import Swal from "sweetalert2";
import { InfinitySpin } from 'react-loader-spinner';
import { useDispatch } from 'react-redux';
import { setStylistUserData } from "../../../../../reducers/User/action";

const ImageCropper = ({ whichCropImage, fileName, imageToCrop, getBack }) => {

    const dispatch = useDispatch();

    const [crop, setCrop] = useState({
        maxHeight: 800,
        maxWidth: 400
    })

    const [uploadLoader, setUploadLoader] = useState(false)
    const [image, setImage] = useState(null);
    const [show, setShow] = useState(true);
    const [cropError, setCropError] = useState('');
    const whereToCrop = whichCropImage;
    
    // get customer data from store
    const userData =  useSelector(store => store.user['stylistuser']);

    const cropImageNow = () => {
        if(crop.width != 0 && crop.height != 0) {
            const canvas = document.createElement("canvas")
            const scaleX = image.naturalWidth / image.width
            const scaleY = image.naturalHeight / image.height
            canvas.width = crop.width
            canvas.height = crop.height
            const ctx = canvas.getContext("2d")

            const pixelRatio = window.devicePixelRatio
            canvas.width = crop.width * pixelRatio
            canvas.height = crop.height * pixelRatio
            ctx.setTransform(pixelRatio, 0, 0, pixelRatio, 0, 0)
            ctx.imageSmoothingQuality = "high"

            ctx.drawImage(
                image,
                crop.x * scaleX,
                crop.y * scaleY,
                crop.width * scaleX,
                crop.height * scaleY,
                0,
                0,
                crop.width,
                crop.height
            )

            const base64Image = canvas.toDataURL("image/jpeg");
            var filebase64 = '';
            var uploadfileName = '';
            if(base64Image){
                filebase64 = base64Image.replace("data:", "").replace(/^.+,/, "");
                uploadfileName = fileName;
                let fileEntentionOfCropImg = uploadfileName.split('.').pop();
                if(whereToCrop === 'Profile'){
                    let fileNameResult = 'profileImage-'+userData.clientId+'.'+fileEntentionOfCropImg;
                    const profileUploadData = {'imageName' : fileNameResult, 'imageStr' : filebase64, 'clientId' : userData.clientId};
                    profileImageUpdate(profileUploadData).then(res => updateImageResponse(res, true));
                }else if(whereToCrop === 'Logo'){
                    let fileNameResult = 'LogoImage-'+userData.clientId+'.'+fileEntentionOfCropImg;
                    const logoUploadData = {'imageName' : fileNameResult, 'imageStr' : filebase64, 'clientId' : userData.clientId};
                    logoImageUpdate(logoUploadData).then(res => updateImageResponse(res, false));
                }
            }
            setUploadLoader(true);
        }else{
            setCropError(whereToCrop + ' image should be cropped');
        }
    }

    const updateImageResponse = (res, isImage) => {
        if(res.data.statusCode == 200){
            Swal.fire({
                position: 'center',
                icon: 'success',
                title: res.data.statusMessage,
                showConfirmButton: false,
                timer: 3000
            });

            if ( isImage ) {
                const setUpdatedUserData = { shopName:userData.shopName, shopId:userData.shopId, professionName:userData.professionName, professionId:userData.professionId, firstName:userData.firstName, lastName:userData.lastName, address1:userData.address1, address2:userData.address2, city:userData.city, county:userData.county, state:userData.state, zipCode:userData.zipCode, clientId:userData.clientId, emailAddress:userData.emailAddress, phoneNumber:userData.phoneNumber, profileImage:res.data.imageUrl, userType:userData.userType };

                dispatch(setStylistUserData(setUpdatedUserData));
            }

            getBack(true);
        }else{
            Swal.fire({
                position: 'center',
                icon: 'error',
                title: 'Oops...',
                showConfirmButton: false,
                text: res.data.statusMessage,
                timer: 3000
            });
        }
        setUploadLoader(false);
    }

    const handleClose = () => {
        setShow(false);
        getBack(false);
    }

    return (
        <div>
            <div>
                {imageToCrop && (
                    <Modal className="custom-modal croperModel" show={show} onHide={handleClose}>
                        <Form>
                            <Modal.Header closeButton>
                                <Modal.Title>{'Crop ' +whereToCrop+' Image'}</Modal.Title>
                            </Modal.Header>
                            <Modal.Body>
                                <Row>
                                    <p className='payoutValid' style={{padding : 0}}>Note: <span>Click and drag anywhere on the photo</span></p>
                                    <ReactCrop
                                            src={imageToCrop}
                                            onImageLoaded={setImage}
                                            maxHeight={400}
                                            maxWidth={400}
                                            crop={crop}
                                            onChange={setCrop}
                                            style={{padding : '0'}}
                                            circularCrop={true}
                                            ruleOfThirds={true}
                                            // keepSelection={true}
                                    />
                                    <p className="error" style={{padding : '0'}}>{cropError}</p>
                                </Row>
                            </Modal.Body>
                            <Modal.Footer>
                                {
                                    uploadLoader === true ?
                                        <InfinitySpin height="80" width="200" radius="9" color="#D49D29" ariaLabel="loading" wrapperStyle wrapperClass />
                                    :
                                        <>
                                            <Button variant="btn btn-primary" onClick={cropImageNow}>Upload</Button>
                                            <Button variant="outline-secondary" onClick={handleClose}>Cancel</Button>
                                        </>
                                }
                            </Modal.Footer>
                        </Form>
                    </Modal>
                )}
            </div>
        </div>
    )
}

export default ImageCropper
