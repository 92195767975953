import { React, useState, useEffect, useCallback } from "react";
import { Scheduler, Editing, Resource } from 'devextreme-react/scheduler';
import { Col, Row, Modal, Container, Stack, Button } from "react-bootstrap";
import Topbar from '../Assets/Topbar';
import LeftSidebar from '../Assets/LeftSidebar';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';
import { useNavigate } from "react-router-dom";
import { setBookingDetails } from "../../../reducers/User/action";
import { faCircle, faCircleCheck } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { customerBookingList } from "../../../Service";
import { bookingcalendarListColor } from "../../Reusable";

export default function CustomerBooking() {
    //initial state declaration
    const [currentDate, setCurrentDate] = useState(new Date());
    const [bookingData , setBookingData] = useState([]);
    const [showPopup, setShowPopup] = useState(false);
    const [actualBookingData, setActualBookingData] = useState([]);
    const [popupAppointments, setPopupAppointments] = useState([]);

    const views = ['month'];
    const navigate = useNavigate();
    const dispatch = useDispatch();

    // get customer data from store
    const userData =  useSelector(store => store.user['stylistuser']);

    const newDate = moment(new Date()).format('YYYY-MM-DD');
    const listParams = {"clientId": userData ? userData.clientId : '', "filterDate": newDate, "filterType": "month"};

    useEffect(()=>{
        customerBookingList(listParams).then(res => initialBookingResponse(res));
    },[]);

    const initialBookingResponse = ( response ) => {

        if ( response.data.statusCode === 200 ) {
            let initialBookedData  = response.data.customerBookingList;
            let monthRegularBookedData  = [];
            let mothSelfBookingData = [];
            let allBookingData = [];
            let newSelfBookingAppointment = [];

            initialBookedData.map( ( list ) => {

                if ( list.status !== "Cancelled" ) {
                    let listOpenTime = list.startDateTime;
                    let listEndTime = list.endDateTime;

                    if ( list.bookingType != 'CLSB' ) {
                        let singleBooedData = { "startDate": listOpenTime, "endDate": listEndTime, "text": list.customerName, "bookingId": list.bookingId, "status" : list.status, "bookingType" : list.bookingType, "colorId" : 1 };

                        monthRegularBookedData.push(singleBooedData);
                        allBookingData.push(singleBooedData);
                    } else {
                        let singleBooedData = { "startDate": listOpenTime, "endDate": listEndTime, "text": 'Self Booking', "bookingId": list.bookingId, "status" : list.status, "bookingType" : list.bookingType };

                        mothSelfBookingData.push(singleBooedData);
                        allBookingData.push(singleBooedData);
                    }

                }

            });

            if ( mothSelfBookingData.length > 0 ) {

                mothSelfBookingData.map( data => {
                    let selfBookingStartDate = moment(data.startDate).format('YYYY-MM-DD');

                    if ( monthRegularBookedData.length > 0 ) {
                        let matchingCount = 0;

                        monthRegularBookedData.map( item => {
                            let regularBookingStartDate = moment(item.startDate).format('YYYY-MM-DD');

                            if ( selfBookingStartDate === regularBookingStartDate ) {
                                matchingCount = matchingCount + 1;
                            }

                        })

                        if ( matchingCount === 0 ) {
                            newSelfBookingAppointment.push( { "startDate": data.startDate, "endDate": data.endDate, "text": 'Self Booking', "bookingId": data.bookingId, "status" : data.status, "bookingType" : data.bookingType, "colorId" : 2 } );
                        }
                    }

                })

            } 

            setActualBookingData(allBookingData);
            setBookingData([...monthRegularBookedData, ...newSelfBookingAppointment]);
        }

    }

    const onAppointmentFormOpening = useCallback((e) => {
        navigate('/stylist/bookingdetails');
        dispatch(setBookingDetails(e.appointmentData));
    }, []);

    const cellClick = (e) => {
    }

    const onOptionChanged = useCallback((e) => {

        if ( e.name === 'currentDate' ) {
            onDateNavigate(e.value);
        }

    },[]);

    const onDateNavigate = ( e )=>{
        let year = moment(e).format('YYYY');
        let month = moment(e).format('MM');
        let date  = '01';
        let changedDate = year+'-'+month+'-'+date;
        const listParams = {"clientId": userData ? userData.clientId : '', "filterDate": changedDate, "filterType": "month"};
        customerBookingList( listParams ).then( res=> initialBookingResponse(res) );
    };

    const onAppointmentTooltipShowing = ( e ) => {
        e.cancel = true;

        let actualCurrentDate = moment(e.appointments[0].appointmentData.startDate).format('YYYY-MM-DD');
        let newAppointments = [];

        actualBookingData.map((data) => {

            if ( data.status !== "Cancelled" ) {
                let appointmentCurrentDate = moment(data.startDate).format('YYYY-MM-DD');
                
                if ( actualCurrentDate === appointmentCurrentDate ) {
                    newAppointments.push(data);
                }
            }

        })

        setPopupAppointments(newAppointments);
        setShowPopup(true);
    }

    const handleClose = () => {
        setShowPopup(false);
        setPopupAppointments([]);
    }

    const getBookingDetails = ( data ) => {
        navigate('/stylist/bookingdetails/'+data.bookingId);
    }

    const addBookingHandleClick = () => {
        navigate('/stylist/addbooking')
    }

    const selfBookingHandleClick = () => {
        navigate('/stylist/selfbooking/');
    }

    return(
        <section className='dashboard'>
            <Container>
                <Row className='sideBarRow'>
                    <Col md={3} className='sideBarCol'>
                        <LeftSidebar/>
                    </Col>
                    <Col md={9}>
                        <div className='rightsidebar'>
                            <Topbar/>
                            <Row className="mb-3 main-title">
                                <Col className="upcommingHours">
                                    <h1>Booking Calendar</h1>
                                </Col>
                            </Row>
                            <Row className='availability-sec'>
                                <Row className="mb-3 managesearch">
                                    <Col>
                                        <Stack direction="horizontal" className="align-items-end" gap={2}>
                                            <div className="add-btn ms-auto">
                                                <Button variant="success" onClick={addBookingHandleClick}>Booking For Customer</Button>  
                                            </div>
                                            <div className="search-status-btn">
                                                <Button variant="primary" onClick={selfBookingHandleClick}>Self Booking</Button>
                                            </div>
                                        </Stack>
                                    </Col>
                                </Row>
                                <div className='booking_calendar'>
                                    <Scheduler
                                        dataSource={bookingData}
                                        currentDate={currentDate}
                                        views={views}
                                        defaultCurrentView="month"
                                        startDayHour={0}
                                        endDayHour={24}
                                        adaptivityEnabled={true}
                                        showAllDayPanel={false}
                                        onCellClick={cellClick}
                                        onAppointmentFormOpening={onAppointmentFormOpening}
                                        onOptionChanged={onOptionChanged}
                                        onAppointmentTooltipShowing={onAppointmentTooltipShowing}
                                    >   
                                        <Editing allowAdding={false} allowDeleting={false} />
                                        <Resource
                                            dataSource={bookingcalendarListColor}
                                            allowMultiple={true}
                                            fieldExpr="colorId"
                                            label="backroundColor"
                                            useColorAsDefault={true}
                                        />
                                    </Scheduler>
                                </div>
                            </Row>
                        </div>
                    </Col>
                </Row>
            </Container>

            {
                showPopup ?
                    <Modal show={showPopup} onHide={handleClose} className="" >
                        <Modal.Header closeButton>
                        </Modal.Header>
                        <Modal.Body style={{padding : 0}}>   
                            {
                                popupAppointments.length > 0 ?
                                    popupAppointments.map((data, index) => (
                                        <div key={ index } className={data.status == 'Completed' ? "appointmentList Completed" : data.bookingType == 'CLSB' ? "appointmentList selfBooking" : 'appointmentList'} onClick={() => getBookingDetails(data)}>
                                            <div className="appointmentIcon">
                                                {
                                                    data.status == 'Completed' || data.status == 'NoShow' ?
                                                        <FontAwesomeIcon icon={faCircleCheck} />
                                                    :
                                                        <FontAwesomeIcon icon={faCircle} />
                                                }
                                            </div>
                                            <div className="appointmentData">
                                                <h3>{data.text}</h3>
                                                <p>{moment(data.startDate).format("MMMM D h:mm A") + '  -  ' + moment(data.endDate).format("h:mm A")}</p>
                                            </div>
                                        </div>
                                    ))
                                :
                                    <></>
                            }
                        </Modal.Body>
                    </Modal>
                :
                    <></>
            }
        </section>
    )
}      

