import { React, useEffect, useState } from 'react';
import { Col, Row, Container } from "react-bootstrap";
import { useSelector } from 'react-redux';
import { useParams, useNavigate } from 'react-router-dom';
import { postMailBookingList, postCustomerBookingApproveOrReject } from '../../Service';
import { InfinitySpin } from 'react-loader-spinner';
import logo from "../../images/logo.png";
import linkExpired from '../../images/link-expired.jpg';
import { JsonEncode } from '../Reusable/JsonModify';
import Swal from "sweetalert2";

export default function RejectBooking(){
    const params = useParams();
    const navigate = useNavigate();

    const [pageLoader, setPageLoader] = useState(true);
    const [acceptBookingStatus, setAcceptBookingStatus] = useState(false);
    const [bookingData, setBookingData] = useState('');

    // get customer data from store
    const userData =  useSelector(store => store.user['customeruser']);

    useEffect(() => {

        if ( userData ) {

            if ( userData.id == params.customerId ) {
                let bookingParams = {'customerId' : params.customerId, 'bookingId' : params.bookingId};
                postMailBookingList(bookingParams).then(res => bookingListResponse(res));
            } else {
                navigate('../../acceptrejectlogin/'+params.customerId+'/'+params.bookingId+'/'+0);
            }

        } else {
            navigate('../../acceptrejectlogin/'+params.customerId+'/'+params.bookingId+'/'+0);
        }

    }, []);

    const bookingListResponse = async ( res ) => {

        if ( res.data.bookingList != null && (res.data.bookingList.bookingType == 'CL' || res.data.bookingList.bookingType == 'CLEB') && res.data.bookingList.isCustomerRespondent == false && res.data.bookingList.status == 'Booked' ) {
            setBookingData(res.data.bookingList);
            let bookingDetails = res.data.bookingList;
            let platformFees = bookingDetails.payment - bookingDetails.serviceCost;
            let roundOfPlatformFees = Math.floor(platformFees * 100) / 100;

            let acceptParams = {'bookingId' : bookingDetails.bookingId, 'customerAccepts' : false, 'customerRejects' : true, 'wrongCustomer' : false, 'serviceId' : bookingDetails.serviceid, 'serviceName' : bookingDetails.serviceName, 'payment' : bookingDetails.payment, 'serviceCost' : bookingDetails.serviceCost, 'platformFees' : roundOfPlatformFees, 'tip' : bookingDetails.tip, 'clientId' : bookingDetails.clientId, 'customerId' : bookingDetails.customerId};

            let jsonData = await JsonEncode(acceptParams).then(result => {
                return result
            }).catch(error => {
                Swal.fire({
                    position: 'center',
                    icon: 'error',
                    title: 'Oops...',
                    showConfirmButton: false,
                    text: error,
                    timer: 3000
                }); 
            });
            
            if ( jsonData ) {
                let loginPayload = {data : jsonData};
                postCustomerBookingApproveOrReject(loginPayload).then(res => acceptHandleResponse(res));
            } else {
                Swal.fire({
                    position: 'center',
                    icon: 'error',
                    title: 'Oops...',
                    showConfirmButton: false,
                    text: 'Something went wrong, Please try after sometimes',
                    timer: 3000
                }); 
            }
            
        } else {
            setPageLoader(false);
        } 

    }

    const acceptHandleResponse = ( res ) => {

        if ( res.data.statusCode === 200 ) {
            setAcceptBookingStatus(true);
            setPageLoader(false);
        } else {
            setPageLoader(false);
        }

    }

    const handleHome = () => {
        navigate('/');
    }

    return(
        <>
            {
                pageLoader === true ?
                    <div className='acceptRejectLoader'>
                        <InfinitySpin height="80" width="200" radius="9" color="#D49D29" ariaLabel="loading" wrapperStyle wrapperClass />
                    </div>
                :
                    <section className="emailTip">
                        <Container>
                            <Row>
                                <Col>
                                    <div className="text-center">
                                        <img src={logo} />
                                    </div>
                                </Col>
                            </Row>
                            {
                                bookingData != '' && acceptBookingStatus === true ?
                                    <div>
                                        <p className='tipThanks'>Thank you. Your booking has been rejected successfully.</p>
                                        <p className='tipThanks'>We are really excited to serve you again. <span onClick={handleHome} style={{cursor : 'pointer', color : '#8aaee2'}}>Click here</span> to visit Unext Homepage. </p>
                                    </div>
                                :
                                    <div style={{textAlign:'center', marginTop : '16px'}}>
                                        <img src={linkExpired} className='img-responsive' />
                                    </div>
                            }
                        </Container>
                    </section>
            }
        </>
    )
}